import { Field, Formik } from "formik";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";
import StepsComponent from "../components/StepsComponent";
import * as yup from "yup";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OpenDematAccount: React.FC = () => {
  const navigate = useNavigate();
  const [nomineeAdd, setNomineeAdd] = useState(false);
  const [nomineeNotNeeded, setNomineeNotNeeded] = useState(false);
  const [nomineeAddress1, setNomineeAddress1] = useState(false);
  const [nomineeAddress2, setNomineeAddress2] = useState(false);
  const [nomineeAddress3, setNomineeAddress3] = useState(false);
  const [minor1, setMinor1] = useState(false);
  const [minor2, setMinor2] = useState(false);
  const [minor3, setMinor3] = useState(false);
  const [nomineeDetail1, setNomineeDetails1] = useState<APIData.NomineeData>(InitialData.NomineeData);
  const [nomineeDetail2, setNomineeDetails2] = useState<APIData.NomineeData>(InitialData.NomineeData);
  const [nomineeDetail3, setNomineeDetails3] = useState<APIData.NomineeData>(InitialData.NomineeData);
  const [relationshipDropdown, setRelationshipDropdown] = useState<APIData.Relationship[]>([]);
  const [nomineeDropdown, setNomineeDropdown] = useState<APIData.NomineeProof[]>([]);
  const [nomineeTitles, setNomineeTitles] = useState<APIData.Titles[]>([]);
  const [proofType1, setProofType1] = useState("Proof");
  const [proofType2, setProofType2] = useState("Proof");
  const [proofType3, setProofType3] = useState("Proof");
  const [gproofType1, setgProofType1] = useState("Proof");
  const [gproofType2, setgProofType2] = useState("Proof");
  const [gproofType3, setgProofType3] = useState("Proof");
  const maxDate = moment(new Date()).format("YYYY-MM-DD");
  const [cityValue1, setCityValue1] = useState("");
  const [cityValue2, setCityValue2] = useState("");
  const [cityValue3, setCityValue3] = useState("");
  const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
  const [state1, setState1] = useState("");
  const [state2, setState2] = useState("");
  const [state3, setState3] = useState("");
  const [pincode1, setPincode1] = useState("");
  const [pincode2, setPincode2] = useState("");
  const [pincode3, setPincode3] = useState("");
  const { setShowPreloader } = useAppStateAPI();
  let AccountRedirect = localStorage.getItem("nominee");
  const [startDate1, setStartDate1] = useState<Date>();
  const [startDate2, setStartDate2] = useState<Date>();
  const [startDate3, setStartDate3] = useState<Date>();
  const [panerror1, setPanerror1] = useState("");
  const [panerror2, setPanerror2] = useState("");
  const [panerror3, setPanerror3] = useState("");


  useEffect(() => {
    setShowPreloader(true);
    ServerAPI.Relationship()
      .then((res: any) => {
        setRelationshipDropdown(res["data"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });
    setShowPreloader(true);
    ServerAPI.NomineeProof()
      .then((res: any) => {
        setNomineeDropdown(res["data"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });

    setShowPreloader(true);
    ServerAPI.userDetailData()
      .then((res: any) => {
        setUserDetails(res["data"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });
    setShowPreloader(true);
    ServerAPI.Titles()
      .then((res: any) => {
        setNomineeTitles(res["data"]["message"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  }, []);

  useEffect(() => {
    let nominee1details: any = { ...nomineeDetail1 };
    if (moment().diff(moment(startDate1), "years") < 18 && startDate1) {
      nominee1details["nominee_dob"] = moment(startDate1).format("YYYY-MM-DD");
      nominee1details["nominee_is_minor"] = 1;
      setMinor1(true);
    } else {
      nominee1details["nominee_dob"] = moment(startDate1).format("YYYY-MM-DD");
      nominee1details["nominee_is_minor"] = 0;
      setMinor1(false);
    }
    setNomineeDetails1(nominee1details);
  }, [startDate1]);
  useEffect(() => {
    let nominee2details: any = { ...nomineeDetail2 };
    if (moment().diff(moment(startDate2), "years") < 18 && startDate2) {
      nominee2details["nominee_dob"] = moment(startDate2).format("YYYY-MM-DD");
      nominee2details["nominee_is_minor"] = 1;
      setMinor2(true);
    } else {
      nominee2details["nominee_is_minor"] = 0;
      nominee2details["nominee_dob"] = moment(startDate2).format("YYYY-MM-DD");
      setMinor2(false);
    }
    setNomineeDetails2(nominee2details);
  }, [startDate2]);
  useEffect(() => {
    let nominee3details: any = { ...nomineeDetail3 };
    if (moment().diff(moment(startDate3), "years") < 18 && startDate3) {
      nominee3details["nominee_dob"] = moment(startDate3).format("YYYY-MM-DD");
      nominee3details["nominee_is_minor"] = 1;
      setMinor3(true);
    } else {
      nominee3details["nominee_dob"] = moment(startDate3).format("YYYY-MM-DD");
      nominee3details["nominee_is_minor"] = 0;
      setMinor3(false);
    }
    setNomineeDetails3(nominee3details);
  }, [startDate3]);

  useEffect(() => {
    setShowPreloader(true);
    if (pincode1 != "") {
      ServerAPI.pincodeSearch(pincode1)
        .then((response: any) => {
          setState1(response["data"][0]["pincode_state_name"]);
          setCityValue1(response["data"][0]["pincode_district"]);
          let nominee1details: any = { ...nomineeDetail1 };
          nominee1details['nominee_address_state'] = response["data"][0]["pincode_state_name"];
          nominee1details['nominee_address_city'] = response["data"][0]["pincode_district"];
          setNomineeDetails1(nominee1details);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
    if (pincode2 != "") {
      ServerAPI.pincodeSearch(pincode2)
        .then((response: any) => {
          setState2(response["data"][0]["pincode_state_name"]);
          setCityValue2(response["data"][0]["pincode_district"]);
          let nominee2details: any = { ...nomineeDetail2 };
          nominee2details['nominee_address_state'] = response["data"][0]["pincode_state_name"];
          nominee2details['nominee_address_city'] = response["data"][0]["pincode_district"];
          setNomineeDetails2(nominee2details);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
    if (pincode3 != "") {
      ServerAPI.pincodeSearch(pincode3)
        .then((response: any) => {
          setState3(response["data"][0]["pincode_state_name"]);
          setCityValue3(response["data"][0]["pincode_district"]);
          let nominee3details: any = { ...nomineeDetail3 };
          nominee3details['nominee_address_state'] = response["data"][0]["pincode_state_name"];
          nominee3details['nominee_address_city'] = response["data"][0]["pincode_district"];
          setNomineeDetails3(nominee3details);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  }, [pincode1, pincode2, pincode3]);

  const handleNomineeDetails = (nomineeSno: number, key: string, value: string | number, guardian: boolean) => {
    let nominee1details: any = { ...nomineeDetail1 };
    let nominee2details: any = { ...nomineeDetail2 };
    let nominee3details: any = { ...nomineeDetail3 };
    if (nomineeSno == 1) {
      if (!guardian) {
        nominee1details[key] = value;
        console.log("nominee1details",nominee1details[key] = value);
      } else {
        if (key == "nominee_relationship_with_applicant_id") {
          nominee1details.nominee_guardian_records["relationship_with_applicant_id"] = value;
        }
        else if (key == "nominee_guardian_identification_id") {
          nominee1details.nominee_guardian_records["nominee_identification_id"] = value;
        } else if (key == "nominee_name_title_id") {
          nominee1details.nominee_guardian_records["name_title_id"] = value;
        }else if (key == "nominee_guardian_identification_number1"){
          nominee1details.nominee_guardian_records["nominee_guardian_identification_number"] = value;
        }
         else {
          nominee1details.nominee_guardian_records[key] = value;
        }
        delete nominee1details.nominee_guardian_records["nominee_guardian_identification_id"];
        delete nominee1details.nominee_guardian_records["nominee_name_title_id"];
        delete nominee1details.nominee_guardian_records["nominee_relationship_with_applicant_id"];
      }
      if (nominee1details.nominee_is_minor == 0) {
        nominee1details.nominee_guardian_records = {};
      }
      setNomineeDetails1(nominee1details);
    } else if (nomineeSno == 2) {
      if (!guardian) {
        nominee2details[key] = value;
      } else {
        if (key == "nominee_guardian_identification_id") {
          nominee2details.nominee_guardian_records["nominee_identification_id"] = value;
        } else if (key == "nominee_relationship_with_applicant_id") {
          nominee2details.nominee_guardian_records["relationship_with_applicant_id"] = value;
        } else if (key == "nominee_name_title_id") {
          nominee2details.nominee_guardian_records["name_title_id"] = value;
        } else {
          nominee2details.nominee_guardian_records[key] = value;
        }
      }
      if (nominee2details.nominee_is_minor == 0) {
        nominee2details.nominee_guardian_records = {};
      }
      console.log(nominee2details);
      setNomineeDetails2(nominee2details);
    } else if (nomineeSno == 3) {
      if (!guardian) {
        nominee3details[key] = value;
      } else {
        if (key == "nominee_guardian_identification_id") {
          nominee3details.nominee_guardian_records["nominee_identification_id"] = value;
        } else if (key == "nominee_relationship_with_applicant_id") {
          nominee3details.nominee_guardian_records["relationship_with_applicant_id"] = value;
        } else if (key == "nominee_name_title_id") {
          nominee3details.nominee_guardian_records["name_title_id"] = value;
        } else {
          nominee3details.nominee_guardian_records[key] = value;
        }
      }
      if (nominee3details.nominee_is_minor == 0) {
        nominee3details.nominee_guardian_records = {};
      }
      setNomineeDetails3(nominee3details);
    }
  };

  const validate = (value: any) => {    
    let errors;
    if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/i.test(value) && proofType1 == "PAN") {
      errors = "Invalid PAN number";
    } else if (!/^[A-PR-WYa-pr-wy][1-9]\\d?\\d{4}[1-9]$/i.test(value) && proofType1 == "Passport") {
      errors = "Invalid Passport number";
    } else if (!/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/i.test(value) && proofType1 == "Aadhar") {
      errors = "Invalid Aadhar number";
    }
    return errors;
  };
  const validate1 = (value: any) => {
    let errors;
    if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/i.test(value) && proofType2 == "PAN") {
      errors = "Invalid PAN number";
    } else if (!/^[A-PR-WYa-pr-wy][1-9]\\d?\\d{4}[1-9]$/i.test(value) && proofType2 == "Passport") {
      errors = "Invalid Passport number";
    } else if (!/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/i.test(value) && proofType2 == "Aadhar") {
      errors = "Invalid Aadhar number";
    }
    return errors;
  };
  const validate2 = (value: any) => {
    
    let errors;
    if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/i.test(value) && proofType3 == "PAN") {
      errors = "Invalid PAN number";
    } else if (!/^[A-PR-WYa-pr-wy][1-9]\\d?\\d{4}[1-9]$/i.test(value) && proofType3 == "Passport") {
      errors = "Invalid Passport number";
    } else if (!/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/i.test(value) && proofType3 == "Aadhar") {
      errors = "Invalid Aadhar number";
    }
    return errors;
  };
  const validate3 = (value: any) => {
    console.log("🚀 ~ file: AddNominee.tsx:234 ~ validate ~ value:", value)
    
    let errors;
    if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/i.test(value) && gproofType1 == "PAN") {
      errors = "Invalid PAN number";
      setPanerror1(errors);
    } else if (!/^[A-PR-WYa-pr-wy][1-9]\\d?\\d{4}[1-9]$/i.test(value) && gproofType1 == "Passport") {
      errors = "Invalid Passport number";
      setPanerror1(errors);
    } else if (!/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/i.test(value) && gproofType1 == "Aadhar") {
      errors = "Invalid Aadhar number";
      setPanerror1(errors);
    }else {
      setPanerror1("");
    }
    // return errors;
    
  };
  const validate4 = (value: any) => {
    
    let errors;
    if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/i.test(value) && gproofType2 == "PAN") {
      errors = "Invalid PAN number";
      setPanerror2(errors);
    } else if (!/^[A-PR-WYa-pr-wy][1-9]\\d?\\d{4}[1-9]$/i.test(value) && gproofType2 == "Passport") {
      errors = "Invalid Passport number";
      setPanerror2(errors);
    } else if (!/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/i.test(value) && gproofType2 == "Aadhar") {
      errors = "Invalid Aadhar number";
      setPanerror2(errors);
    }else {
      setPanerror2("");
    }
    // return errors;
  };
  const validate5 = (value: any) => {    
    let errors;
    if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/i.test(value) && gproofType3 == "PAN") {
      errors = "Invalid PAN number";
      setPanerror3(errors);
    } else if (!/^[A-PR-WYa-pr-wy][1-9]\\d?\\d{4}[1-9]$/i.test(value) && gproofType3 == "Passport") {
      errors = "Invalid Passport number";
      setPanerror3(errors);
    } else if (!/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/i.test(value) && gproofType3 == "Aadhar") {
      errors = "Invalid Aadhar number";
      setPanerror3(errors);
    }
    else{
      setPanerror3('');

    }
    // return errors;
  };
  const addNomineeDetails = () => {
    let nomineeDetails: any = [];
    let total =
      (!isNaN(parseInt(nomineeDetail1.nominee_percentage)) && parseInt(nomineeDetail1.nominee_percentage) != null && parseInt(nomineeDetail1.nominee_percentage) != undefined ? parseInt(nomineeDetail1.nominee_percentage) : 0) +
      (!isNaN(parseInt(nomineeDetail2.nominee_percentage)) && parseInt(nomineeDetail2.nominee_percentage) != null && parseInt(nomineeDetail2.nominee_percentage) != undefined ? parseInt(nomineeDetail2.nominee_percentage) : 0) +
      (!isNaN(parseInt(nomineeDetail3.nominee_percentage)) && parseInt(nomineeDetail3.nominee_percentage) != null && parseInt(nomineeDetail3.nominee_percentage) != undefined ? parseInt(nomineeDetail3.nominee_percentage) : 0);
    if (total != 100) {
      toast.error("The Sum of Nominee Percentage Must be equal to 100");
    } else {
      if (nomineeDetail1.nominee_fullname != "" && nomineeDetail2.nominee_fullname != "" && nomineeDetail3.nominee_fullname == "") {
        nomineeDetails = [nomineeDetail1, nomineeDetail2];
      } else if (nomineeDetail1.nominee_fullname != "" && nomineeDetail2.nominee_fullname != "" && nomineeDetail3.nominee_fullname != "") {
        nomineeDetails = [nomineeDetail1, nomineeDetail2, nomineeDetail3];
      } else if (nomineeDetail1.nominee_fullname != "" && nomineeDetail2.nominee_fullname == "" && nomineeDetail3.nominee_fullname == "") {
        nomineeDetails = [nomineeDetail1];
      }
      console.log("nomineeDetail1", nomineeDetail1);
      setShowPreloader(true);
      ServerAPI.AddNominee(nomineeDetails)
        .then((res: any) => {
          console.log("🚀 ~ file: AddNominee.tsx:328 ~ .then ~ res:", res)
          if (res != undefined) {
            toast.success(res["data"]["message"]);
            navigate(PageLinks.VERIFY_ADD_NOMINEE_SUCCESS);
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  };

  const updateUserDetails = () => {
    let userdata = { ...userDetail };
    if (nomineeNotNeeded == true) {
      userdata.personal_data_nominee_added = 0;
    } else {
      userdata.personal_data_nominee_added = 1;
    }
    setUserDetails(userdata);
    setShowPreloader(true);
    ServerAPI.add_profile_details(userDetail)
      .then((res: any) => {
        console.log("🚀 ~ file: AddNominee.tsx:349 ~ .then ~ res:", res)
        if (res != undefined) {
          toast.success(res["data"]["message"]);
          navigate(PageLinks.DOWNLOAD_APPLICATION);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  return (
    <div className="main-index-wrapper">
      <main>
        {/* Profile-details-edit-area start */}
        <section className="subscribe-letter-area pt-20 pb-20">
          <div className="line-shape d-none d-lg-inline-block">
            <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
          </div>

          <div className="container custom-container-subs">
            <div className="sub-bg">
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="subscribe-wrapper">
                    <div className={AccountRedirect != "true" ? "section-title section-title-3 text-center" : "section-title section-title-3 text-center mb-5"}>
                      <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                    </div>
                  </div>
                  {AccountRedirect != "true" && <StepsComponent step={3} />}

                  <div className="step_box_content">
                    <div className="sub-bg">
                      <div className="row justify-content-center">
                        <div className="col-xl-12">
                          <div className="subscribe-wrapper">
                            <div className="section-title section-title-3">
                              <h6 className="wow page-main-heading" data-wow-delay="0.1s">
                                Open Demat Account <span className="page-main-heading-steps">STEPS - 3 of 4</span>
                              </h6>
                              <hr></hr>
                              <div className="col-xl-12 pl-0 float-left">
                                <h6 className="pt-20 mb--10  pb-10 header-h6" data-wow-delay="0.1s">
                                  Add Nominee
                                </h6>
                                <p className="personal_sub_text">You can add up to 3 nominees for your account. You can also add them later in your profile once your account is open</p>
                              </div>
                              <div className="col-xl-8 p-0 float-left">
                                <div className="col-xl-6 pl-0 float-left  ">
                                  <div className="choice-list political_switch mt-1">
                                    <span className="input-title">Do You Want To Add A Nominee?</span>
                                    <div className="input-title-filled nominee-toggle-switch" style={{ marginLeft: "163px" }}>
                                      <div className="custom-switch custom-switch-label-yesno pl-0 ">
                                        <input
                                          className="custom-switch-input"
                                          id="citizen"
                                          type="checkbox"
                                          checked={nomineeAdd == true ? true : false}
                                          onChange={(e: any) => {
                                            if (e.target.checked) {
                                              setNomineeAdd(true);
                                              setNomineeNotNeeded(false);
                                            } else {
                                              setNomineeAdd(false);
                                              setNomineeNotNeeded(true);
                                            }
                                          }}
                                        />
                                        <label className="custom-switch-btn" htmlFor="citizen"></label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-12 pl-0 float-left mt-5">
                                <label htmlFor="terms" className="review-text mx-1 mb-0 p-0">
                                  <p className="review-text text-left mt-1">
                                    <input
                                      type="checkbox"
                                      checked={nomineeNotNeeded ? true : nomineeNotNeeded}
                                      id="terms"
                                      name="user_auth_fetch_kyc_details"
                                      onChange={(e: any) => {
                                        if (e.target.checked == true) {
                                          setNomineeNotNeeded(true);
                                          setNomineeAdd(false);
                                        } else {
                                          setNomineeNotNeeded(false);
                                          setNomineeAdd(true);
                                        }
                                      }}
                                    />{" "}
                                    I do not wish to add Nominee/s
                                  </p>
                                </label>
                              </div>
                              {nomineeAdd ? (
                                <Fragment>
                                  <div className="float-left col-xl-12 br-1px mb-5">
                                    <Accordion allowZeroExpanded>
                                      <AccordionItem>
                                        <AccordionItemHeading>
                                          <AccordionItemButton>Nominee - 01</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <Formik
                                            initialValues={nomineeDetail1}
                                            enableReinitialize
                                            onSubmit={(values, { setSubmitting }) => {
                                              // let data: APIData.NomineeData = { ...values };
                                              // let valuedata: APIData.NomineeData = { ...values };
                                            }}
                                          >
                                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                              <form className="quote-form mb-10 nominee-form1" onSubmit={handleSubmit}>
                                                <div className="row">
                                                <div className="col-xl-8 p-0 float-left mt-1">
                                                  <div className="col-xl-6 pl-0 float-left">
                                                    <div className="email-input input_field pl-0">
                                                      <label className={errors.nominee_percentage && touched.nominee_percentage ? "error" : "input-title"}>Nominee Percentage</label>
                                                      <Field
                                                        as="input"
                                                        onChange={(e: any) => {
                                                          handleNomineeDetails(1, "nominee_percentage", e.target.value, false);
                                                        }}
                                                        onBlur={(e: any) => {
                                                          handleNomineeDetails(1, "nominee_percentage", e.target.value, false);
                                                        }}
                                                        placeholder="Enter Percentage"
                                                        name="nominee_percentage"
                                                        className={errors.nominee_percentage && touched.nominee_percentage ? "input-error" : ""}
                                                        value={values.nominee_percentage}
                                                      />
                                                        <span className="error">Share percentage for one/all nominee should be 100%</span>
                                                      {errors.nominee_percentage && touched.nominee_percentage && <span className="error">{errors.nominee_percentage}</span>}
                                                    </div>
                                                  </div>
                                                </div>
                                                </div>
                                               
                                                <div className="col-xl-8 p-0 float-left">
                                                <div className="row">
                                                <div className="col-xl-6 pl-0 float-left  ">
                                                    <div className="email-input input_field pl-0">
                                                      <label className="input-title">Title</label>
                                                      <Field
                                                        as="select"
                                                        name="name_title_id"
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          handleNomineeDetails(1, "name_title_id", e.target.value, false);
                                                        }}
                                                        onBlur={(e: any) => {
                                                          handleBlur(e);
                                                          handleNomineeDetails(1, "name_title_id", e.target.value, false);
                                                        }}
                                                      >
                                                        <option value={""}>select</option>
                                                        {nomineeTitles != undefined &&
                                                          nomineeTitles.length > 0 &&
                                                          nomineeTitles.map((title: APIData.Titles) => {
                                                            return <option value={title.name_title_id}>{title.name_title}</option>;
                                                          })}
                                                      </Field>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6 pl-0 float-left  ">
                                                    <div className="email-input input_field pl-0">
                                                      <label className={errors.nominee_fullname && touched.nominee_fullname ? "error" : "input-title"}>
                                                        Full Name <span className="error-area">*</span>
                                                      </label>
                                                      <Field
                                                        as="input"
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          handleNomineeDetails(1, "nominee_fullname", e.target.value, false);
                                                        }}
                                                        onBlur={(e: any) => {
                                                          handleBlur(e);
                                                          handleNomineeDetails(1, "nominee_fullname", e.target.value, false);
                                                        }}
                                                        placeholder="Enter First Name"
                                                        name="nominee_fullname"
                                                      />
                                                    </div>
                                                    {errors.nominee_fullname && touched.nominee_fullname && <span className="error">{errors.nominee_fullname}</span>}
                                                  </div>
                                                  <div className="col-xl-6 pl-0 float-left">
                                                    <div className="email-input input_field pl-0">
                                                      <label className={errors.relationship_with_applicant_id && touched.relationship_with_applicant_id ? "error" : "input-title"}>Relationship with Application</label>
                                                      <Field
                                                        className=""
                                                        name="relationship_with_applicant_id"
                                                        as="select"
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          handleNomineeDetails(1, "relationship_with_applicant_id", e.target.value, false);
                                                        }}
                                                        onBlur={(e: any) => {
                                                          handleBlur(e);
                                                          handleNomineeDetails(1, "relationship_with_applicant_id", e.target.value, false);
                                                        }}
                                                      >
                                                        <option value="0">select</option>
                                                        {relationshipDropdown != undefined &&
                                                          relationshipDropdown.length > 0 &&
                                                          relationshipDropdown.map((relation: APIData.Relationship, index) => {
                                                            return (
                                                              <option key={index} value={relation.relationship_with_applicant_id}>
                                                                {relation.relationship_with_applicant}
                                                              </option>
                                                            );
                                                          })}
                                                      </Field>
                                                    </div>
                                                    {errors.relationship_with_applicant_id && touched.relationship_with_applicant_id && <span className="error">{errors.relationship_with_applicant_id}</span>}
                                                  </div>
                                                  <div className="col-xl-6 pl-0 float-left">
                                                    <div className="email-input input_field pl-0">
                                                      <label className="input-title">Nominee Identification</label>
                                                      <Field
                                                        as="select"
                                                        className=""
                                                        name="nominee_identification_id"
                                                        value={values.nominee_identification_id}
                                                        onChange={(e: any) => {
                                                          if (e.target.value == 0) {
                                                            e.preventDefault();
                                                            handleChange(e);
                                                            handleBlur(e);
                                                            setProofType1("Proof");
                                                            handleNomineeDetails(1, "nominee_identification_id", 0, false);
                                                          } else {
                                                            e.preventDefault();
                                                            handleChange(e);
                                                            handleBlur(e);
                                                            setProofType1(e.nativeEvent.target[e.target.selectedIndex].text);
                                                            handleNomineeDetails(1, "nominee_identification_id", e.target.value, false);
                                                          }
                                                        }}
                                                      >
                                                        <option>select</option>
                                                        {nomineeDropdown != undefined &&
                                                          nomineeDropdown.length > 0 &&
                                                          nomineeDropdown.map((proof: APIData.NomineeProof, index) => {
                                                            return (
                                                              <option key={index} value={proof.nominee_identification_id}>
                                                                {proof.nominee_identification}
                                                              </option>
                                                            );
                                                          })}
                                                      </Field>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6 pl-0 float-left">
                                                    <div className="email-input input_field pl-0">
                                                      <label className="input-title">{proofType1} Number</label>
                                                      <Field
                                                        as="input"
                                                        placeholder={"Enter" + " " + proofType1 + " " + "Number"}
                                                        maxLength={proofType1 == "PAN" ? "10" : proofType1 == "Passport" ? "8" : proofType1 == "Aadhar" ? "12" : null}
                                                        validate={validate}
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          handleNomineeDetails(1, "nominee_identification_number", e.target.value.toUpperCase(), false);
                                                        }}
                                                        onBlur={(e: any) => {
                                                          handleBlur(e);
                                                          handleNomineeDetails(1, "nominee_identification_number", e.target.value.toUpperCase(), false);
                                                        }}
                                                        name="nominee_identification_number"
                                                      />
                                                      {errors.nominee_identification_number && touched.nominee_identification_number ? <span className="error">{errors.nominee_identification_number}</span> : null}
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6 pl-0 float-left">
                                                    <div className="email-input input_field pl-0">
                                                      <label className={errors.nominee_dob ? "error" : "input-title"}>Date of Birth</label>
                                                      <DatePicker peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" maxDate={new Date(maxDate)} minDate={new Date("1900-01-01")} className="date-picker-input profile-calendar mobile_number" name="nominee_dob" selected={startDate1} onChange={(date: any) => setStartDate1(date)} />
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6 pl-0 float-left">
                                                    <div className="email-input input_field pl-0">
                                                      <label className="input-title">Mobile Number</label>
                                                      <Field
                                                        as="input"
                                                        maxLength={10}
                                                        placeholder="Enter Mobile Number"
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          handleNomineeDetails(1, "nominee_mobile_number", e.target.value, false);
                                                        }}
                                                        onBlur={(e: any) => {
                                                          handleBlur(e);
                                                          handleNomineeDetails(1, "nominee_mobile_number", e.target.value, false);
                                                        }}
                                                        name="nominee_mobile_number"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                </div>
                                                {minor1 == true && values.nominee_guardian_records != undefined && (
                                                  <Fragment>
                                                    <div className="col-xl-12 p-0 float-left guardian-details">
                                                      <h3>
                                                        <label className="input-title nominee-guardian-title">Guardian Details</label>
                                                      </h3>
                                                      <div className="email-input input_field pl-0">
                                                        <div className="col-xl-8 p-0 float-left">
                                                          <div className="col-xl-6 pl-0 float-left  ">
                                                            <div className="email-input input_field pl-0">
                                                              <label className="input-title">Title</label>
                                                              <Field
                                                                as="select"
                                                                name="nominee_name_title_id"
                                                                onChange={(e: any) => {
                                                                  handleNomineeDetails(1, "nominee_name_title_id", e.target.value, true);
                                                                }}
                                                                onBlur={(e: any) => {
                                                                  handleNomineeDetails(1, "nominee_name_title_id", e.target.value, true);
                                                                }}
                                                              >
                                                                <option value={""}>select</option>
                                                                {nomineeTitles != undefined &&
                                                                  nomineeTitles.length > 0 &&
                                                                  nomineeTitles.map((title: APIData.Titles) => {
                                                                    return <option value={title.name_title_id}>{title.name_title}</option>;
                                                                  })}
                                                              </Field>
                                                            </div>
                                                          </div>
                                                          <div className="col-xl-6 pl-0 float-left  ">
                                                            <div className="email-input input_field pl-0">
                                                              <label className={errors.nominee_fullname && touched.nominee_fullname ? "error" : "input-title"}>
                                                                Full Name <span className="error-area">*</span>
                                                              </label>
                                                              <Field
                                                                as="input"
                                                                onChange={(e: any) => {
                                                                  handleNomineeDetails(1, "nominee_guardian_fullname", e.target.value, true);
                                                                }}
                                                                onBlur={(e: any) => {
                                                                  handleNomineeDetails(1, "nominee_guardian_fullname", e.target.value, true);
                                                                }}
                                                                placeholder="Enter First Name"
                                                                name="nominee_guardian_fullname"
                                                              />
                                                            </div>
                                                            {errors.nominee_fullname && touched.nominee_fullname && <span className="error">{errors.nominee_fullname}</span>}
                                                          </div>
                                                          <div className="col-xl-6 pl-0 float-left">
                                                            <div className="email-input input_field pl-0">
                                                              <label className={errors.nominee_guardian_records?.nominee_relationship_with_applicant_id && touched.nominee_guardian_records?.nominee_relationship_with_applicant_id ? "error" : "input-title"}>Relationship with Application</label>
                                                              <Field
                                                                className=""
                                                                name="nominee_relationship_with_applicant_id"
                                                                as="select"
                                                                onChange={(e: any) => {
                                                                  handleNomineeDetails(1, "nominee_relationship_with_applicant_id", e.target.value, true);
                                                                }}
                                                                onBlur={(e: any) => {
                                                                  handleNomineeDetails(1, "nominee_relationship_with_applicant_id", e.target.value, true);
                                                                }}
                                                              >
                                                                <option value="0">select</option>
                                                                {relationshipDropdown != undefined &&
                                                                  relationshipDropdown.length > 0 &&
                                                                  relationshipDropdown.map((relation: APIData.Relationship, index) => {
                                                                    return (
                                                                      <option key={index} value={relation.relationship_with_applicant_id}>
                                                                        {relation.relationship_with_applicant}
                                                                      </option>
                                                                    );
                                                                  })}
                                                              </Field>
                                                            </div>
                                                            {errors.nominee_guardian_records?.nominee_relationship_with_applicant_id && touched.nominee_guardian_records?.nominee_relationship_with_applicant_id && <span className="error">{errors.nominee_guardian_records?.nominee_relationship_with_applicant_id}</span>}
                                                          </div>
                                                          <div className="col-xl-6 pl-0 float-left">
                                                            <div className="email-input input_field pl-0">
                                                              <label className="input-title">Guardian Identification</label>
                                                              <Field
                                                                as="select"
                                                                className=""
                                                                name="nominee_guardian_identification_id"
                                                                onChange={(e: any) => {
                                                                  if (e.target.value == 0) {
                                                                    e.preventDefault();
                                                                    handleChange(e);
                                                                    handleBlur(e);
                                                                    setgProofType1("Proof");
                                                                    handleNomineeDetails(1, "nominee_guardian_identification_id", 0, true);
                                                                  } else {
                                                                    e.preventDefault();
                                                                    handleChange(e);
                                                                    handleBlur(e);
                                                                    setgProofType1(e.nativeEvent.target[e.target.selectedIndex].text);
                                                                    handleNomineeDetails(1, "nominee_guardian_identification_id", e.target.value.toUpperCase(), true);
                                                                  }
                                                                }}
                                                              > 
                                                                <option>select</option>
                                                                {nomineeDropdown != undefined &&
                                                                  nomineeDropdown.length > 0 &&
                                                                  nomineeDropdown.map((proof: APIData.NomineeProof, index) => {
                                                                    return (
                                                                      <option key={index} value={proof.nominee_identification_id}>
                                                                        {proof.nominee_identification}
                                                                      </option>
                                                                    );
                                                                  })}
                                                              </Field>
                                                            </div>
                                                          </div>
                                                          <div className="col-xl-6 pl-0 float-left">
                                                            <div className="email-input input_field pl-0">
                                                              <label className="input-title">{gproofType1} Number</label>
                                                              <Field
                                                                as="input"
                                                                style={{ textTransform: 'uppercase'}}
                                                                placeholder={"Enter" + " " + gproofType1 + " " + "Number"}
                                                                maxLength={gproofType1 == "PAN" ? "10" : gproofType1 == "Passport" ? "8" : gproofType1 == "Aadhar" ? "12" : null}
                                                                // validate={validate3}
                                                                onChange={(e: any) => {
                                                                  validate3(e.target.value.toUpperCase())
                                                                  handleChange(e);
                                                                  handleNomineeDetails(1, "nominee_guardian_identification_number1", e.target.value.toUpperCase(), true);
                                                                }}
                                                                onBlur={(e: any) => {
                                                                  validate3(e.target.value.toUpperCase())

                                                                  handleBlur(e);
                                                                  handleNomineeDetails(1, "nominee_guardian_identification_number1", e.target.value.toUpperCase(), true);
                                                                }}
                                                                name="nominee_guardian_identification_number1"
                                                              />
                                                               {panerror1 != null && panerror1 != undefined ? <span className="error">{panerror1}</span> : null}
                                                            </div>
                                                          </div>
                                                          <div className="col-xl-6 pl-0 float-left">
                                                            <div className="email-input input_field pl-0">
                                                              <label className="input-title">Mobile Number</label>
                                                              <Field
                                                                as="input"
                                                                maxLength={10}
                                                                placeholder="Enter Mobile Number"
                                                                onChange={(e: any) => {
                                                                  handleNomineeDetails(1, "nominee_guardian_mobile_number", e.target.value, true);
                                                                }}
                                                                onBlur={(e: any) => {
                                                                  handleNomineeDetails(1, "nominee_guardian_mobile_number", e.target.value, true);
                                                                }}
                                                                name="nominee_guardian_mobile_number"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Fragment>
                                                )}
                                                <div className="col-xl-8 p-0 float-left">
                                                  <div className="email-input input_field pl-0">
                                                    <label className="input-title">Address (Same as the Applicant)</label>
                                                    <textarea rows={4} disabled className="text-field" value={userDetail?.personal_data_address_line_1 + "," + userDetail?.personal_data_address_line_2 + "," + "\n" + userDetail?.personal_data_address_city + "," + "\n" + userDetail?.personal_data_address_state + "."}></textarea>
                                                  </div>
                                                </div>
                                                <div className="col-xl-4 p-0 float-left"></div>
                                                <div className="col-xl-12 p-0 mt-10 mb-10 float-left">
                                                  <input
                                                    type="checkbox"
                                                    id="terms"
                                                    name="nominee_address_as_user"
                                                    onChange={(e: any) => {
                                                      if (e.target.checked) {
                                                        setNomineeAddress1(true);
                                                        handleNomineeDetails(1, "nominee_address_as_user", 0, false);
                                                      } else {
                                                        setNomineeAddress1(false);
                                                        handleNomineeDetails(1, "nominee_address_as_user", 1, false);
                                                      }
                                                    }}
                                                  />{" "}
                                                  Got a different Address
                                                </div>
                                                {nomineeAddress1 == true && (
                                                  <Fragment>
                                                    <div className="col-xl-12 pl-0 float-left">
                                                      <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">
                                                        Enter Address Detail
                                                      </h6>
                                                    </div>
                                                    <div className="col-xl-8 p-0 float-left">
                                                      <div className="col-xl-6 pl-0 float-left  ">
                                                        <div className="email-input input_field pl-0">
                                                          <label className={errors.nominee_address_line_1 && touched.nominee_address_line_1 ? "error" : "input-title"}>
                                                            Address line 1 <span className="error-area">*</span>
                                                          </label>
                                                          <Field
                                                            as="input"
                                                            className={errors.nominee_address_line_1 && touched.nominee_address_line_1 ? "input-error" : ""}
                                                            type="text"
                                                            placeholder="Enter Address line 1"
                                                            name="nominee_address_line_1"
                                                            onChange={(e: any) => {
                                                              handleChange(e);
                                                              handleNomineeDetails(1, "nominee_address_line_1", e.target.value, false);
                                                            }}
                                                            onBlur={(e: any) => {
                                                              handleBlur(e);
                                                              handleNomineeDetails(1, "nominee_address_line_1", e.target.value, false);
                                                            }}
                                                          />
                                                          {errors.nominee_address_line_1 && touched.nominee_address_line_1 && <span className="error">{errors.nominee_address_line_1}</span>}
                                                        </div>
                                                      </div>
                                                      <div className="col-xl-6 pl-0 float-left  ">
                                                        <div className="email-input input_field pl-0">
                                                          <label className={errors.nominee_address_line_2 && touched.nominee_address_line_2 ? "error" : "input-title"}>
                                                            Address line 2 <span className="error-area">*</span>
                                                          </label>
                                                          <Field
                                                            as="input"
                                                            className={errors.nominee_address_line_2 && touched.nominee_address_line_2 ? "input-error" : ""}
                                                            type="text"
                                                            placeholder="Enter Address line 2"
                                                            name="nominee_address_line_2"
                                                            onChange={(e: any) => {
                                                              handleChange(e);
                                                              handleNomineeDetails(1, "nominee_address_line_2", e.target.value, false);
                                                            }}
                                                            onBlur={(e: any) => {
                                                              handleBlur(e);
                                                              handleNomineeDetails(1, "nominee_address_line_2", e.target.value, false);
                                                            }}
                                                          />
                                                          {errors.nominee_address_line_2 && touched.nominee_address_line_2 && <span className="error">{errors.nominee_address_line_2}</span>}
                                                        </div>
                                                      </div>
                                                      <div className="col-xl-6 pl-0 float-left">
                                                        <div className="email-input input_field pl-0">
                                                          <label className={errors.nominee_address_pincode && touched.nominee_address_pincode ? "error" : "input-title"}>
                                                            Pincode <span className="error-area">*</span>
                                                          </label>
                                                          <Field
                                                            as="input"
                                                            className={errors.nominee_address_pincode && touched.nominee_address_pincode ? "input-error" : ""}
                                                            type="text"
                                                            placeholder="Enter Pincode"
                                                            name="nominee_address_pincode"
                                                            maxLength={6}
                                                            onChange={(e: any) => {
                                                              handleChange(e);
                                                              handleNomineeDetails(1, "nominee_address_pincode", e.target.value, false);
                                                            }}
                                                            onBlur={(e: any) => {
                                                              setPincode1(e.target.value);
                                                              handleNomineeDetails(1, "nominee_address_pincode", e.target.value, false);
                                                            }}
                                                          />
                                                          {errors.nominee_address_pincode && touched.nominee_address_pincode && <span className="error">{errors.nominee_address_pincode}</span>}
                                                        </div>
                                                      </div>
                                                      <div className="col-xl-6 pl-0 float-left">
                                                        <div className="email-input input_field pl-0">
                                                          <label className={errors.nominee_address_state && touched.nominee_address_state ? "error" : "input-title"}>
                                                            State <span className="error-area">*</span>
                                                          </label>
                                                          <Field
                                                            as="input"
                                                            className={errors.nominee_address_state && touched.nominee_address_state ? "input-error" : ""}
                                                            type="text"
                                                            placeholder="Enter State"
                                                            name="nominee_address_state"
                                                            value={state1}
                                                            onChange={(e: any) => {
                                                              handleChange(e);
                                                              handleNomineeDetails(1, "nominee_address_state", e.target.value, false);
                                                            }}
                                                            onBlur={(e: any) => {
                                                              handleBlur(e);
                                                              handleNomineeDetails(1, "nominee_address_state", e.target.value, false);
                                                            }}
                                                          />
                                                          {errors.nominee_address_state && touched.nominee_address_state && <span className="error">{errors.nominee_address_state}</span>}
                                                        </div>
                                                      </div>
                                                      <div className="col-xl-6 pl-0 float-left">
                                                        <div className="email-input input_field pl-0">
                                                          <label className={errors.nominee_address_city && touched.nominee_address_city ? "error" : "input-title"}>
                                                            City <span className="error-area">*</span>
                                                          </label>
                                                          <Field
                                                            as="input"
                                                            value={cityValue1}
                                                            className={errors.nominee_address_city && touched.nominee_address_city ? "input-error" : ""}
                                                            type="text"
                                                            placeholder="Enter City"
                                                            name="nominee_address_city"
                                                            onChange={(e: any) => {
                                                              handleChange(e);
                                                              handleNomineeDetails(1, "nominee_address_city", cityValue1, false);
                                                            }}
                                                            onBlur={(e: any) => {
                                                              handleBlur(e);
                                                              handleNomineeDetails(1, "nominee_address_city",cityValue1, false);
                                                            }}
                                                          />
                                                          {errors.nominee_address_city && touched.nominee_address_city && <span className="error">{errors.nominee_address_city}</span>}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Fragment>
                                                )}
                                                <div className="col-xl-4 p-0 float-left"></div>
                                              </form>
                                            )}
                                          </Formik>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                    </Accordion>
                                  </div>
                                  <div className="float-left col-xl-12 br-1px  mb-5">
                                    <Accordion allowZeroExpanded>
                                      <AccordionItem>
                                        <AccordionItemHeading>
                                          <AccordionItemButton>Nominee - 02</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <Formik
                                            initialValues={nomineeDetail2}
                                            enableReinitialize
                                            onSubmit={(values, { setSubmitting }) => {
                                              let data: APIData.NomineeData = { ...values };
                                              let valuedata: APIData.NomineeData = { ...values };
                                            }}
                                          >
                                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                              <form className="quote-form mb-10 nominee-form1" onSubmit={handleSubmit}>
                                                <div className="row">
                                                <div className="col-xl-8 p-0 float-left mt-1">
                                                  <div className="col-xl-6 pl-0 float-left  ">
                                                    <div className="email-input input_field pl-0">
                                                      <label className="input-title">Nominee Percentage</label>
                                                      <Field
                                                        as="input"
                                                        onChange={(e: any) => {
                                                          handleNomineeDetails(2, "nominee_percentage", e.target.value, false);
                                                        }}
                                                        onBlur={(e: any) => {
                                                          handleNomineeDetails(2, "nominee_percentage", e.target.value, false);
                                                        }}
                                                        placeholder="Enter Percentage"
                                                        name="nominee_percentage"
                                                      />
                                                       <span className="error">Share percentage for one/all nominee should be 100%</span>
                                                    </div>
                                                  </div>
                                                </div>
                                                </div>
                                                <div className="col-xl-8 p-0 float-left">
                                                  <div className="row">
                                                  <div className="col-xl-6 pl-0 float-left  ">
                                                    <div className="email-input input_field pl-0">
                                                      <label className="input-title">Title</label>
                                                      <Field
                                                        as="select"
                                                        name="name_title_id"
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          handleNomineeDetails(2, "name_title_id", e.target.value, false);
                                                        }}
                                                        onBlur={(e: any) => {
                                                          handleBlur(e);
                                                          handleNomineeDetails(2, "name_title_id", e.target.value, false);
                                                        }}
                                                      >
                                                        <option value={""}>select</option>
                                                        {nomineeTitles != undefined &&
                                                          nomineeTitles.length > 0 &&
                                                          nomineeTitles.map((title: APIData.Titles) => {
                                                            return <option value={title.name_title_id}>{title.name_title}</option>;
                                                          })}
                                                      </Field>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6 pl-0 float-left  ">
                                                    <div className="email-input input_field pl-0">
                                                      <label className={errors.nominee_fullname && touched.nominee_fullname ? "error" : "input-title"}>
                                                        Full Name <span className="error-area">*</span>
                                                      </label>
                                                      <Field
                                                        as="input"
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          handleNomineeDetails(2, "nominee_fullname", e.target.value, false);
                                                        }}
                                                        onBlur={(e: any) => {
                                                          handleBlur(e);
                                                          handleNomineeDetails(2, "nominee_fullname", e.target.value, false);
                                                        }}
                                                        placeholder="Enter First Name"
                                                        name="nominee_fullname"
                                                      />
                                                    </div>
                                                    {errors.nominee_fullname && touched.nominee_fullname && <span className="error">{errors.nominee_fullname}</span>}
                                                  </div>
                                                  <div className="col-xl-6 pl-0 float-left">
                                                    <div className="email-input input_field pl-0">
                                                      <label className={errors.relationship_with_applicant_id && touched.relationship_with_applicant_id ? "error" : "input-title"}>Relationship with Application</label>
                                                      <Field
                                                        className=""
                                                        name="relationship_with_applicant_id"
                                                        as="select"
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          handleNomineeDetails(2, "relationship_with_applicant_id", e.target.value, false);
                                                        }}
                                                        onBlur={(e: any) => {
                                                          handleBlur(e);
                                                          handleNomineeDetails(2, "relationship_with_applicant_id", e.target.value, false);
                                                        }}
                                                      >
                                                        <option value="0">select</option>
                                                        {relationshipDropdown != undefined &&
                                                          relationshipDropdown.length > 0 &&
                                                          relationshipDropdown.map((relation: APIData.Relationship, index) => {
                                                            return (
                                                              <option key={index} value={relation.relationship_with_applicant_id}>
                                                                {relation.relationship_with_applicant}
                                                              </option>
                                                            );
                                                          })}
                                                      </Field>
                                                    </div>
                                                    {errors.relationship_with_applicant_id && touched.relationship_with_applicant_id && <span className="error">{errors.relationship_with_applicant_id}</span>}
                                                  </div>
                                                  <div className="col-xl-6 pl-0 float-left">
                                                    <div className="email-input input_field pl-0">
                                                      <label className="input-title"> Nominee Identification</label>
                                                      <Field
                                                        as="select"
                                                        className=""
                                                        name="nominee_identification_id"
                                                        value={values.nominee_identification_id}
                                                        onChange={(e: any) => {
                                                          if (e.target.value == 0) {
                                                            e.preventDefault();
                                                            handleChange(e);
                                                            handleBlur(e);
                                                            setProofType2("Proof");
                                                            handleNomineeDetails(2, "nominee_identification_id", 0, false);
                                                          } else {
                                                            e.preventDefault();
                                                            handleChange(e);
                                                            handleBlur(e);
                                                            setProofType2(e.nativeEvent.target[e.target.selectedIndex].text);
                                                            handleNomineeDetails(2, "nominee_identification_id", e.target.value, false);
                                                          }
                                                        }}
                                                      >
                                                        <option>select</option>
                                                        {nomineeDropdown != undefined &&
                                                          nomineeDropdown.length > 0 &&
                                                          nomineeDropdown.map((proof: APIData.NomineeProof, index) => {
                                                            return (
                                                              <option key={index} value={proof.nominee_identification_id}>
                                                                {proof.nominee_identification}
                                                              </option>
                                                            );
                                                          })}
                                                      </Field>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6 pl-0 float-left">
                                                    <div className="email-input input_field pl-0">
                                                      <label className="input-title">{proofType2} Number</label>
                                                      <Field
                                                        as="input"
                                                        placeholder={"Enter" + " " + proofType2 + " " + "Number"}
                                                        maxLength={proofType2 == "PAN" ? "10" : proofType2 == "Passport" ? "8" : proofType2 == "Aadhar" ? "12" : null}
                                                        validate={validate1}
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          handleNomineeDetails(2, "nominee_identification_number", e.target.value.toUpperCase(), false);
                                                        }}
                                                        onBlur={(e: any) => {
                                                          handleBlur(e);
                                                          handleNomineeDetails(2, "nominee_identification_number", e.target.value.toUpperCase(), false);
                                                        }}
                                                        name="nominee_identification_number"
                                                      />
                                                      {errors.nominee_identification_number && touched.nominee_identification_number ? <span className="error">{errors.nominee_identification_number}</span> : null}
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6 pl-0 float-left">
                                                    <div className="email-input input_field pl-0">
                                                      <label className={errors.nominee_dob ? "error" : "input-title"}>Date of Birth</label>
                                                      <DatePicker peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" maxDate={new Date(maxDate)} minDate={new Date("1900-01-01")} className="date-picker-input profile-calendar mobile_number" name="nominee_dob" selected={startDate2} onChange={(date: any) => setStartDate2(date)} />
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6 pl-0 float-left">
                                                    <div className="email-input input_field pl-0">
                                                      <label className="input-title">Mobile Number</label>
                                                      <Field
                                                        as="input"
                                                        maxLength={10}
                                                        placeholder="Enter Mobile Number"
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          handleNomineeDetails(2, "nominee_mobile_number", e.target.value, false);
                                                        }}
                                                        onBlur={(e: any) => {
                                                          handleBlur(e);
                                                          handleNomineeDetails(2, "nominee_mobile_number", e.target.value, false);
                                                        }}
                                                        name="nominee_mobile_number"
                                                      />
                                                    </div>
                                                  </div>
                                                  </div>
                                                </div>
                                                {minor2 && values.nominee_guardian_records != undefined && (
                                                  <Fragment>
                                                    <div className="col-xl-12 p-0 float-left guardian-details">
                                                      <h3>
                                                        <label className="input-title nominee-guardian-title">Guardian Details</label>
                                                      </h3>
                                                      <div className="email-input input_field pl-0">
                                                        <div className="col-xl-8 p-0 float-left">
                                                          <div className="col-xl-6 pl-0 float-left  ">
                                                            <div className="email-input input_field pl-0">
                                                              <label className="input-title">Title</label>
                                                              <Field
                                                                as="select"
                                                                name="nominee_name_title_id"
                                                                onChange={(e: any) => {
                                                                  handleNomineeDetails(2, "nominee_name_title_id", e.target.value, true);
                                                                }}
                                                                onBlur={(e: any) => {
                                                                  handleNomineeDetails(2, "nominee_name_title_id", e.target.value, true);
                                                                }}
                                                              >
                                                                <option value={""}>select</option>
                                                                {nomineeTitles != undefined &&
                                                                  nomineeTitles.length > 0 &&
                                                                  nomineeTitles.map((title: APIData.Titles) => {
                                                                    return <option value={title.name_title_id}>{title.name_title}</option>;
                                                                  })}
                                                              </Field>
                                                            </div>
                                                          </div>
                                                          <div className="col-xl-6 pl-0 float-left  ">
                                                            <div className="email-input input_field pl-0">
                                                              <label className={errors.nominee_fullname && touched.nominee_fullname ? "error" : "input-title"}>
                                                                Full Name <span className="error-area">*</span>
                                                              </label>
                                                              <Field
                                                                as="input"
                                                                onChange={(e: any) => {
                                                                  handleNomineeDetails(2, "nominee_guardian_fullname", e.target.value, true);
                                                                }}
                                                                onBlur={(e: any) => {
                                                                  handleNomineeDetails(2, "nominee_guardian_fullname", e.target.value, true);
                                                                }}
                                                                placeholder="Enter First Name"
                                                                name="nominee_guardian_fullname"
                                                              />
                                                            </div>
                                                            {errors.nominee_fullname && touched.nominee_fullname && <span className="error">{errors.nominee_fullname}</span>}
                                                          </div>
                                                          <div className="col-xl-6 pl-0 float-left">
                                                            <div className="email-input input_field pl-0">
                                                              <label className={errors.nominee_guardian_records?.nominee_relationship_with_applicant_id && touched.nominee_guardian_records?.nominee_relationship_with_applicant_id ? "error" : "input-title"}>Relationship with Application</label>
                                                              <Field
                                                                className=""
                                                                name="nominee_relationship_with_applicant_id"
                                                                as="select"
                                                                onChange={(e: any) => {
                                                                  handleNomineeDetails(2, "nominee_relationship_with_applicant_id", e.target.value, true);
                                                                }}
                                                                onBlur={(e: any) => {
                                                                  handleNomineeDetails(2, "nominee_relationship_with_applicant_id", e.target.value, true);
                                                                }}
                                                              >
                                                                <option value="0">select</option>
                                                                {relationshipDropdown != undefined &&
                                                                  relationshipDropdown.length > 0 &&
                                                                  relationshipDropdown.map((relation: APIData.Relationship, index) => {
                                                                    return (
                                                                      <option key={index} value={relation.relationship_with_applicant_id}>
                                                                        {relation.relationship_with_applicant}
                                                                      </option>
                                                                    );
                                                                  })}
                                                              </Field>
                                                            </div>
                                                            {errors.nominee_guardian_records?.nominee_relationship_with_applicant_id && touched.nominee_guardian_records?.nominee_relationship_with_applicant_id && <span className="error">{errors.nominee_guardian_records?.nominee_relationship_with_applicant_id}</span>}
                                                          </div>
                                                          <div className="col-xl-6 pl-0 float-left">
                                                            <div className="email-input input_field pl-0">
                                                              <label className="input-title">Guardian Identification</label>
                                                              <Field
                                                                as="select"
                                                                className=""
                                                                name="nominee_guardian_identification_id"
                                                                onChange={(e: any) => {
                                                                  if (e.target.value == 0) {
                                                                    e.preventDefault();
                                                                    handleChange(e);
                                                                    handleBlur(e);
                                                                    setgProofType2("Proof");
                                                                    handleNomineeDetails(2, "nominee_guardian_identification_id", 0, true);
                                                                  } else {
                                                                    e.preventDefault();
                                                                    handleChange(e);
                                                                    handleBlur(e);
                                                                    setgProofType2(e.nativeEvent.target[e.target.selectedIndex].text);
                                                                    handleNomineeDetails(2, "nominee_guardian_identification_id", e.target.value, true);
                                                                  }
                                                                }}
                                                              >
                                                                <option>select</option>
                                                                {nomineeDropdown != undefined &&
                                                                  nomineeDropdown.length > 0 &&
                                                                  nomineeDropdown.map((proof: APIData.NomineeProof, index) => {
                                                                    return (
                                                                      <option key={index} value={proof.nominee_identification_id}>
                                                                        {proof.nominee_identification}
                                                                      </option>
                                                                    );
                                                                  })}
                                                              </Field>
                                                            </div>
                                                          </div>
                                                          <div className="col-xl-6 pl-0 float-left">
                                                            <div className="email-input input_field pl-0">
                                                              <label className="input-title">{gproofType2} Number</label>
                                                              <Field
                                                                as="input"  
                                                                style={{ textTransform: 'uppercase'}}
                                                                maxLength={gproofType2 == "PAN" ? "10" : gproofType2 == "Passport" ? "8" : gproofType2 == "Aadhar" ? "12" : null}
                                                                // validate={validate4}
                                                                placeholder={"Enter" + " " + gproofType2 + " " + "Number"}
                                                                onChange={(e: any) => {
                                                                  handleChange(e);
                                                                  validate4(e.target.value.toUpperCase())
                                                                  handleNomineeDetails(2, "nominee_guardian_identification_number1", e.target.value.toUpperCase(), true);
                                                                }}
                                                                onBlur={(e: any) => {
                                                                  validate4(e.target.value.toUpperCase())
                                                                  handleBlur(e);
                                                                  handleNomineeDetails(2, "nominee_guardian_identification_number1", e.target.value.toUpperCase(), true);
                                                                }}
                                                                name="nominee_guardian_identification_number1"
                                                              />
                                                              {/* {errors ? console.log(errors) :console.log("errors")} */}
                                                              {panerror2 != null && panerror2 != undefined ? <span className="error">{panerror2}</span> : null}                                                          
                                                            </div>
                                                          </div>
                                                          <div className="col-xl-6 pl-0 float-left">
                                                            <div className="email-input input_field pl-0">
                                                              <label className="input-title">Mobile Number</label>
                                                              <Field
                                                                as="input"
                                                                maxLength={10}
                                                                placeholder="Enter Mobile Number"
                                                                onChange={(e: any) => {
                                                                  handleNomineeDetails(2, "nominee_guardian_mobile_number", e.target.value, true);
                                                                }}
                                                                onBlur={(e: any) => {
                                                                  handleNomineeDetails(2, "nominee_guardian_mobile_number", e.target.value, true);
                                                                }}
                                                                name="nominee_guardian_mobile_number"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Fragment>
                                                )}
                                                <div className="col-xl-8 p-0 float-left">
                                                  <div className="email-input input_field pl-0">
                                                    <label className="input-title">Address (Same as the Applicant)</label>
                                                    <textarea rows={4} disabled className="text-field" value={userDetail?.personal_data_address_line_1 + "," + userDetail?.personal_data_address_line_2 + "," + "\n" + userDetail?.personal_data_address_city + "," + "\n" + userDetail?.personal_data_address_state + "."}></textarea>
                                                  </div>
                                                </div>
                                                <div className="col-xl-4 p-0 float-left"></div>
                                                <div className="col-xl-12 p-0 mt-10 mb-10 float-left">
                                                  <input
                                                    type="checkbox"
                                                    id="terms"
                                                    onChange={(e: any) => {
                                                      if (e.target.checked) {
                                                        setNomineeAddress2(true);
                                                        handleNomineeDetails(2, "nominee_address_as_user", 0, false);
                                                      } else {
                                                        setNomineeAddress2(false);
                                                        handleNomineeDetails(2, "nominee_address_as_user", 1, false);
                                                      }
                                                    }}
                                                  />{" "}
                                                  Got a different Address
                                                </div>
                                                {nomineeAddress2 == true && (
                                                  <Fragment>
                                                    <div className="col-xl-12 pl-0 float-left">
                                                      <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">
                                                        Enter Address Detail
                                                      </h6>
                                                    </div>
                                                    <div className="col-xl-8 p-0 float-left">
                                                      <div className="col-xl-6 pl-0 float-left  ">
                                                        <div className="email-input input_field pl-0">
                                                          <label className={errors.nominee_address_line_1 && touched.nominee_address_line_1 ? "error" : "input-title"}>
                                                            Address line 1 <span className="error-area">*</span>
                                                          </label>
                                                          <Field
                                                            as="input"
                                                            className={errors.nominee_address_line_1 && touched.nominee_address_line_1 ? "input-error" : ""}
                                                            type="text"
                                                            placeholder="Enter Address line 1"
                                                            name="nominee_address_line_1"
                                                            value={values.nominee_address_line_1}
                                                            onChange={(e: any) => {
                                                              handleChange(e);
                                                              handleNomineeDetails(2, "nominee_address_line_1", e.target.value, false);
                                                            }}
                                                            onBlur={(e: any) => {
                                                              handleBlur(e);
                                                              handleNomineeDetails(2, "nominee_address_line_1", e.target.value, false);
                                                            }}
                                                          />
                                                          {errors.nominee_address_line_1 && touched.nominee_address_line_1 && <span className="error">{errors.nominee_address_line_1}</span>}
                                                        </div>
                                                      </div>
                                                      <div className="col-xl-6 pl-0 float-left  ">
                                                        <div className="email-input input_field pl-0">
                                                          <label className={errors.nominee_address_line_2 && touched.nominee_address_line_2 ? "error" : "input-title"}>
                                                            Address line 2 <span className="error-area">*</span>
                                                          </label>
                                                          <Field
                                                            as="input"
                                                            className={errors.nominee_address_line_2 && touched.nominee_address_line_2 ? "input-error" : ""}
                                                            type="text"
                                                            placeholder="Enter Address line 2"
                                                            name="nominee_address_line_2"
                                                            value={values.nominee_address_line_2}
                                                            onChange={(e: any) => {
                                                              handleChange(e);
                                                              handleNomineeDetails(2, "nominee_address_line_2", e.target.value, false);
                                                            }}
                                                            onBlur={(e: any) => {
                                                              handleBlur(e);
                                                              handleNomineeDetails(2, "nominee_address_line_2", e.target.value, false);
                                                            }}
                                                          />
                                                          {errors.nominee_address_line_2 && touched.nominee_address_line_2 && <span className="error">{errors.nominee_address_line_2}</span>}
                                                        </div>
                                                      </div>
                                                      <div className="col-xl-6 pl-0 float-left">
                                                        <div className="email-input input_field pl-0">
                                                          <label className={errors.nominee_address_pincode && touched.nominee_address_pincode ? "error" : "input-title"}>
                                                            Pincode <span className="error-area">*</span>
                                                          </label>
                                                          <Field
                                                            as="input"
                                                            className={errors.nominee_address_pincode && touched.nominee_address_pincode ? "input-error" : ""}
                                                            type="text"
                                                            placeholder="Enter Pincode"
                                                            name="nominee_address_pincode"
                                                            maxLength={6}
                                                            onChange={(e: any) => {
                                                              handleChange(e);
                                                              handleNomineeDetails(2, "nominee_address_pincode", e.target.value, false);
                                                            }}
                                                            onBlur={(e: any) => {
                                                              setPincode2(e.target.value);
                                                              handleNomineeDetails(2, "nominee_address_pincode", e.target.value, false);
                                                            }}
                                                          />
                                                          {errors.nominee_address_pincode && touched.nominee_address_pincode && <span className="error">{errors.nominee_address_pincode}</span>}
                                                        </div>
                                                      </div>
                                                      <div className="col-xl-6 pl-0 float-left">
                                                        <div className="email-input input_field pl-0">
                                                          <label className={errors.nominee_address_state && touched.nominee_address_state ? "error" : "input-title"}>
                                                            State <span className="error-area">*</span>
                                                          </label>
                                                          <Field
                                                            as="input"
                                                            className={errors.nominee_address_state && touched.nominee_address_state ? "input-error" : ""}
                                                            type="text"
                                                            placeholder="Enter State"
                                                            name="nominee_address_state"
                                                            value={state2}
                                                            onChange={(e: any) => {
                                                              handleChange(e);
                                                              handleNomineeDetails(2, "nominee_address_state", e.target.value, false);
                                                            }}
                                                            onBlur={(e: any) => {
                                                              handleBlur(e);
                                                              handleNomineeDetails(2, "nominee_address_state", e.target.value, false);
                                                            }}
                                                          />
                                                          {errors.nominee_address_state && touched.nominee_address_state && <span className="error">{errors.nominee_address_state}</span>}
                                                        </div>
                                                      </div>
                                                      <div className="col-xl-6 pl-0 float-left">
                                                        <div className="email-input input_field pl-0">
                                                          <label className={errors.nominee_address_city && touched.nominee_address_city ? "error" : "input-title"}>
                                                            City <span className="error-area">*</span>
                                                          </label>
                                                          <Field
                                                            as="input"
                                                            value={cityValue2}
                                                            className={errors.nominee_address_city && touched.nominee_address_city ? "input-error" : ""}
                                                            type="text"
                                                            placeholder="Enter City"
                                                            name="nominee_address_city"
                                                            onChange={(e: any) => {
                                                              handleChange(e);
                                                              handleNomineeDetails(2, "nominee_address_city", e.target.value, false);
                                                            }}
                                                            onBlur={(e: any) => {
                                                              handleBlur(e);
                                                              handleNomineeDetails(2, "nominee_address_city", e.target.value, false);
                                                            }}
                                                          />
                                                          {errors.nominee_address_city && touched.nominee_address_city && <span className="error">{errors.nominee_address_city}</span>}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Fragment>
                                                )}
                                                <div className="col-xl-4 p-0 float-left"></div>
                                              </form>
                                            )}
                                          </Formik>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                    </Accordion>
                                  </div>
                                  <div className="float-left col-xl-12 br-1px  mb-5">
                                    <Accordion allowZeroExpanded>
                                      <AccordionItem>
                                        <AccordionItemHeading>
                                          <AccordionItemButton>Nominee - 03</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <div>
                                            <Formik
                                              initialValues={nomineeDetail3}
                                              enableReinitialize
                                              onSubmit={(values, { setSubmitting }) => {
                                                let data: APIData.NomineeData = { ...values };
                                                let valuedata: APIData.NomineeData = { ...values };
                                              }}
                                            >
                                              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                                <form className="quote-form mb-10 nominee-form1" onSubmit={handleSubmit}>
                                                  <div className="row">
                                                  <div className="col-xl-8 p-0 float-left mt-1">
                                                    <div className="col-xl-6 pl-0 float-left  ">
                                                      <div className="email-input input_field pl-0">
                                                        <label className="input-title">Nominee Percentage</label>
                                                        <Field
                                                          as="input"
                                                          onChange={(e: any) => {
                                                            handleChange(e);
                                                            handleNomineeDetails(3, "nominee_percentage", e.target.value, false);
                                                          }}
                                                          onBlur={(e: any) => {
                                                            handleBlur(e);
                                                            handleNomineeDetails(3, "nominee_percentage", e.target.value, false);
                                                          }}
                                                          placeholder="Enter Percentage"
                                                          name="nominee_percentage"
                                                        />
                                                         <span className="error">Share percentage for one/all nominee should be 100%</span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  </div>
                                                  <div className="col-xl-8 p-0 float-left">
                                                    <div className="row">
                                                    <div className="col-xl-6 pl-0 float-left  ">
                                                      <div className="email-input input_field pl-0">
                                                        <label className="input-title">Title</label>
                                                        <Field
                                                          as="select"
                                                          name="name_title_id"
                                                          onChange={(e: any) => {
                                                            handleChange(e);
                                                            handleNomineeDetails(3, "name_title_id", e.target.value, false);
                                                          }}
                                                          onBlur={(e: any) => {
                                                            handleBlur(e);
                                                            handleNomineeDetails(3, "name_title_id", e.target.value, false);
                                                          }}
                                                        >
                                                          <option value={""}>select</option>
                                                          {nomineeTitles != undefined &&
                                                            nomineeTitles.length > 0 &&
                                                            nomineeTitles.map((title: APIData.Titles) => {
                                                              return <option value={title.name_title_id}>{title.name_title}</option>;
                                                            })}
                                                        </Field>
                                                      </div>
                                                    </div>
                                                    <div className="col-xl-6 pl-0 float-left  ">
                                                      <div className="email-input input_field pl-0">
                                                        <label className={errors.nominee_fullname && touched.nominee_fullname ? "error" : "input-title"}>
                                                          Full Name <span className="error-area">*</span>
                                                        </label>
                                                        <Field
                                                          as="input"
                                                          onChange={(e: any) => {
                                                            handleChange(e);
                                                            handleNomineeDetails(3, "nominee_fullname", e.target.value, false);
                                                          }}
                                                          onBlur={(e: any) => {
                                                            handleBlur(e);
                                                            handleNomineeDetails(3, "nominee_fullname", e.target.value, false);
                                                          }}
                                                          placeholder="Enter First Name"
                                                          name="nominee_fullname"
                                                        />
                                                      </div>
                                                      {errors.nominee_fullname && touched.nominee_fullname && <span className="error">{errors.nominee_fullname}</span>}
                                                    </div>
                                                    <div className="col-xl-6 pl-0 float-left">
                                                      <div className="email-input input_field pl-0">
                                                        <label className={errors.relationship_with_applicant_id && touched.relationship_with_applicant_id ? "error" : "input-title"}>Relationship with Application</label>
                                                        <Field
                                                          className=""
                                                          name="relationship_with_applicant_id"
                                                          as="select"
                                                          onChange={(e: any) => {
                                                            handleChange(e);
                                                            handleNomineeDetails(3, "relationship_with_applicant_id", e.target.value, false);
                                                          }}
                                                          onBlur={(e: any) => {
                                                            handleBlur(e);
                                                            handleNomineeDetails(3, "relationship_with_applicant_id", e.target.value, false);
                                                          }}
                                                        >
                                                          <option value="0">select</option>
                                                          {relationshipDropdown != undefined &&
                                                            relationshipDropdown.length > 0 &&
                                                            relationshipDropdown.map((relation: APIData.Relationship, index) => {
                                                              return (
                                                                <option key={index} value={relation.relationship_with_applicant_id}>
                                                                  {relation.relationship_with_applicant}
                                                                </option>
                                                              );
                                                            })}
                                                        </Field>
                                                      </div>
                                                      {errors.relationship_with_applicant_id && touched.relationship_with_applicant_id && <span className="error">{errors.relationship_with_applicant_id}</span>}
                                                    </div>
                                                    <div className="col-xl-6 pl-0 float-left">
                                                      <div className="email-input input_field pl-0">
                                                        <label className="input-title">Nominee Identification</label>
                                                        <Field
                                                          as="select"
                                                          className=""
                                                          name="nominee_identification_id"
                                                          value={values.nominee_identification_id}
                                                          onChange={(e: any) => {
                                                            if (e.target.value == 0) {
                                                              e.preventDefault();
                                                              handleChange(e);
                                                              handleBlur(e);
                                                              setProofType3("Proof");
                                                              handleNomineeDetails(3, "nominee_identification_id", 0, false);
                                                            } else {
                                                              e.preventDefault();
                                                              handleChange(e);
                                                              handleBlur(e);
                                                              setProofType3(e.nativeEvent.target[e.target.selectedIndex].text);
                                                              handleNomineeDetails(3, "nominee_identification_id", e.target.value, false);
                                                            }
                                                          }}
                                                        >
                                                          <option>select</option>
                                                          {nomineeDropdown != undefined &&
                                                            nomineeDropdown.length > 0 &&
                                                            nomineeDropdown.map((proof: APIData.NomineeProof, index) => {
                                                              return (
                                                                <option key={index} value={proof.nominee_identification_id}>
                                                                  {proof.nominee_identification}
                                                                </option>
                                                              );
                                                            })}
                                                        </Field>
                                                      </div>
                                                    </div>
                                                    <div className="col-xl-6 pl-0 float-left">
                                                      <div className="email-input input_field pl-0">
                                                        <label className="input-title">{proofType3} Number</label>
                                                        <Field
                                                          as="input"
                                                          placeholder={"Enter" + " " + proofType3 + " " + "Number"}
                                                          maxLength={proofType3 == "PAN" ? "10" : proofType3 == "Passport" ? "8" : proofType3 == "Aadhar" ? "12" : null}
                                                          validate={validate2}
                                                          onChange={(e: any) => {
                                                            handleChange(e);
                                                            handleNomineeDetails(3, "nominee_identification_number", e.target.value.toUpperCase(), false);
                                                          }}
                                                          onBlur={(e: any) => {
                                                            handleBlur(e);
                                                            handleNomineeDetails(3, "nominee_identification_number", e.target.value.toUpperCase(), false);
                                                          }}
                                                          name="nominee_identification_number"
                                                        />
                                                        {errors.nominee_identification_number && touched.nominee_identification_number ? <span className="error">{errors.nominee_identification_number}</span> : null}
                                                      </div>
                                                    </div>
                                                    <div className="col-xl-6 pl-0 float-left">
                                                      <div className="email-input input_field pl-0">
                                                        <label className={errors.nominee_dob ? "error" : "input-title"}>Date of Birth</label>
                                                        <DatePicker peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" maxDate={new Date(maxDate)} minDate={new Date("1900-01-01")} className="date-picker-input profile-calendar mobile_number" name="nominee_dob" selected={startDate3} onChange={(date: any) => setStartDate3(date)} />
                                                      </div>
                                                    </div>
                                                    <div className="col-xl-6 pl-0 float-left">
                                                      <div className="email-input input_field pl-0">
                                                        <label className="input-title">Mobile Number</label>
                                                        <Field
                                                          as="input"
                                                          maxLength={10}
                                                          placeholder="Enter Mobile Number"
                                                          onChange={(e: any) => {
                                                            handleChange(e);
                                                            handleNomineeDetails(3, "nominee_mobile_number", e.target.value, false);
                                                          }}
                                                          onBlur={(e: any) => {
                                                            handleBlur(e);
                                                            handleNomineeDetails(3, "nominee_mobile_number", e.target.value, false);
                                                          }}
                                                          name="nominee_mobile_number"
                                                        />
                                                      </div>
                                                    </div>
                                                    </div>
                                                  </div>
                                                  {minor3 && values.nominee_guardian_records != undefined && (
                                                    <Fragment>
                                                      <div className="col-xl-12 p-0 float-left guardian-details">
                                                        <h3>
                                                          <label className="input-title nominee-guardian-title">Guardian Details</label>
                                                        </h3>
                                                        <div className="email-input input_field pl-0">
                                                          <div className="col-xl-8 p-0 float-left">
                                                            <div className="col-xl-6 pl-0 float-left  ">
                                                              <div className="email-input input_field pl-0">
                                                                <label className="input-title">Title</label>
                                                                <Field
                                                                  as="select"
                                                                  name="nominee_name_title_id"
                                                                  onChange={(e: any) => {
                                                                    handleNomineeDetails(3, "nominee_name_title_id", e.target.value, true);
                                                                  }}
                                                                  onBlur={(e: any) => {
                                                                    handleNomineeDetails(3, "nominee_name_title_id", e.target.value, true);
                                                                  }}
                                                                >
                                                                  <option value={""}>select</option>
                                                                  {nomineeTitles != undefined &&
                                                                    nomineeTitles.length > 0 &&
                                                                    nomineeTitles.map((title: APIData.Titles) => {
                                                                      return <option value={title.name_title_id}>{title.name_title}</option>;
                                                                    })}
                                                                </Field>
                                                              </div>
                                                            </div>
                                                            <div className="col-xl-6 pl-0 float-left  ">
                                                              <div className="email-input input_field pl-0">
                                                                <label className={errors.nominee_fullname && touched.nominee_fullname ? "error" : "input-title"}>
                                                                  Full Name <span className="error-area">*</span>
                                                                </label>
                                                                <Field
                                                                  as="input"
                                                                  onChange={(e: any) => {
                                                                    handleNomineeDetails(3, "nominee_guardian_fullname", e.target.value, true);
                                                                  }}
                                                                  onBlur={(e: any) => {
                                                                    handleNomineeDetails(3, "nominee_guardian_fullname", e.target.value, true);
                                                                  }}
                                                                  placeholder="Enter First Name"
                                                                  name="nominee_guardian_fullname"
                                                                />
                                                              </div>
                                                              {errors.nominee_fullname && touched.nominee_fullname && <span className="error">{errors.nominee_fullname}</span>}
                                                            </div>
                                                            <div className="col-xl-6 pl-0 float-left">
                                                              <div className="email-input input_field pl-0">
                                                                <label className={errors.nominee_guardian_records?.nominee_relationship_with_applicant_id && touched.nominee_guardian_records?.nominee_relationship_with_applicant_id ? "error" : "input-title"}>Relationship with Application</label>
                                                                <Field
                                                                  className=""
                                                                  name="nominee_relationship_with_applicant_id"
                                                                  as="select"
                                                                  onChange={(e: any) => {
                                                                    handleNomineeDetails(3, "nominee_relationship_with_applicant_id", e.target.value, true);
                                                                  }}
                                                                  onBlur={(e: any) => {
                                                                    handleNomineeDetails(3, "nominee_relationship_with_applicant_id", e.target.value, true);
                                                                  }}
                                                                >
                                                                  <option value="0">select</option>
                                                                  {relationshipDropdown != undefined &&
                                                                    relationshipDropdown.length > 0 &&
                                                                    relationshipDropdown.map((relation: APIData.Relationship, index) => {
                                                                      return (
                                                                        <option key={index} value={relation.relationship_with_applicant_id}>
                                                                          {relation.relationship_with_applicant}
                                                                        </option>
                                                                      );
                                                                    })}
                                                                </Field>
                                                              </div>
                                                              {errors.nominee_guardian_records?.nominee_relationship_with_applicant_id && touched.nominee_guardian_records?.nominee_relationship_with_applicant_id && <span className="error">{errors.nominee_guardian_records?.nominee_relationship_with_applicant_id}</span>}
                                                            </div>
                                                            <div className="col-xl-6 pl-0 float-left">
                                                              <div className="email-input input_field pl-0">
                                                                <label className="input-title">Guardian Identification</label>
                                                                <Field
                                                                  as="select"
                                                                  className=""
                                                                  name="nominee_guardian_identification_id"
                                                                  onChange={(e: any) => {
                                                                    if (e.target.value == 0) {
                                                                      e.preventDefault();
                                                                      handleChange(e);
                                                                      handleBlur(e);
                                                                      setgProofType3("Proof");
                                                                      handleNomineeDetails(3, "nominee_guardian_identification_id", 0, true);
                                                                    } else {
                                                                      e.preventDefault();
                                                                      handleChange(e);
                                                                      handleBlur(e);
                                                                      setgProofType3(e.nativeEvent.target[e.target.selectedIndex].text);
                                                                      handleNomineeDetails(3, "nominee_guardian_identification_id", e.target.value, true);
                                                                    }
                                                                  }}
                                                                >
                                                                  <option>select</option>
                                                                  {nomineeDropdown != undefined &&
                                                                    nomineeDropdown.length > 0 &&
                                                                    nomineeDropdown.map((proof: APIData.NomineeProof, index) => {
                                                                      return (
                                                                        <option key={index} value={proof.nominee_identification_id}>
                                                                          {proof.nominee_identification}
                                                                        </option>
                                                                      );
                                                                    })}
                                                                </Field>
                                                              </div>
                                                            </div>
                                                            <div className="col-xl-6 pl-0 float-left">
                                                              <div className="email-input input_field pl-0">
                                                                <label className="input-title">{gproofType3} Number</label>
                                                                <Field
                                                                as="input"
                                                                style={{ textTransform: 'uppercase'}}
                                                                placeholder={"Enter" + " " + gproofType3 + " " + "Number"}
                                                                maxLength={gproofType3 == "PAN" ? "10" : gproofType3 == "Passport" ? "8" : gproofType3 == "Aadhar" ? "12" : null}
                                                                // validate={validate5}
                                                                onChange={(e: any) => {
                                                                  validate5(e.target.value.toUpperCase())
                                                                  handleChange(e);
                                                                  handleNomineeDetails(3, "nominee_guardian_identification_number1", e.target.value.toUpperCase(), true);
                                                                }}
                                                                onBlur={(e: any) => {
                                                                  validate5(e.target.value.toUpperCase())
                                                                  handleBlur(e);
                                                                  handleNomineeDetails(3, "nominee_guardian_identification_number1", e.target.value.toUpperCase(), true);
                                                                }}
                                                                name="nominee_guardian_identification_number1"
                                                              />
                                                               {panerror3 != null && panerror3 != undefined ? <span className="error">{panerror3}</span> : null}

                                                               {/* {errors.nominee_guardian_identification_number && touched.nominee_guardian_identification_number ? <span className="error">{errors.nominee_guardian_identification_number}</span> : null} */}
                                                              </div>
                                                            </div>
                                                            <div className="col-xl-6 pl-0 float-left">
                                                              <div className="email-input input_field pl-0">
                                                                <label className="input-title">Mobile Number</label>
                                                                <Field
                                                                  as="input"
                                                                  maxLength={10}
                                                                  placeholder="Enter Mobile Number"
                                                                  onChange={(e: any) => {
                                                                    handleNomineeDetails(3, "nominee_guardian_mobile_number", e.target.value, true);
                                                                  }}
                                                                  onBlur={(e: any) => {
                                                                    handleNomineeDetails(3, "nominee_guardian_mobile_number", e.target.value, true);
                                                                  }}
                                                                  name="nominee_guardian_mobile_number"
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Fragment>
                                                  )}
                                                  <div className="col-xl-8 p-0 float-left">
                                                    <div className="email-input input_field pl-0">
                                                      <label className="input-title">Address (Same as the Applicant)</label>
                                                      <textarea rows={4} disabled className="text-field" value={userDetail?.personal_data_address_line_1 + "," + userDetail?.personal_data_address_line_2 + "," + "\n" + userDetail?.personal_data_address_city + "," + "\n" + userDetail?.personal_data_address_state + "-" + userDetail?.personal_data_address_pincode}></textarea>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-4 p-0 float-left"></div>
                                                  <div className="col-xl-12 p-0 mt-10 mb-10 float-left">
                                                    <input
                                                      type="checkbox"
                                                      id="terms"
                                                      onChange={(e: any) => {
                                                        if (e.target.checked) {
                                                          setNomineeAddress3(true);
                                                          handleNomineeDetails(3, "nominee_address_as_user", 0, false);
                                                        } else {
                                                          setNomineeAddress3(false);
                                                          handleNomineeDetails(3, "nominee_address_as_user", 1, false);
                                                        }
                                                      }}
                                                    />{" "}
                                                    Got a different Address
                                                  </div>
                                                  {nomineeAddress3 == true && (
                                                    <Fragment>
                                                      <div className="col-xl-12 pl-0 float-left">
                                                        <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">
                                                          Enter Address Detail
                                                        </h6>
                                                      </div>
                                                      <div className="col-xl-8 p-0 float-left">
                                                        <div className="col-xl-6 pl-0 float-left  ">
                                                          <div className="email-input input_field pl-0">
                                                            <label className={errors.nominee_address_line_1 && touched.nominee_address_line_1 ? "error" : "input-title"}>
                                                              Address line 1 <span className="error-area">*</span>
                                                            </label>
                                                            <Field
                                                              as="input"
                                                              className={errors.nominee_address_line_1 && touched.nominee_address_line_1 ? "input-error" : ""}
                                                              type="text"
                                                              value={values.nominee_address_line_1}
                                                              placeholder="Enter Address line 1"
                                                              name="nominee_address_line_1"
                                                              onChange={(e: any) => {
                                                                handleChange(e);
                                                                handleNomineeDetails(3, "nominee_address_line_1", e.target.value, false);
                                                              }}
                                                              onBlur={(e: any) => {
                                                                handleBlur(e);
                                                                handleNomineeDetails(3, "nominee_address_line_1", e.target.value, false);
                                                              }}
                                                            />
                                                            {errors.nominee_address_line_1 && touched.nominee_address_line_1 && <span className="error">{errors.nominee_address_line_1}</span>}
                                                          </div>
                                                        </div>
                                                        <div className="col-xl-6 pl-0 float-left  ">
                                                          <div className="email-input input_field pl-0">
                                                            <label className={errors.nominee_address_line_2 && touched.nominee_address_line_2 ? "error" : "input-title"}>
                                                              Address line 2 <span className="error-area">*</span>
                                                            </label>
                                                            <Field
                                                              as="input"
                                                              className={errors.nominee_address_line_2 && touched.nominee_address_line_2 ? "input-error" : ""}
                                                              type="text"
                                                              placeholder="Enter Address line 2"
                                                              value={values.nominee_address_line_2}
                                                              name="nominee_address_line_2"
                                                              onChange={(e: any) => {
                                                                handleChange(e);
                                                                handleNomineeDetails(3, "nominee_address_line_2", e.target.value, false);
                                                              }}
                                                              onBlur={(e: any) => {
                                                                handleBlur(e);
                                                                handleNomineeDetails(3, "nominee_address_line_2", e.target.value, false);
                                                              }}
                                                            />
                                                            {errors.nominee_address_line_2 && touched.nominee_address_line_2 && <span className="error">{errors.nominee_address_line_2}</span>}
                                                          </div>
                                                        </div>
                                                        <div className="col-xl-6 pl-0 float-left">
                                                          <div className="email-input input_field pl-0">
                                                            <label className={errors.nominee_address_pincode && touched.nominee_address_pincode ? "error" : "input-title"}>
                                                              Pincode <span className="error-area">*</span>
                                                            </label>
                                                            <Field
                                                              as="input"
                                                              className={errors.nominee_address_pincode && touched.nominee_address_pincode ? "input-error" : ""}
                                                              type="text"
                                                              placeholder="Enter Pincode"
                                                              name="nominee_address_pincode"
                                                              maxLength={6}
                                                              onChange={(e: any) => {
                                                                handleChange(e);
                                                                handleNomineeDetails(3, "nominee_address_pincode", e.target.value, false);
                                                              }}
                                                              onBlur={(e: any) => {
                                                                setPincode3(e.target.value);
                                                                handleNomineeDetails(3, "nominee_address_pincode", e.target.value, false);
                                                              }}
                                                            />
                                                            {errors.nominee_address_pincode && touched.nominee_address_pincode && <span className="error">{errors.nominee_address_pincode}</span>}
                                                          </div>
                                                        </div>
                                                        <div className="col-xl-6 pl-0 float-left">
                                                          <div className="email-input input_field pl-0">
                                                            <label className={errors.nominee_address_state && touched.nominee_address_state ? "error" : "input-title"}>
                                                              State <span className="error-area">*</span>
                                                            </label>
                                                            <Field
                                                              as="input"
                                                              className={errors.nominee_address_state && touched.nominee_address_state ? "input-error" : ""}
                                                              type="text"
                                                              placeholder="Enter State"
                                                              name="nominee_address_state"
                                                              value={state3}
                                                              onChange={(e: any) => {
                                                                handleChange(e);
                                                                handleNomineeDetails(3, "nominee_address_state", e.target.value, false);
                                                              }}
                                                              onBlur={(e: any) => {
                                                                handleBlur(e);
                                                                handleNomineeDetails(3, "nominee_address_state", e.target.value, false);
                                                              }}
                                                            />
                                                            {errors.nominee_address_state && touched.nominee_address_state && <span className="error">{errors.nominee_address_state}</span>}
                                                          </div>
                                                        </div>
                                                        <div className="col-xl-6 pl-0 float-left">
                                                          <div className="email-input input_field pl-0">
                                                            <label className={errors.nominee_address_city && touched.nominee_address_city ? "error" : "input-title"}>
                                                              City <span className="error-area">*</span>
                                                            </label>
                                                            <Field
                                                              as="input"
                                                              value={cityValue3}
                                                              className={errors.nominee_address_city && touched.nominee_address_city ? "input-error" : ""}
                                                              type="text"
                                                              placeholder="Enter City"
                                                              name="nominee_address_city"
                                                              onChange={(e: any) => {
                                                                handleChange(e);
                                                                handleNomineeDetails(3, "nominee_address_city", e.target.value, false);
                                                              }}
                                                              onBlur={(e: any) => {
                                                                handleBlur(e);
                                                                handleNomineeDetails(3, "nominee_address_city", e.target.value, false);
                                                              }}
                                                            />
                                                            {errors.nominee_address_city && touched.nominee_address_city && <span className="error">{errors.nominee_address_city}</span>}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Fragment>
                                                  )}
                                                  <div className="col-xl-4 p-0 float-left"></div>
                                                </form>
                                              )}
                                            </Formik>
                                          </div>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                    </Accordion>
                                  </div>
                                  <div className="col-xl-12 p-0 float-left text-center mt-100">
                                    <button
                                      type="submit"
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        addNomineeDetails();
                                      }}
                                      className="theme_btn primary_btn_one sub-btn wow w-auto"
                                      data-wow-delay="0.5s"
                                    >
                                      Continue
                                    </button>
                                    <div
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        // setKycExit(true);
                                      }}
                                      className="save_continue_btn text-center cursor mt-3"
                                    >
                                      <u>Save & Complete Later</u>
                                    </div>
                                  </div>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <div className="col-xl-12 p-0 float-left text-center mt-100">
                                    <a
                                      className="theme_btn primary_btn_one sub-btn wow "
                                      data-wow-delay="0.5s"
                                      onClick={(e: any) => {
                                        updateUserDetails();
                                        // if (AccountRedirect == 'true') {
                                        //     // navigate(PageLinks.MY_ACCOUNT_PROFILE);
                                        //     localStorage.setItem('nominee', '');
                                        // } else {
                                        //     navigate(PageLinks.DOWNLOAD_APPLICATION);
                                        // }
                                      }}
                                    >
                                      Continue
                                    </a>
                                    <div
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        // setKycExit(true);
                                      }}
                                      className="save_continue_btn text-center cursor mt-3"
                                    >
                                      <u>Save & Complete Later</u>
                                    </div>
                                  </div>
                                </Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Profile-details-edit-area end */}
      </main>
    </div>
  );
};

export default OpenDematAccount;
