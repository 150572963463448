import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";

const PanVerificationDeactivate: React.FC = () => {
    const location = useLocation();
    const prevData: any = location.state;
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const { setShowPreloader } = useAppStateAPI();

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: APIData.userDetail) => {
            setUserDetails(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }, []);

    return (
        <div className="main-index-wrapper">
            <main>
                <section className="otp_popup">
                    <div className="blur_back"></div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg pt-150 pb-100 pr-50 pl-50 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 col-md-6  text-left" data-wow-delay="0.3s">
                                    <div className="do-box do-box-2 mb-30 email_verify_bg text-center">
                                        <img src="assets/animations/icon_animations/user_error.gif" className="user_icon" />
                                        <h6 className="mb--10  email_verify_head" data-wow-delay="0.1s">Hi, {userDetail?.personal_data_pan_fullname}</h6>
                                        <form className="email_verify_form quote-form mb-20" action="#">
                                            <p className="pan-status-text-p">
                                                Your PAN is
                                            </p>
                                            {prevData.name == 'delete' &&
                                                <p className="pan-status-text">INVALID</p>
                                            }
                                            {prevData.name == 'deactivated' &&
                                                <p className="pan-status-text">DEACTIVATED</p>
                                            }
                                            {prevData.name == 'inoperative' &&
                                                <p className="pan-status-text">INOPERATIVE</p>
                                            }
                                            {prevData.name == 'deactivated' ?
                                                <p className="pan-status-text-p">Please provide an active PAN Number</p>
                                                :
                                                <p className="pan-status-text-p">Please provide a Valid PAN Number</p>
                                            }
                                            <Link to={PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VALIDATE_PAN} className="theme_btn email_verify_btn sub-btn " data-wow-delay="0.5s">
                                                {prevData.name == 'deactivated' &&
                                                    "Please provide an Active PAN Number"
                                                }
                                                {prevData.name == 'delete' &&
                                                    "Re-enter PAN Number"
                                                }
                                            </Link>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default PanVerificationDeactivate;
