import { useEffect, useState } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Preloader from "./components/template/Preloader";
import PersonalDetailsEdit from "./pages/PersonalDetailsEdit";
import PersonalDetailsEditValidatePan from "./pages/PersonalDetailsEditValidatePan";
import PanVerification from "./pages/PanVerification";
import AccountOpeningpennyDrop from "./pages/AccountOpeningpennyDrop";
import VerifyYourIdentity from "./pages/VerifyYourIdentity";
import VerifyYourIdentityPhotoSuccess from "./pages/VerifyYourIdentityPhotoSuccess";
import BankDetails from "./pages/BankDetails";
import BankDetailsAddedSuccess from "./pages/BankDetailsAddedSuccess";
import DematDetails from "./pages/DematDetails";
import DematDetailsAddedSuccess from "./pages/DematDetailsAddedSuccess";
import KYCSuccess from "./pages/KYCSuccess";
import AccountOpeningpennyDropPopup from "./pages/AccountOpeningpennyDropPopup";
import AreYouSureAlertPopup from "./pages/AreYouSureAlertPopup";
import DematSignaturePreview from "./pages/DematSignaturePreview";
import SignatureSuccess from "./pages/SignatureSuccess";
import AddNominee from "./pages/AddNominee";
import EsignAadhaar from "./pages/EsignAadhaar";
import FormSuccess from "./pages/FormSuccess";
import AuthenticateAadhaar from "./pages/AuthenticateAadhaar";
import AuthenticateAadhaarDetail from "./pages/AuthenticateAadhaarDetail";
import PanNotMatchPopup from "./pages/PanNotMatchPopup";
import UploadCancelledCheque from "./pages/UploadCancelledCheque";
import CancelledChequePreview from "./pages/CancelledChequePreview";
import PanStatusPopup from "./pages/PanStatusPopup";
import DijiSuccessPopup from "./pages/DijiSuccessPopup";
import PanVerificationDeactivate from "./pages/PanVerificationDeactivate";
import EsignSuccessPopup from "./pages/EsignSuccessPopup";
import InstructionScreen from "./pages/Instruction_Screen";
import VerifyYourEmailSuccess from "./pages/VerifyYourEmailSuccess";
import ScrollToTop from "./components/template/ScrollToTop";
import ApplicationDownload from "./pages/ApplicationDownload";
import { ServerAPI } from "./common/ServerAPI";
import { PageLinks } from "./common/Constants";
import { isExpired, decodeToken } from "react-jwt";
import EsignCancelPopup from "./pages/EsignCancelPopup";
import VerifyAddNominee from "./pages/VerifyAddNominee";
import VerifyDematDetails from "./pages/VerifyDematDetails";


const AppNavigate: React.FC = () => {
    const [sessionValid, setSessionValid] = useState(false);
    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    };

    const RefreshLoginToken = () => {
        let login = localStorage.getItem('login');
        if (login != null && login != undefined && login != '0') {
            let token = localStorage.getItem('token');
            if (token != null) {
                const myDecodedToken = decodeToken(token);
                console.log(myDecodedToken);
                let tokendetail: any = myDecodedToken;
                if (tokendetail.exp * 1000 < Date.now()) {
                    ServerAPI.RefreshToken().then((response: any) => {
                        if (response == true) {
                            setSessionValid(true);
                        }else{
                            setSessionValid(false);
                        }
                    });
                } else {
                    setSessionValid(true);
                }
            }
        }else{
            setSessionValid(true);
        }
    }
    useEffect(() => {
        const win: Window = window;
        // if (!window.location.host.startsWith("www")) {
        //     win.location = window.location.protocol + "//" + "www." + window.location.host + window.location.pathname;
        // }

        clearCacheData();
    }, [])

    useEffect(() => {
        if (sessionValid == false) {
            RefreshLoginToken();
        }
    }, [sessionValid])


    return (
        <Router>
            <ScrollToTop />
            <Preloader />
            {sessionValid ?
                <Routes>
                    <Route path={PageLinks.PROFILE_PERSONAL_DETAILS_EDIT} element={<PersonalDetailsEdit />} />
                    <Route path={PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VALIDATE_PAN} element={<PersonalDetailsEditValidatePan />} />
                    <Route path={PageLinks.PAN_VERIFICATION} element={<PanVerification />} />
                    <Route path={PageLinks.ACCOUNT_OPENING_PENNY_DROP} element={<AccountOpeningpennyDrop />} />
                    <Route path={PageLinks.VERIFY_YOUR_IDENTITY} element={<VerifyYourIdentity />} />
                    <Route path={PageLinks.VERIFY_YOUR_IDENTITY_SUCCESS} element={<VerifyYourIdentityPhotoSuccess />} />
                    <Route path={PageLinks.VERIFY_ADD_NOMINEE_SUCCESS} element={<VerifyAddNominee />} />
                    <Route path={PageLinks.VERIFY_DEMAT_DETAILS_SUCCESS} element={<VerifyDematDetails />} />
                    <Route path={PageLinks.BANK_DETAILS} element={<BankDetails />} />
                    <Route path={PageLinks.BANK_DETAILS_ADDED_SUCCESS} element={<BankDetailsAddedSuccess />} />
                    <Route path={PageLinks.DEMAT_DETAILS} element={<DematDetails />} />
                    <Route path={PageLinks.DEMAT_DETAILS_ADDED_SUCCESS} element={<DematDetailsAddedSuccess />} />
                    <Route path={PageLinks.KYC_SUCCESS} element={<KYCSuccess />} />
                    <Route path={PageLinks.ACCOUNT_OPENING_PENNY_DROP_POPUP} element={<AccountOpeningpennyDropPopup />} />
                    <Route path={PageLinks.ARE_YOU_SURE_ALERT} element={<AreYouSureAlertPopup />} />
                    <Route path={PageLinks.DEMAT_SIGNATURE_PREVIEW} element={<DematSignaturePreview />} />
                    <Route path={PageLinks.SIGNATURE_SUCCESS} element={<SignatureSuccess />} />
                    <Route path={PageLinks.ADD_NOMINEE} element={<AddNominee />} />
                    <Route path={PageLinks.ESIGN_AADHAAR} element={<EsignAadhaar />} />
                    <Route path={PageLinks.FORM_SUCCESS} element={<FormSuccess />} />
                    <Route path={PageLinks.AUTHENTICATE_AADHAAR} element={<AuthenticateAadhaar />} />
                    <Route path={PageLinks.AUTHENTICATE_AADHAAR_DETAIL} element={<AuthenticateAadhaarDetail />} />
                    <Route path={PageLinks.PAN_NOT_MATCH_POPUP} element={<PanNotMatchPopup />} />
                    <Route path={PageLinks.UPLOAD_CANCELLED_CHEQUE} element={<UploadCancelledCheque />} />
                    <Route path={PageLinks.CANCELLED_CHEQUE_PREVIEW} element={<CancelledChequePreview />} />
                    <Route path={PageLinks.PAN_STATUS_POPUP} element={<PanStatusPopup />} />
                    <Route path={PageLinks.DIJI_SUCCESS_POPUP} element={<DijiSuccessPopup />} />
                    <Route path={PageLinks.PAN_INVALID} element={<PanVerificationDeactivate />} />
                    <Route path={PageLinks.ESIGN_SUCCESS_POPUP} element={<EsignSuccessPopup />} />
                    <Route path={PageLinks.ESIGN_CANCEL_POPUP} element={<EsignCancelPopup />} />
                    <Route path={PageLinks.INSTRUCTION_SCREEN} element={<InstructionScreen />} />
                    <Route path={PageLinks.EMAIL_VERIFED_SUCCESS} element={<VerifyYourEmailSuccess />} />
                    <Route path={PageLinks.DOWNLOAD_APPLICATION} element={<ApplicationDownload />} />



                    <Route path="/" element={<Navigate to={PageLinks.INSTRUCTION_SCREEN} />} />
                </Routes>
                :
                <div />
            }
        </Router>
    )
}

export default AppNavigate;
