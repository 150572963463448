import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import { API, Endpoints, PageLinks } from "./Constants";
import { APIData } from "./DataTypes";
import { getAuthString } from "./Utilities";

export namespace ServerAPI {
  export enum APIMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
  }

  export const mobileVerify = async (values: string) => {
    let data: { [k: string]: any } = {};
    data["mobile_number"] = values;
    data["resend_otp"] = false;
    return executeAPI(API.EndPoint.mobile_otp_send, APIMethod.POST, false, data);
  };

  export const userDetailData = async () => {
    return executeAPI(API.EndPoint.userDetails, APIMethod.GET, true);
  };
  export const bankDetailData = async (ifsc_code: string, account_number: string, account_type_1: string, account_type_2: string, cheque_image: string) => {
    let data: { [k: string]: any } = {};
    data["bank_ifsc"] = ifsc_code;
    data["bank_account_number"] = account_number;
    data["bank_account_type_1"] = parseInt(account_type_1);
    data["bank_account_type_2"] = parseInt(account_type_2);
    data["bank_cancel_cheque_image"] = cheque_image;

    return executeAPI(API.EndPoint.bank_details, APIMethod.POST, true, data);
  };

  export const EmailSendOtp = async (email_id: any) => {
    let data: { [k: string]: any } = {};
    data["email_id"] = email_id;
    data["resend_otp"] = false;
    return executeAPI(API.EndPoint.email_send_otp, APIMethod.POST, true, data);
  };
  export const ResendEmailOtp = async (values: any) => {
    let data: { [k: string]: any } = {};
    data["mobile_number"] = values["mobile_number"];
    data["email_id"] = values["email_id"];
    data["resend_otp"] = true;
    return executeAPI(API.EndPoint.email_resend_otp, APIMethod.POST, true, data);
  };

  export const EmailVerify = async (email: string, otp: any) => {
    let data: { [k: string]: any } = {};
    data["email_id"] = email;
    data["otp"] = otp;
    // data["resend_otp"] = true;
    return executeAPI(API.EndPoint.email_otp_verify, APIMethod.POST, true, data);
  };
  export const MobileOTPVerify = async (otp: any) => {
    let data: { [k: string]: any } = {};
    data["mobile_number"] = localStorage.getItem("mobile");
    data["otp"] = otp;
    data["hash_key"] = localStorage.getItem("hashkey");
    return executeAPI(API.EndPoint.mobile_otp_verify, APIMethod.POST, false, data);
  };

  export const RazorPaybankDetailData = async (ifsc_code: string, account_number: string, account_type_1: string, account_type_2: string) => {
    let data: { [k: string]: any } = {};
    data["bank_ifsc_code"] = ifsc_code;
    data["bank_account_number"] = account_number;
    data["account_type_1"] = parseInt(account_type_1);
    data["account_type_2"] = parseInt(account_type_2);
    return executeAPI(API.EndPoint.razorpay_bank_details, APIMethod.POST, true, data, null, false, true);
  };
  export const exisitingDematAccount = async (values: APIData.DematAccount) => {
    let data: { [k: string]: any } = {};
    data["auth_fund_securities"] = values.auth_fund_securities;
    data["auth_receive_info_stock_broker"] = values.auth_receive_info_stock_broker;
    data["auth_transfer_fund_securities"] = values.auth_transfer_fund_securities;
    data["auth_accept_collateral_segments"] = values.auth_accept_collateral_segments;
    data["dp_id"] = values.dp_id;
    data["depository_id"] = values.depository_id;
    data["brokerage_scheme_id"] = values.brokerage_scheme_id;
    data["various_segment"] = values.various_segment;
    return executeAPI(API.EndPoint.exisiting_demat_account, APIMethod.POST, true, data);
  };
  export const Verify_code = async () => {
    return executeAPI(API.EndPoint.verification_code, APIMethod.GET, true);
  };

  export const AuthenticateAadhar = async (platform: any) => {
    let params: { [k: string]: any } = {};
    params["platform"] = platform;
    // return executeAPI(API.EndPoint.select_city,APIMethod.GET, true,null,params)
    return executeAPI(API.EndPoint.authenticate_aadhar, APIMethod.GET, true, null, params);
  };

  export const AadharDetails = async () => {
    return executeAPI(API.EndPoint.aadhar_details, APIMethod.GET, true);
  };

  export const AddressProof = async () => {
    return executeAPI(API.EndPoint.address_proof, APIMethod.GET, true);
  };

  export const City = async (state_id: number) => {
    let params: { [k: string]: any } = {};
    params["state_id"] = Number(state_id);
    return executeAPI(API.EndPoint.select_city, APIMethod.GET, true, null, params);
  };

  export const State = async () => {
    return executeAPI(API.EndPoint.select_state, APIMethod.GET, true);
  };

  export const BankDetails = async () => {
    return executeAPI(API.EndPoint.bank_details, APIMethod.GET, true);
  };

  export const BankStatus = async (id: number, status: number) => {
    let data: { [k: string]: any } = {};
    data["bank_id"] = id;
    data["status"] = status;
    return executeAPI(API.EndPoint.bank_details_status, APIMethod.PUT, true, data);
  };

  export const ExisitingDematAccountList = async () => {
    return executeAPI(API.EndPoint.exisiting_demat_account, APIMethod.GET, true);
  };

  export const NewDematAccountList = async () => {
    return executeAPI(API.EndPoint.new_demat_account_details, APIMethod.GET, true);
  };

  export const upload_vidio = async (file: any) => {
    let data: { [k: string]: any } = {};
    data["ipv_video"] = file;
    const config: any = { headers: { "Content-Type": "multipart/form-data", Authorization: localStorage.getItem("token") } };
    let fd = new FormData();
    fd.append("ipv_video", file);

    try {
      const response = await axios.put(API.BaseUrl + API.EndPoint.verification_code, fd, config);
      return response.data;
    } catch (error: any) {
      return { success: false, message: "Error Occurred in the Request!" };
    }
    // return executeAPI(API.EndPoint.upload_image,APIMethod.POST,true)
  };

  export const add_profile_details = async (details: APIData.userDetail) => {
    console.log("🚀 ~ file: ServerAPI.tsx:151 ~ constadd_profile_details= ~ details:", details)
    console.log(details);
    let data: { [k: string]: any } = {};
    if (details.personal_data_address_proof_number != undefined && details.personal_data_address_proof_number != "") {
      data["personal_data_address_proof_number"] = details.personal_data_address_proof_number;
    }
    if (details.address_proof_id != 0) {
      data["address_proof_id"] = details.address_proof_id;
    }
    if (details.address_proof_verified_remarks_id != 0) {
      data["address_proof_verified_remarks_id"] = details.address_proof_verified_remarks_id;
    }
    if (details.annual_income_id != 0) {
      data["annual_income_id"] = details.annual_income_id;
    }
    if (details.gender_id != 0) {
      data["gender_id"] = details.gender_id;
    }
    if (details.marital_status_id != 0) {
      data["marital_status_id"] = details.marital_status_id;
    }
    if (details.occupation_id != 0) {
      data["occupation_id"] = details.occupation_id;
    }
    data["personal_data_address_as_aadhar"] = details.personal_data_address_as_aadhar;
    if (details.personal_data_address_city != "") {
      data["personal_data_address_city"] = details.personal_data_address_city;
    }
    if (details.personal_data_address_line_1 != "") {
      data["personal_data_address_line_1"] = details.personal_data_address_line_1;
    }
    if (details.personal_data_address_line_2 != "") {
      data["personal_data_address_line_2"] = details.personal_data_address_line_2;
    }
    if (details.personal_data_address_pincode != "") {
      data["personal_data_address_pincode"] = details.personal_data_address_pincode;
    }
    if (details.personal_data_address_proof_back_image != "") {
      data["personal_data_address_proof_back_image"] = details.personal_data_address_proof_back_image;
    }
    if (details.personal_data_address_proof_front_image != "") {
      data["personal_data_address_proof_front_image"] = details.personal_data_address_proof_front_image;
    }
    if (details.personal_data_address_state != "") {
      data["personal_data_address_state"] = details.personal_data_address_state;
    }
    if (details.personal_data_dob != "") {
      data["personal_data_dob"] = details.personal_data_dob;
    }
    if (details.personal_data_father_or_husband_name != "") {
      data["personal_data_father_or_husband_name"] = details.personal_data_father_or_husband_name;
    }
    if (details.personal_data_firstname != "") {
      data["personal_data_firstname"] = details.personal_data_firstname;
    }
    if (details.personal_data_lastname != "") {
      data["personal_data_lastname"] = details.personal_data_lastname;
    }
    if (details.personal_data_mothers_maiden_name != "") {
      data["personal_data_mothers_maiden_name"] = details.personal_data_mothers_maiden_name;
    }
    data["personal_data_nominee_added"] = details.personal_data_nominee_added;
    if (details.trading_experience_id != 0) {
      data["trading_experience_id"] = details.trading_experience_id;
    }

    return executeAPI(API.EndPoint.userDetails, APIMethod.PUT, true, data);
  };
  export const userConcent = async (details: APIData.userDetail) => {
    let data: { [k: string]: any } = {};
    data["user_auth_interfychange"] = details.user_auth_interfychange;
    data["user_e_confirmation"] = details.user_e_confirmation;
    data["user_auth_fund_securities"] = details.user_auth_fund_securities;
    data["user_citizen_usa"] = details.user_citizen_usa;
    data["user_auth_fetch_kyc_details"] = details.user_auth_fetch_kyc_details;
    data["user_auth_use_aadhar"] = details.user_auth_use_aadhar;
    data["user_tax_resident"] = details.user_tax_resident;
    data["user_itr_records"] = details.user_itr_records;

    return executeAPI(API.EndPoint.user_consent, APIMethod.PUT, true, data);
  };

  export const Depository = async () => {
    return executeAPI(API.EndPoint.depository, APIMethod.GET, true);
  };
  export const Relationship = async () => {
    return executeAPI(API.EndPoint.relationship_with_application, APIMethod.GET, true);
  };

  export const NomineeProof = async () => {
    return executeAPI(API.EndPoint.nominee_proof_dropdown, APIMethod.GET, true);
  };

  export const ProfessionDropDown = async () => {
    return executeAPI(API.EndPoint.profession_dropdown, APIMethod.GET, true);
  };

  export const proofImageUpload = async (proof_type: number, front_image?: any, back_image?: any) => {
    const config = { headers: { "Content-Type": "multipart/form-data", Authorization: localStorage.getItem("token")! } };
    let image_position = 1;
    let fd = new FormData();
    if (front_image) {
      fd.append("image", front_image);
      image_position = 1;
    }
    if (back_image) {
      fd.append("image", back_image);
      image_position = 2;
    }
    try {
      const response = await axios.post(API.BaseUrl + API.EndPoint.proof_image_upload + "?address_proof_id=" + proof_type + "&image_position=" + image_position, fd, config);
      return response.data;
    } catch (error: any) {
      return { success: false, message: "Error Occurred in the Request!" };
    }
  };

  export const removeBankAccount = async (ID: number) => {
    let params: { [k: string]: any } = {};
    params["bank_id"] = ID;
    return executeAPI(API.EndPoint.bank_details, APIMethod.DELETE, true, null, params);
  };
  export const removeNewDematAccount = async (ID: number) => {
    let params: { [k: string]: any } = {};
    params["new_demat_id"] = ID;
    return executeAPI(API.EndPoint.new_demat_account_details, APIMethod.DELETE, true, null, params);
  };

  export const removeExisitingDematAccount = async (ID: number) => {
    let params: { [k: string]: any } = {};
    params["exist_demat_id"] = ID;
    return executeAPI(API.EndPoint.exisiting_demat_account, APIMethod.DELETE, true, null, params);
  };

  export const UploadCancelledCheque = async (file: any) => {
    const config = { headers: { "Content-Type": "multipart/form-data", Authorization: localStorage.getItem("token")! } };
    let fd = new FormData();
    fd.append("cancel_cheque_image", file);

    try {
      const response = await axios.post(API.BaseUrl + API.EndPoint.cheque_cancelled_image, fd, config);
      return response.data;
    } catch (error: any) {
      return { success: false, message: "Error Occurred in the Request!" };
    }
  };

  export const PanVerify = async (pan_no: string) => {
    let params: { [k: string]: any } = {};
    params["pan_no"] = pan_no;
    return executeAPI(API.EndPoint.pan_verify, APIMethod.GET, true, null, params);
  };
  export const IfscCode = async (ifsc: string) => {
    let params: { [k: string]: any } = {};
    params["bank_ifsc"] = ifsc;
    return executeAPI(API.EndPoint.ifsc_code, APIMethod.GET, true, null, params);
  };
  export const WealthSource = async () => {
    return executeAPI(API.EndPoint.primary_source_of_wealth, APIMethod.GET, true);
  };
  export const BornCity = async () => {
    return executeAPI(API.EndPoint.where_you_born, APIMethod.GET, true);
  };

  export const newDematAdd = async (values: APIData.NewDematAccount) => {
    let data: { [k: string]: any } = {};
    data["dp_resident_india"] = values.dp_resident_india;
    data["dp_resident_usa"] = values.dp_resident_usa;
    data["check_box_terms_selected"] = values.check_box_terms_selected;
    data["check_box_account_statement_electronic"] = values.check_box_account_statement_electronic;
    data["demat_signature_image"] = values.demat_signature_image;
    data["born_place"] = values.born_place;
    data["primary_source"] = values.primary_source;
    return executeAPI(API.EndPoint.new_demat_account_details, APIMethod.POST, true, data);
  };
  export const eSign = async (platform: any) => {
    let params: { [k: string]: any } = {};
    params["platform"] = platform;
    return executeAPI(API.EndPoint.esign_pdf, APIMethod.GET, true, null, params);
  };

  export const AddNominee = async (values: APIData.NomineeData[]) => {
    // values.map((data:any)=>{
    //     if(data.nominee_guardian_records && !Array.isArray(data.nominee_guardian_records)){
    //         data.nominee_guardian_records = [data.nominee_guardian_records];
    console.log("🚀 ~ file: ServerAPI.tsx:343 ~ //values.map ~ data.nominee_guardian_records:", values);
    //     }
    // })
    return executeAPI(API.EndPoint.nominee_details, APIMethod.POST, true, values);
  };

  export const pincodeSearch = async (pincode: any) => {
    let params: { [k: string]: any } = {};
    params["pincode"] = pincode;
    return executeAPI(API.EndPoint.pincode, APIMethod.GET, true, null, params);
  };

  export const UploadSignature = async (file: any) => {
    const config = { headers: { "Content-Type": "multipart/form-data", Authorization: localStorage.getItem("token")! } };
    let fd = new FormData();
    fd.append("image", file);

    try {
      const response = await axios.post(API.BaseUrl + API.EndPoint.signature_image_upload, fd, config);
      return response.data;
    } catch (error: any) {
      return { success: false, message: "Error Occurred in the Request!" };
    }
  };
  export const NomineeDetails = async () => {
    return executeAPI(API.EndPoint.nominee_details, APIMethod.GET, true, null);
  };

  export const ProgressBarStatusGet = async () => {
    return executeAPI(API.EndPoint.kyc_progressbar_status, APIMethod.GET, true, null);
  };

  export const ProgressBarStatus = async (status: number) => {
    let data: { [k: string]: any } = {};
    data["kyc_progressbar_status_id"] = status;
    return executeAPI(API.EndPoint.kyc_progressbar_status, APIMethod.PUT, true, data);
  };

  export const GetNomineeDetails = async (id: number) => {
    let params: { [k: string]: any } = {};
    params["nominee_details_id"] = id;
    return executeAPI(API.EndPoint.nominee_details_specific, APIMethod.GET, true, null, params);
  };

  export const EsignDownload = async () => {
    return executeAPI(API.EndPoint.e_sign_pdf_download, APIMethod.GET, true, null);
  };

  export const deleteNomineeDetails = async (ID: number) => {
    let params: { [k: string]: any } = {};
    params["nominee_details_id"] = ID;
    return executeAPI(API.EndPoint.nominee_details, APIMethod.DELETE, true, null, params);
  };

  export const GetDocumentsCollected = async () => {
    return executeAPI(API.EndPoint.documents_collected, APIMethod.GET, true);
  };

  // export const UpdateNomineeDetails = async (id: number, values: APIData.NomineeData) => {
  //     let data: { [k: string]: any } = {};
  //     let params: { [k: string]: any } = {};

  //     data["nominee_details_title"] = values.nominee_details_title;
  //     data["nominee_details_relationship"] = values.nominee_details_relationship;
  //     data["nominee_details_mobile_number"] = values.nominee_details_mobile_number;
  //     data["nominee_details_identification"] = values.nominee_details_identification;
  //     data["nominee_details_fname"] = values.nominee_details_fname;
  //     data["nominee_details_address_zip"] = values.nominee_details_address_zip;
  //     data["nominee_details_address_state"] = values.nominee_details_address_state;
  //     data["nominee_details_address_line_2"] = values.nominee_details_address_line_2;
  //     data["nominee_details_address_line_1"] = values.nominee_details_address_line_1;
  //     data["nominee_details_address_city"] = values.nominee_details_address_city;
  //     data["nominee_details_address_zip"] = values.nominee_details_address_zip;
  //     data["nominee_details_identification_number"] = values.nominee_details_identification_number;
  //     data["nominee_details_dob"] = values.nominee_details_dob;
  //     params["nominee_details_id"] = id;
  //     return executeAPI(API.EndPoint.nominee_details, APIMethod.PUT, true, data, params);
  // }

  export const RefreshToken = async () => {
    const response = await executeAPI(API.EndPoint.token_refresh, APIMethod.POST, true);
    localStorage.setItem("token", response["token"]);
    localStorage.setItem("refresh_token", response["refresh_token"]);
    return true;
  };

  export const Gender = async () => {
    return executeAPI(API.EndPoint.gender, APIMethod.GET, true);
  };

  export const MaritalStatus = async () => {
    return executeAPI(API.EndPoint.marital_status, APIMethod.GET, true);
  };

  export const AnnualIncome = async () => {
    return executeAPI(API.EndPoint.annual_income, APIMethod.GET, true);
  };

  export const TradingExperience = async () => {
    return executeAPI(API.EndPoint.trading_experience, APIMethod.GET, true);
  };

  export const BrokerageScheme = async () => {
    return executeAPI(API.EndPoint.brokerage_scheme, APIMethod.GET, true);
  };
  export const Segments = async () => {
    return executeAPI(API.EndPoint.various_segment, APIMethod.GET, true);
  };

  export const Titles = async () => {
    return executeAPI(API.EndPoint.name_title, APIMethod.GET, true);
  };

  export const SearchForIFSC = async (bank_name_collection_id?: number, bank_state_collection_id?: number, bank_city_collection_id?: number, bank_branch_collection_id?: number) => {
    let params: { [k: string]: any } = {};
    params["bank_name_collection_id"] = bank_name_collection_id;
    params["bank_state_collection_id"] = bank_state_collection_id;
    params["bank_city_collection_id"] = bank_city_collection_id;
    params["bank_branch_collection_id"] = bank_branch_collection_id;
    return executeAPI(API.EndPoint.search_ifsc, APIMethod.GET, true, null, params);
  };

  export const executeAPI = async (endpoint: string, method: APIMethod, authRequired?: boolean, data?: any, params?: any, suppressError?: boolean, returnError?: boolean) => {
    let headers: { [k: string]: any } = {};
    const user_date_time = new Date();
    // headers["UserDateTime"] = moment(user_date_time).format("DD-MM-YYYY hh:mm");
    headers["UserDateTime"] = moment(user_date_time).format();
    console.log(headers);
    if (endpoint == Endpoints.token_refresh) {
      var refreshToken;
      console.log("Token Login");
      refreshToken = localStorage.getItem("refresh_token");
      headers["Authorization"] = refreshToken;
    } else {
      if (authRequired) {
        // headers["Authorization"] = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3NjI2NjI4OCwianRpIjoiYTBjOGJjZTMtZGMzZi00YzQzLWE1MTAtMDY3Zjk1MWVlNDA1IiwidHlwZSI6ImFjY2VzcyIsInN1YiI6NSwibmJmIjoxNjc2MjY2Mjg4LCJleHAiOjE2NzY4NzEwODgsInVzZXJfaWQiOjUsInVzZXJfdHlwZSI6MiwiZW52IjoiZGV2ZWxvcG1lbnQifQ.guEiFKvqcWSUiDypZ3y5lu9APM-Kuc-SN3NjYs1U26M';
        const persistAuthString = await getAuthString();
        if (persistAuthString) {
          headers["Authorization"] = persistAuthString;
          // headers["Authorization"] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3NzEzNTY0MywianRpIjoiODM0NTIwYzgtMjJhOC00MDVmLTg3ODQtYzc0Mjc4ZDljZDc2IiwidHlwZSI6ImFjY2VzcyIsInN1YiI6MTg1LCJuYmYiOjE2NzcxMzU2NDMsImV4cCI6MTY3Nzc0MDQ0MywidXNlcl9pZCI6MTg1LCJ1c2VyX3R5cGUiOjIsImVudiI6ImRldmVsb3BtZW50In0.xr0UxuoPBg-Na0J-gregGbrm6oeK5XON8rTHpqJfl6I";
        }
      }
    }
    if (method === APIMethod.POST || method === APIMethod.PUT) {
      headers["Content-Type"] = "application/json";
    }

    try {
      const response = await axios({
        method: method,
        url: API.BaseUrl + endpoint,
        data: JSON.stringify(data),
        headers: headers,
        params: params,
      });
      const details = { data: response.data, status: response.status };
      return details;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && !suppressError && !returnError) {
        console.log("🚀 ~ file: ServerAPI.tsx:508 ~ response:", error.response);
        const errorData: any = error.response.data;
        if (errorData.errors && errorData.errors.length > 0) {
          if (errorData.errors) {
            toast.error(errorData.errors);
          } else {
            toast.error(errorData.errors);
          }
        } else {
          if (errorData["message"]) {
            toast.error(errorData["message"]);
          } else {
            console.log("🚀 ~ file: ServerAPI.tsx:508 ~ response:", error.response);
            toast.error("Error Occured in the Request");
          }
        }
      }
      if (axios.isAxiosError(error) && error.response && returnError) {
        const errorData: any = error.response.data;
        if (errorData.errors && errorData.errors.length > 0) {
          if (errorData.errors) {
            return errorData.errors;
          }
        } else {
          const details = { data: error.response.data, status: error.response.status };

          return details;
        }
      }
    }
  };
}
