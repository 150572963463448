import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { kycStatus, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";

const VerifyDematDetails: React.FC = () => {

    const location = useLocation();
    const preData: any = location.state;
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: any) => {
            setUserDetails(res)
        }).finally(() => {
            setShowPreloader(false);
        })

    }, []);

    const Pagenavigate = () => {
        navigate(PageLinks.ADD_NOMINEE);
    }

    setTimeout(Pagenavigate, 3000);

    return (
        <section className="subscribe-letter-area animation-div">
            <div className="line-shape d-none d-lg-inline-block success-popup-bg-img">
                <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
            </div>
            <div className="container custom-container-subs">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6  text-left mt-200" data-wow-delay="0.3s">
                        <div className="do-box do-box-pan-status mb-30 email_verify_bg text-center cursor" onClick={(e: any) => {
                            e.preventDefault();
                            navigate(PageLinks.ADD_NOMINEE);
                        }}>
                            <img src="assets/animations/icon_animations/user.gif" className="user_icon" />
                            <p className="success-popup-text" data-wow-delay="0.1s">Congratulations!</p>
                            <p className="success-popup-text" data-wow-delay="0.1s">{userDetail?.personal_data_pan_fullname}&nbsp;Congratulations! Jairaj Demat Account Added Successfully</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VerifyDematDetails;