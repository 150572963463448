export enum PageLinks {
    PROFILE_PERSONAL_DETAILS_EDIT = "/profile-personal-details-edit",
    PROFILE_PERSONAL_DETAILS_EDIT_VERIFY_EMAIL = "/profile-personal-details-edit-verify-email",
    EMAIL_VERIFICATION = "/email-verification",
    EMAIL_VERIFICATION_OTP = "/email-verification-otp",
    MOBILE_VERIFICATION_OTP = "/mobile-verification-otp",
    PROFILE_PERSONAL_DETAILS_EDIT_EMAIL_VERIFIED = "/profile-personal-details-edit-email-verified",
    PROFILE_PERSONAL_DETAILS_EDIT_VALIDATE_PAN = "/profile-personal-details-edit-validate-pan",
    PAN_VERIFICATION = "/pan-verification",
    PROFILE_PERSONAL_DETAILS_EDIT_PAN_DATA = "/profile-personal-details-edit-pan-data",
    ACCOUNT_OPENING_PENNY_DROP = "/verify-your-details",
    VERIFY_YOUR_IDENTITY = "/verify-your-indentity",
    VERIFY_YOUR_IDENTITY_PHOTO = "/verify-your-indentity-photo",
    VERIFY_YOUR_IDENTITY_SUCCESS = "/verify-your-indentity-success",
    VERIFY_ADD_NOMINEE_SUCCESS = "/verify-add-nominee-success",
    VERIFY_DEMAT_DETAILS_SUCCESS = "/verify-demat-details-success",
    BANK_DETAILS = "/bank-details",
    BANK_DETAILS_ADDED_SUCCESS = "/bank-details-added-success",
    DEMAT_DETAILS = "/demat-details",
    DEMAT_DETAILS_ADDED_SUCCESS = "/demat-details-added-success",
    KYC_SUCCESS = "/kyc-success",
    ACCOUNT_OPENING_PENNY_DROP_POPUP = "/account-opening-example",
    VERIFY_YOUR_IDENTITY_PHOTO_POPUP = "/verify-your-indentity-photo-popup",
    ARE_YOU_SURE_ALERT = "/are-you-sure-alert-popup",
    OPEN_DEMAT_ACCOUNT = "/open-demat-account",
    APPOLOGIES_POPUP = "/appoligies-popup",
    DEMAT_SIGNATURE_PREVIEW = "/demat-signature-preview",
    SIGNATURE_SUCCESS = "/signature-success",
    ADD_NOMINEE = "/add-nominee",
    ESIGN_AADHAAR = "/esign-aadhaar",
    FORM_SUCCESS = "/form-success",
    AUTHENTICATE_AADHAAR = "/authenticate-aadhaar",
    AUTHENTICATE_AADHAAR_DETAIL = "/authenticate-aadhaar-detail",
    UPLOAD_PASSPORT_SCANS = "/upload-passport-scans",
    PASSPORT_PREVIEW = "/passport-preview",
    PAN_NOT_MATCH_POPUP = "/pan-not-match-popup",
    UPLOAD_CANCELLED_CHEQUE = "/upload-cancelled-cheque",
    CANCELLED_CHEQUE_PREVIEW = "/cancelled-cheque-preview",
    PAN_STATUS_POPUP = "/pan-status-popup",
    DIJI_SUCCESS_POPUP = "/diji-success-popup",
    PAN_INVALID = "/pan-invalid",
    EMAIL_UPDATE = "/email-update",
    ESIGN_SUCCESS_POPUP = "/esign-success-popup",
    ESIGN_CANCEL_POPUP = "/esign-cancel-popup",
    INSTRUCTION_SCREEN = "/instruction-screen",
    EMAIL_VERIFED_SUCCESS = "/email-verfied-success",
    BOND_IPO_FLOW = "/bond-ipo-detail",
    BOND_IPO_PURCHASE = "/bond-ipo-purchase",
    DOWNLOAD_APPLICATION = "/download-application",
    REFUND_POLICY = "/refund-policy",
    ENQUIRY_ADDED_SUCCESS = "/enquiry-added-success",

}

export enum Endpoints {
    userDetails = "kyc/personal-records",
    email_verify = "kyc/send_otp_email",
    email_otp_verify = "kyc/email-otp-verify",
    bank_details = "kyc/bank-records",
    mobile_otp_send = "kyc/mobile-otp-send",
    login_resend_otp = "kyc/login_mobile_otp_send",
    mobile_otp_verify = "kyc/mobile-otp-verify",
    email_send_otp = "kyc/email-otp-send",
    email_resend_otp = "kyc/send_otp_email",
    exisiting_demat_account = "kyc/demat-account-records",
    verification_code = "kyc/ipv-verification",
    authenticate_aadhar = "kyc/authentication-digilocker",
    aadhar_details = "kyc/digilocker-details",
    address_proof = "master/address-proof",
    depository = "master/depository",
    proof_image_upload = "kyc/address-proof-upload",
    new_demat_account_details = "new_demat_account_details",
    razorpay_bank_details = "kyc/bank-records-razorpay",
    cheque_cancelled_image = "kyc/cancel-cheque-image",
    select_state = "master/state",
    select_city = "master/city",
    pan_verify = "kyc/pan-verify",
    profession_dropdown = "master/occupation",
    ifsc_code = "kyc/ifsc-detail",
    primary_source_of_wealth = "primary_source_of_wealth",
    where_you_born = "where_you_born",
    relationship_with_application = "master/relationship-applicant",
    nominee_proof_dropdown = "master/nominee-identification",
    nominee_details = "kyc/nominee-records",
    esign_pdf = "kyc/sign-application-form",
    pincode = "master/pincode",
    signature_image_upload = 'signature_image_upload',
    kyc_progressbar_status = 'kyc_progressbar_status',
    instruction_screen = 'instrction_screen',
    e_sign_pdf_download = 'kyc/application-form-download',
    nominee_details_specific = 'nominee_details_specific',
    documents_collected = "kyc/documents-collected",
    token_refresh = 'kyc/token_refresh',
    user_consent = 'kyc/user-consent',
    bank_details_status = "kyc/set-bank-status",
    gender = "master/gender",
    marital_status = "master/marital-status",
    annual_income = "master/annual-income",
    trading_experience = "master/trading-experience",
    brokerage_scheme = "master/brokerage-scheme",
    various_segment = "master/various-segment",
    name_title = "master/name-title",
    search_ifsc = "kyc/search-ifsc"

}

export const APIResult = {
    SUCCESS: true,
    FAILURE: false
}

export const Mail_mobile_verify = {
    Verified: 1,
    NotVerified: 0
}
export const Selected_gender = {
    Male: 1,
    Female: 2,
    Trans: 3
}

export const Selected_marital_status = {
    NotSelected: 0,
    Single: 1,
    Married: 2,
    Others: 3
}

export const Annual_income_status = {
    NotSelected: 0,
    below_one: 1,
    one_to_five: 2,
    five_to_ten: 3,
    ten_to_twentyfive: 4,
    above_twntyfive: 5

}

export const Checked_value = {
    Yes: 1,
    No: 2
}

export const ProofImageSides = {
    Not_Selected: 0,
    Front: 1,
    Rear: 2
}

export const Experience = {
    below_one: 1,
    one_to_two: 2,
    two_to_five: 3,
    five_to_ten: 4,
    ten_to_twenty: 5,
    twenty_to_twentyfive: 6,
    above_twentyfive: 7
}

export const AccountTypeValues1 = {
    NotSelected: 0,
    savings: 1,
    current: 2
}

export const Gender = {
    NotSelected: 0,
    Male: 1,
    Female: 2,
    Trans: 3
}

export const TradingExperience = {
    NotSelected: 0,
    less_than_one: 1,
    one_to_two: 2,
    two_to_five: 3,
    five_to_ten: 4,
    ten_to_twenty: 5,
    twenty_to_twenty_five: 6,
    above_twenty_five: 7
}

export const AccountTypeValues2 = {
    NotSelected: 0,
    single: 1,
    joint: 2
}

export const true_false_value = {
    True: 1,
    False: 0
}

export const depositories = {
    CSDL: 1,
    NSDL: 2
}

export const kycStatus = {
    NOT_STARTED: 0,
    PERSONAL_DETAILS: 1,
    EMAIL: 2,
    PAN: 3,
    AADHAR: 4,
    KRA_RECORDS: 5,
    IPV: 6,
    BANK: 7,
    DEMAT: 8,
    ESIGN: 9,
    KYC_COMPLETE: 10,
    APPLICATION_DOWNLOAD: 11,

}


export const API = {
    BaseUrl: "https://trust-ekyc-api.trustmony.com/api/v1/",
    EndPoint: Endpoints,
    Result: APIResult

}
