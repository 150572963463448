import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";
import StepsComponent from "../components/StepsComponent";

const ApplicationDownload: React.FC = () => {
  const { setShowPreloader } = useAppStateAPI();
  const [userDetail, setUserDetails] = useState<APIData.userDetail>();
  const navigate = useNavigate();
  const [KycExit, setKycExit] = useState(false);

  useEffect(() => {
    setShowPreloader(true);
    ServerAPI.userDetailData()
      .then((res) => {
        setUserDetails(res["data"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  }, []);

  return (
    <div className="main-index-wrapper">
      <main>
        <section className="subscribe-letter-area pt-20 pb-20">
          <div className="line-shape d-none d-lg-inline-block">
            <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
          </div>

          <div className="container custom-container-subs">
            <div className="sub-bg">
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="subscribe-wrapper">
                    <div className="section-title section-title-3 text-center">
                      <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                    </div>
                  </div>
                  <StepsComponent step={3} />
                  <div className="step_box_content">
                    <div className="sub-bg">
                      <div className="row justify-content-center">
                        <div className="col-xl-12">
                          <div className="subscribe-wrapper">
                            <div className="section-title section-title-3">
                              <h6 className="mb--10 wow " data-wow-delay="0.1s">
                                Open Demat Account <span className="step_count">STEPS - 4 of 4</span>
                              </h6>
                              <hr></hr>
                              <form className="quote-form mb-10" action="#">
                                <div className="col-xl-12 pl-0 mt-0 mb-50 float-left">
                                  <div className="col-xl-12 pl-0 float-left">
                                    <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">
                                      Hey, {userDetail?.personal_data_pan_fullname}
                                    </h6>
                                    <p className="personal_sub_text mt-10">You can download your application form and cross-check it at your convenience.</p>
                                    <div className="col-xl-8 p-0 float-left mt-3">
                                      <div
                                        className="theme_btn btn-change  esign_btn sub-btn wow  cursor"
                                        onClick={(e: any) => {
                                          e.preventDefault();
                                          ServerAPI.EsignDownload().then((response: any) => {
                                            window.location.replace(response["data"]["message"]);
                                          });
                                        }}
                                        data-wow-delay="0.5s"
                                      >
                                        Download Your Application Form
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-xl-12 p-0 float-left text-center mt-200">
                                  <div
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      navigate(PageLinks.ESIGN_AADHAAR);
                                    }}
                                    className="theme_btn primary_btn_one sub-btn wow mb-3"
                                    data-wow-delay="0.5s"
                                  >
                                    Continue to E-sign Process
                                  </div>
                                  <div
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      setKycExit(true);
                                    }}
                                    className="save_continue_btn text-center cursor"
                                  >
                                    <u>Save & Complete Later</u>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmationKYCModel
            showModal={KycExit}
            closeModal={() => {
              setKycExit(false);
            }}
            confirmtype={"applicationdownload"}
          />
        </section>
      </main>
    </div>
  );
};

export default ApplicationDownload;
