import { Backdrop, CircularProgress } from "@mui/material";
import { Fragment } from "react";
import { useAppStateAPI } from "../../common/AppStateAPI";


const Preloader: React.FC = () => {

    const { showPreloader } = useAppStateAPI();
    const animationType = localStorage.getItem('animation');

    return (
        <>
            {showPreloader &&
                animationType == 'default' ?
                <Backdrop
                    sx={{ color: '#ff0023', zIndex: "9999" }}
                    open={showPreloader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                <Backdrop
                    sx={{ color: '#ff405a', zIndex: "9999" }}
                    open={showPreloader}
                >
                    <section className="subscribe-letter-area pt-20 pb-20 animation-div">
                        <div className="line-shape d-none d-lg-inline-block">
                            <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                        </div>
                        <div className="container custom-container-subs">
                            <div className="text-center">
                                <Fragment>
                                    <div className="animation-pop">
                                        <video className="video-style" autoPlay controls={false} loop height="500px" width="100%" >
                                            {animationType == 'bank' &&
                                                <source src="assets/animations/bank.mp4" type="video/mp4" />
                                            }
                                            {animationType == 'pan' &&
                                                <source src="assets/animations/pan-verify.mp4" type="video/mp4 " />
                                            }
                                            {animationType == 'email' &&
                                                <source src="assets/animations/pan-verify.mp4" type="video/mp4 " />
                                            }
                                            {animationType == 'exisitingdemat' &&
                                                <source src="assets/animations/demat.mp4" type="video/mp4 " />
                                            }
                                            {animationType == 'demat' &&
                                                <source src="assets/animations/demat.mp4" type="video/mp4 " />
                                            }
                                            {animationType == 'identity' &&
                                                <source src="assets/animations/default_loader.mp4" type="video/mp4 " />
                                            }
                                        </video>
                                    </div>
                                    <div className="content">
                                        {animationType == 'bank' &&
                                            <Fragment>
                                                <h6> We Are Verifying Your Bank Account</h6>
                                                <p className="light-text">We will deposit ₹1 in your bank account to verify your account, please wait this may take sometime.</p>
                                            </Fragment>
                                        }
                                        {animationType == 'pan' &&
                                            <Fragment>
                                                <div className="mt-3 mb-3">
                                                    <img style={{ width: "25%" }} src="assets/img/Progress Bar_01.gif" />
                                                </div>
                                                <h6> We Are Verifying Your PAN</h6>
                                            </Fragment>
                                        }
                                        {animationType == 'exisitingdemat' &&
                                            <Fragment>
                                                {/* <div className="mt-3 mb-3">
                                                    <img style={{ width: "25%" }} src="assets/img/loader.png" />
                                                </div> */}
                                                <h6> We Are Verifying Your Demat Details</h6>
                                                <p className="light-text">We are validating your ID and Username with the authorities, this may take some time.</p>
                                            </Fragment>
                                        }
                                        {animationType == 'identity' &&
                                            <Fragment>
                                                {/* <div className="mt-3 mb-3">
                                                    <img style={{ width: "25%" }} src="assets/img/loader.png" />
                                                </div> */}
                                                <h6> We Are Verifying Your Identity</h6>
                                                <p className="light-text">We are validating your ID and Username with the authorities, this may take some time.</p>
                                            </Fragment>
                                        }
                                        {animationType == 'demat' &&
                                            <Fragment>
                                                {/* <div className="mt-3 mb-3">
                                                    <img style={{ width: "25%" }} src="assets/img/loader.png" />
                                                </div> */}
                                                <h6> We Are Verifying Your Demat Details</h6>
                                                <p className="light-text">We are validating your ID and Username with the authorities, this may take some time.</p>
                                            </Fragment>
                                        }
                                        {animationType == 'email' &&
                                            <Fragment>
                                                {/* <div className="mt-3 mb-3">
                                                    <img style={{ width: "25%" }} src="assets/img/loader.png" />
                                                </div> */}
                                                <h6> We Are Verifying Your Email ID</h6>
                                                <p className="light-text">We are validating your ID and Username with the service provider, this may take some time.</p>
                                            </Fragment>
                                        }

                                    </div>

                                    {/* <div>
                                    <img src="" alt="" className="video-style" />

                                    <div className="content">
                                        <h6> We Are Verifying Your test</h6>
                                        <p className="light-text">We are validating your ID and Username with the authorities, this may take some time.</p>
                                    </div>

                                </div> */}
                                </Fragment>
                            </div>
                        </div>
                    </section>
                </Backdrop>
            }
        </>
    )
}

export default Preloader;