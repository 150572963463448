import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import ChequeUpload from "./ChequeUpload";
import { useNavigate } from "react-router-dom";
import { kycStatus, PageLinks } from "../../common/Constants";
import { ServerAPI } from "../../common/ServerAPI";
import toast from "react-hot-toast";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { kycStatusUpdate } from "../../common/Utilities";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
    confirmtype: string,
}

const ConfirmationKYCModel: React.FC<FormParameters> = ({ showModal, closeModal, confirmtype }) => {
    const navigate = useNavigate();
    const { setShowPreloader } = useAppStateAPI();

    const confirmFunction = () => {
        if(confirmtype=='personaldetails'){
            kycStatusUpdate(kycStatus.PERSONAL_DETAILS);
        }else if(confirmtype=='krarecords'){
            kycStatusUpdate(kycStatus.KRA_RECORDS);
        }else if(confirmtype=='aadhar'){
            kycStatusUpdate(kycStatus.AADHAR);
        }else if(confirmtype=='bank'){
            kycStatusUpdate(kycStatus.BANK);
        }else if(confirmtype=='demat'){
            kycStatusUpdate(kycStatus.DEMAT);
        }else if(confirmtype=='email'){
            kycStatusUpdate(kycStatus.EMAIL);
        }else if(confirmtype=='pan'){
            kycStatusUpdate(kycStatus.PAN);
        }else if(confirmtype=='ipv'){
            kycStatusUpdate(kycStatus.IPV);
        }else if(confirmtype=='applicationdownload'){
            kycStatusUpdate(kycStatus.APPLICATION_DOWNLOAD);
        }else if(confirmtype=='esign'){
            kycStatusUpdate(kycStatus.ESIGN);
        }
    }

    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog className="dialog-background"
                    open={showModal}
                    keepMounted
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            closeModal();
                        }
                    }}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    PaperProps={{
                        style: {
                            background: 'none',
                            boxShadow: 'none',
                        },
                    }}
                    maxWidth="md"
                >
                    <DialogContent style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div className="do-box mb-30 confirmation-model-bg text-center">
                                <img src="assets/img/icon/warning.png" className="user_icon" />
                                <p className="mb-10  identity_verify_success" data-wow-delay="0.1s">Are you sure?</p>
                                <p className="mb-10  confirmation-model-sub-head" data-wow-delay="0.1s">Do you want to Exit the KYC Progress?</p>
                                <div className="mt-3 col-md-12 col-xl-12 row col-12">
                                    <div className="col-md-6 col-xl-6 col-12">
                                        <button onClick={(e: any) => {
                                            e.preventDefault();
                                            closeModal();
                                        }} type="submit" className="theme_btn email_verify_btn primary_btn_1 sub-btn " data-wow-delay="0.5s">Cancel</button>
                                    </div>
                                    <div className="col-md-6 col-xl-6 col-12">
                                        <button onClick={(e: any) => {
                                            e.preventDefault();
                                            confirmFunction();
                                            closeModal();
                                        }} type="submit" className="theme_btn email_verify_btn primary_btn_1 sub-btn confirm-btn" data-wow-delay="0.5s">Yes</button>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

            </main>
        </div>
    )
}

export default ConfirmationKYCModel;