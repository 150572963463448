import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";

const SignatureSuccess: React.FC = () => {
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: any) => {
            setUserDetails(res)
        }).finally(() => {
            setShowPreloader(false);
        })

    }, []);

    const PageNavigation = () => {
        navigate(PageLinks.ADD_NOMINEE);
    }

    setTimeout(PageNavigation, 3000);

    return (
        <Fragment>
            <section className="subscribe-letter-area pt-20 pb-20 kyc-section sign-success">
                <div className="line-shape d-none d-lg-inline-block">
                    <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                </div>
                <div className="container custom-container-subs">
                    <div className="row justify-content-center cursor" onClick={(e: any) => {
                        navigate(PageLinks.ADD_NOMINEE);
                    }}>
                        <div className="col-lg-6 col-md-6  text-left" data-wow-delay="0.3s">
                            <div className="">
                                <div className="icon mb-25 icon_img">
                                    <img src="assets/img/icon/Thumbs_up.png" alt="" />
                                </div>
                                <p className="otp_popup_text">Congratulations! {userDetail?.personal_data_pan_fullname} Signature Uploaded Successfully</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default SignatureSuccess;