import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { PageLinks } from "../../common/Constants";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ServerAPI } from "../../common/ServerAPI";
import toast from "react-hot-toast";
import AddressProofPreview from "./AddressProofPreview";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { Field } from "formik";
import { APIData } from "../../common/DataTypes";

interface FormParameters {
    showModal: boolean,
    closeModal: any,

}

const BankIFSC: React.FC<FormParameters> = ({ showModal, closeModal }) => {
    const { setShowPreloader } = useAppStateAPI();
    const [IFSCBank, setIFSCBank] = useState<APIData.IFSCBank[]>([]);
    const [IFSCState, setIFSCState] = useState<APIData.IFSCState[]>([]);
    const [IFSCCity, setIFSCCity] = useState<APIData.IFSCCity[]>([]);
    const [IFSCBranch, setIFSCBranch] = useState<APIData.IFSCBranch[]>([]);
    const [ifscDetails, setifscDetails] = useState<APIData.IFSCBranch>();
    const [IFSCBankID, setIFSCBankId] = useState(0);
    const [IFSCBankStateID, setIFSCBankStateId] = useState(0);
    const [IFSCBankCityID, setIFSCBankCityId] = useState(0);
    const [IFSCBankBranchID, setIFSCBankBranchId] = useState(0);
    localStorage.setItem('animation', 'default');


    const bank = () => {
        setShowPreloader(true);
        ServerAPI.SearchForIFSC().then((res: any) => {
            if (res != undefined) {
                setIFSCBank(res['data']);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }


    useEffect(() => {
        bank();
    }, [])

    useEffect(() => {
        if (IFSCBankID != 0) {
            setShowPreloader(true);
            ServerAPI.SearchForIFSC(IFSCBankID).then((res: any) => {
                if (res != undefined) {
                    setIFSCState(res['data']);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [IFSCBankID])
    useEffect(() => {
        if (IFSCBankID != 0 && IFSCBankStateID != 0) {
            setShowPreloader(true);
            ServerAPI.SearchForIFSC(IFSCBankID, IFSCBankStateID).then((res: any) => {
                if (res != undefined) {
                    setIFSCCity(res['data']);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [IFSCBankID, IFSCBankStateID])
    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.SearchForIFSC(IFSCBankID, IFSCBankStateID, IFSCBankCityID).then((res: any) => {
            if (res != undefined) {
                setIFSCBranch(res['data']);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }, [IFSCBankID, IFSCBankStateID, IFSCBankCityID])

    useEffect(() => {
        if (IFSCBranch.length > 0) {
            let bankbranches = [...IFSCBranch];
            let selectedbranch = bankbranches.filter((branch: APIData.IFSCBranch) => branch.bank_branch_collection_id == IFSCBankBranchID);
            setifscDetails(selectedbranch[0]);
            if (ifscDetails != undefined) {
                localStorage.setItem('popupIFSC', ifscDetails.bank_branch_ifsc_collection_name);
            }
        }
    }, [IFSCBankBranchID])

    useEffect(() => {
        setIFSCBankId(0);
        setIFSCBankStateId(0);
        setIFSCBankCityId(0);
    }, [showModal])




    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog
                    open={showModal}
                    keepMounted
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    maxWidth="lg"
                >
                    <DialogTitle> Search for IFSC
                        <button onClick={(e: any) => {
                            e.preventDefault();
                            closeModal();
                        }} className="theme_btn profile_close_btn sub-btn wow "><i className="far fa-times-circle"></i> Close</button>
                    </DialogTitle>
                    <hr />
                    <DialogContent className="cancelled-cheque-bg" style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div className="col-xl-12 bank-form">
                                <div className="subscribe-wrapper">
                                    <div className="col-xl-12 p-0 float-left">
                                        <form className="quote-form mb-1">
                                            <div className="col-xl-12 p-0 row mb-1">
                                                <div className="col-xl-5">
                                                    <div className="email-input input_field pl-0">
                                                        <label className="input-title">Bank Name</label>
                                                        <select name="occupation_id" className="" onChange={(e: any) => {
                                                            e.preventDefault();
                                                            setIFSCBankId(e.target.value);
                                                        }}>
                                                            <option value={0}>select</option>
                                                            {IFSCBank != undefined && IFSCBank.length > 0 && IFSCBank.map((bank: APIData.IFSCBank) => {
                                                                return (
                                                                    <option value={bank.bank_name_collection_id}>{bank.bank_name_collection_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 p-0 row mb-1">
                                                <div className="col-xl-3 bank-details ifsc-bank d-flex"><img className="ifsc-logo" src="assets/img/sbi.png" />SBI</div>
                                                <div className="col-xl-3 bank-details ifsc-bank d-flex"><img className="ifsc-logo" src="assets/img/hdfc.png" />HDFC</div>
                                                <div className="col-xl-3 bank-details ifsc-bank d-flex"><img className="ifsc-logo" src="assets/img/icici.png" />ICICI</div>
                                            </div>
                                            <div className="col-xl-12 p-0 row mb-1">
                                                <div className="col-xl-3 bank-details ifsc-bank d-flex"><img className="ifsc-logo" src="assets/img/lvb.png" />LVB</div>
                                                <div className="col-xl-3 bank-details ifsc-bank d-flex"><img className="ifsc-logo" src="assets/img/axis.png" />AXIS</div>
                                                <div className="col-xl-3 bank-details ifsc-bank d-flex"><img className="ifsc-logo" src="assets/img/canara.png" />CANARA</div>
                                            </div>
                                            <div className="col-xl-12 p-0 row">
                                                <div className="col-xl-5">
                                                    <div className="email-input input_field pl-0">
                                                        <label className="input-title">Bank State</label>
                                                        <select name="occupation_id" className="" onChange={(e: any) => {
                                                            e.preventDefault();
                                                            setIFSCBankStateId(e.target.value);
                                                        }}>
                                                            <option value={0}>select</option>
                                                            {IFSCState != undefined && IFSCState.length > 0 && IFSCState.map((state: APIData.IFSCState) => {
                                                                return (
                                                                    <option value={state.bank_state_collection_id}>{state.bank_state_collection_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xl-5">
                                                    <div className="email-input input_field pl-0">
                                                        <label className="input-title">Bank City</label>
                                                        <select name="occupation_id" className="" onChange={(e: any) => {
                                                            e.preventDefault();
                                                            setIFSCBankCityId(e.target.value);
                                                        }}>
                                                            <option value={0}>select</option>
                                                            {IFSCCity != undefined && IFSCCity.length > 0 && IFSCCity.map((city: APIData.IFSCCity) => {
                                                                return (
                                                                    <option value={city.bank_city_collection_id}>{city.bank_city_collection_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 p-0 row mt-2">
                                                <div className="col-xl-5">
                                                    <div className="email-input input_field pl-0">
                                                        <label className="input-title">Bank Branch</label>
                                                        <select name="occupation_id" className="" onChange={(e: any) => {
                                                            e.preventDefault();
                                                            setIFSCBankBranchId(e.target.value);
                                                        }}>
                                                            <option value={0}>select</option>
                                                            {IFSCBranch != undefined && IFSCBranch.length > 0 && IFSCBranch.map((branch: APIData.IFSCBranch) => {
                                                                return (
                                                                    <option value={branch.bank_branch_collection_id}>{branch.bank_branch_address_collection_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {ifscDetails &&
                                                <div className="col-xl-12 p-0 row mt-2">
                                                    <div className="col-xl-8">
                                                        <div className="email-input input_field pl-0">
                                                            <label className="input-title">Bank Details</label>
                                                            <div className="bank-details col-xl-12">
                                                                <p>{ifscDetails?.bank_branch_collection_name}</p>
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan={2} className="bank-details-table-head">IFSC Code</th>
                                                                            <th colSpan={2} className="bank-details-table-head">Branch</th>
                                                                            <th colSpan={2} className="bank-details-table-head">Address</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td colSpan={2} className="bank-details-table-body">{ifscDetails?.bank_branch_ifsc_collection_name}</td>
                                                                            <td colSpan={2} className="bank-details-table-body">{ifscDetails?.bank_branch_collection_name}</td>
                                                                            <td colSpan={2} className="bank-details-table-body">{ifscDetails?.bank_branch_address_collection_name}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </main>
        </div>
    )
}

export default BankIFSC;