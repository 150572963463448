import { useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";

const DijiSuccessPopup: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const preData:any = location.state;

    return (
        <div className="main-index-wrapper">
            <main>
                {/* subscribe-letter-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>
                </section>
                    <section className="otp_popup">
                        <div className="blur_back"></div>
                        <div className="container custom-container-subs leaf_bg">
                            <div className="sub-bg pt-85 pb-75 pr-100 pl-100 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-md-6 wow  text-center" data-wow-delay="0.3s">
                                        <div className=" do-box do-box-2">
                                            <div className="icon mb-25 icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 209.916 200.875" className="mt-2">
                                                <g id="Group_15427" data-name="Group 15427" transform="translate(0.001)">
                                                    <g id="Group_3212" data-name="Group 3212" transform="translate(-0.001 10.539)" opacity="0.15">
                                                    <g id="Group_3211" data-name="Group 3211" transform="translate(0 0)">
                                                        <path id="Path_11149" data-name="Path 11149" d="M-104.554-468.9a11.035,11.035,0,0,1-11.035-11.035V-581.124a11.034,11.034,0,0,1,11.035-11.034A11.038,11.038,0,0,1-93.52-581.124v101.187A11.039,11.039,0,0,1-104.554-468.9Z" transform="translate(165.9 659.238)" fill="#ff6ad3"/>
                                                        <path id="Path_11150" data-name="Path 11150" d="M31.127-417.839H-37.2a9.948,9.948,0,0,1-9.951-9.949,9.95,9.95,0,0,1,9.951-9.95H31.127a11.786,11.786,0,0,0,11.707-10.028L53.148-514.33a11.058,11.058,0,0,0-2.567-9.011,11.045,11.045,0,0,0-8.508-3.915H-7.352a9.947,9.947,0,0,1-9.951-9.949V-576.69a11.5,11.5,0,0,0-11.483-11.487,1.736,1.736,0,0,0-1.925,1.363,214.372,214.372,0,0,1-18.832,59.086l-6.378,13.062a9.946,9.946,0,0,1-8.941,5.593h-37.42a3.643,3.643,0,0,0-3.642,3.636v64.056a3.644,3.644,0,0,0,3.642,3.642h37.33A9.95,9.95,0,0,1-55-427.788a9.948,9.948,0,0,1-9.951,9.949h-37.33a23.568,23.568,0,0,1-23.543-23.541v-64.056a23.562,23.562,0,0,1,23.543-23.534h31.2l3.652-7.484a194.751,194.751,0,0,0,17.08-53.563,21.412,21.412,0,0,1,21.24-18.058A31.491,31.491,0,0,1,2.6-576.69v29.535H42.073A31.077,31.077,0,0,1,65.7-536.29a31.084,31.084,0,0,1,7.12,25.008L62.5-444.723A31.583,31.583,0,0,1,31.127-417.839Z" transform="translate(125.825 608.076)" fill="#ff6ad3"/>
                                                    </g>
                                                    </g>
                                                    <g id="Group_3213" data-name="Group 3213" transform="translate(10.902)">
                                                    <path id="Path_11151" data-name="Path 11151" d="M-102.578-481.843c-6.091,0-11.035-4.5-11.035-10.047v-92.133c0-5.551,4.944-10.046,11.035-10.046s11.035,4.495,11.035,10.046v92.133C-91.544-486.344-96.482-481.843-102.578-481.843Z" transform="translate(163.924 672.174)" fill="#ee445c"/>
                                                    <path id="Path_11152" data-name="Path 11152" d="M33.108-419.755H-35.228a9.954,9.954,0,0,1-9.951-9.95,9.951,9.951,0,0,1,9.951-9.949H33.108a11.777,11.777,0,0,0,11.7-10.03L55.129-516.25a11.033,11.033,0,0,0-2.567-9,11.04,11.04,0,0,0-8.508-3.915H-5.376a9.954,9.954,0,0,1-9.951-9.95V-578.6A11.494,11.494,0,0,0-26.81-590.088a1.734,1.734,0,0,0-1.92,1.358,214.283,214.283,0,0,1-18.837,59.081L-53.94-516.58a9.942,9.942,0,0,1-8.946,5.587h-37.42a3.647,3.647,0,0,0-3.642,3.641v64.061a3.646,3.646,0,0,0,3.642,3.636h37.335A9.951,9.951,0,0,1-53.02-429.7a9.954,9.954,0,0,1-9.951,9.95h-37.335a23.566,23.566,0,0,1-23.543-23.536v-64.061a23.566,23.566,0,0,1,23.543-23.54H-69.1l3.647-7.482a194.207,194.207,0,0,0,17.08-53.555,21.42,21.42,0,0,1,21.245-18.057A31.486,31.486,0,0,1,4.575-578.6v29.54H44.054a31.091,31.091,0,0,1,23.623,10.859A31.083,31.083,0,0,1,74.8-513.2L64.478-446.634A31.569,31.569,0,0,1,33.108-419.755Z" transform="translate(123.849 609.986)" fill="#28bfd2"/>
                                                    </g>
                                                </g>
                                                </svg>
                                            </div>
                                            <h5 className="otp_popup_text">Document(s) Pulled Succesfully <br></br> You May Close The Window.</h5>
                                       
                                        <div onClick={(e:any)=>{
                                                window.close(); 
                                                window.opener.location.href = PageLinks.AUTHENTICATE_AADHAAR_DETAIL;
                                        }}>
                                        <p className="theme_btn btn-change popup_btn sub-btn wow " data-wow-delay="0.5s">Let's Continue</p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                {/* subscribe-letter-area end */}
            </main>
        </div>
        )
}

export default DijiSuccessPopup;
