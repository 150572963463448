import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
// import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";

const DematSignaturePreview: React.FC = () => {

    // const location = useLocation();
    const navigate = useNavigate();
    const [date_of_birth, setDob] = useState<null | Date>();
    const [total, setTotal] = useState({})
    const [btn_disable, setBtn_disable] = useState(false)
    const [checkbox_value, setCheckbox_value] = useState({
        agreed_1: false,
        agreed_2: false
    })
    const [btn_color, setBtn_color] = useState(false);
    const [showText, setShowText] = useState(false)
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const [userValue, setValue] = useState({
        fname: "",
        lname: "",
        mobile_number: "",
        is_politically_exposed: false,
        would_you_like_to_activate: false

    })
    const { setShowPreloader } = useAppStateAPI();

    useEffect(() => {
        if (userDetail == undefined) {
            setShowPreloader(true);
            ServerAPI.userDetailData().then((res: any) => {
                setUserDetails(res);
                setValue({
                    ...userValue,
                    fname: res.fname,
                    lname: res.lname,
                    mobile_number: res.mobile_number,
                    is_politically_exposed: res.is_politically_exposed == 1 ? true : false,
                    would_you_like_to_activate: res.would_you_like_to_activate == 1 ? true : false,
                })

                setDob(res.dob)
            }).finally(() => {
                setShowPreloader(false);
            })
        }
        let dob = moment(date_of_birth).format('DD-MM-YYYY');
        setTotal({ ...userValue, dob });
    }, [userValue]);

    useEffect(() => {
        if (userValue.fname != "" && userValue.lname != "" && userValue.mobile_number != "" && checkbox_value.agreed_1 == true && checkbox_value.agreed_2 == true) {
            setBtn_color(true)
        } else { setBtn_color(false) }

    }, [checkbox_value, userValue])

    const userDetails = (e: any, toggle: string) => {
        if (toggle == "") {
            setValue({
                ...userValue,
                [e.target.name]: e.target.value

            })

        }
        if (toggle != "") {
            setValue({
                ...userValue,
                [toggle]: e.target.checked

            })
        }

    }

    const checkbox_change = (e: any) => {
        setCheckbox_value({
            ...checkbox_value,
            [e.target.name]: e.target.checked
        })
    }

    const next = () => {
        if (checkbox_value.agreed_1 == false || checkbox_value.agreed_2 == false) {
            setShowText(true)

        } else {
            setShowText(false)

        }

        if (userValue.fname != "" && userValue.lname != "" && userValue.mobile_number != "" && checkbox_value.agreed_1 == true && checkbox_value.agreed_2 == true) {
            navigate(PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VERIFY_EMAIL, { state: total })
        } else {

        }
    }

    return (
        <div className="main-index-wrapper">
            <main>
                {/* Profile-details-edit-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                           <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                        </div>
                                    </div>
                                    <div className="progress-indicator-element">
                                    <ul className="progress-indicator"> 
                                            <li className="completed">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 01</span> <br></br><p> Personal Details</p></span>
                                                </span>
                                                {/* <span className="bubble"></span> */}
                                            </li>
                                            <li className="completed msg_contain">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 02</span> <br></br><p> Bank Details</p></span>
                                                </span>
                                                {/* <span className="bubble"></span> */}
                                                <div className="bse-order-no">
                                                </div>
                                                <div className="bse-order-msg msg-success">
                                                </div>
                                            </li>
                                            <li className="completed">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 03</span> <br></br> <p> Demat Details</p></span>
                                                </span>
                                                {/* <span className="bubble"></span> */}
                                            </li>
                                        </ul>
                                        <div className='line-container'>
                                            <div className='progress-line'>
                                                <div className='progress '></div>
                                                <div className='status'>
                                                    <div className='dot completed'></div>
                                                </div>
                                                <div className='status'>
                                                    <div className='dot current'></div>
                                                </div>
                                                <div className='status'>
                                                    <div className='dot'></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="mb--10 wow " data-wow-delay="0.1s">Open Demat Account <span className="step_count">STEPS - 2 of 4</span></h6>
                                                            <hr></hr>
                                                            <form className="quote-form mb-10" action="#">
                                                                <div className="col-xl-8 pl-0 float-left">
                                                                    <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">Signature Upload</h6>
                                                                    <p className="personal_sub_text">Let’s Upload your Signature</p>
                                                                </div>
                                                                <div className="col-xl-4 p-0 float-left">

                                                                </div>
                                                                <div className="col-xl-12 p-0 float-left">
                                                                    <div className="signature_preview_box">
                                                                        <div className="signature_preview_box_head">Signature Preview</div>
                                                                        <div className="signature_preview_box_content">
                                                                            <p className="signature_preview_box_content_text">Ensure that you sign on a blank paper in a clearly <Link to={"#"}>See Example</Link></p>
                                                                            <div className="upload_img"><img src="assets/img/icon/Upload.png" /></div>
                                                                            <p className="upload_img_text">Upload your Signature</p>
                                                                            <p className="signature_preview_box_content_text_one">upload your Signature <Link to={'#'}>JPG</Link>, <Link to={'#'}>JPEG</Link> or PNG in less than <Link to={'#'}>10 MB</Link></p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="signature_preview_box_with_sign">
                                                                        <div className="signature_preview_box_head">Signature Preview</div>
                                                                        <div className="signature_preview_box_content">
                                                                            <div className="signature_preview_box_content_sign">
                                                                                <img src="assets/img/icon/upload_sign.png" />
                                                                            </div>
                                                                            <div className="signature_preview_box_content_replace_btn">Replace</div>
                                                                            <p className="signature_preview_box_content_text_one">upload your Signature <Link to={'#'}>JPG</Link>, <Link to={'#'}>JPEG</Link> or PNG in less than <Link to={'#'}>10 MB</Link></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-12 p-0 float-left text-center mt-20">
                                                                    {/* to={PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VERIFY_EMAIL} */}
                                                                    {/* <a className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s" onClick={() => next()}>Continue</a> */}
                                                                    <Link to={PageLinks.SIGNATURE_SUCCESS} className="theme_btn primary_btn sub-btn wow" data-wow-delay="0.5s">Continue</Link>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Profile-details-edit-area end */}
            </main>
        </div>
    )
}

export default DematSignaturePreview;
