import { Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { Annual_income_status, Gender, kycStatus, PageLinks, Selected_marital_status, TradingExperience } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { kycStatusUpdate } from "../common/Utilities";
import { InitialData } from "../components/Form/InitialData";
import AppologiesPopup from "../components/Modals/AppologiesPopup";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";
import StepsComponent from "../components/StepsComponent";

const AccountOpeningpennyDrop: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
  const [gender, setGender] = useState(Gender.NotSelected);
  const [maritalStatus, setMaritalStatus] = useState(Selected_marital_status.NotSelected);
  const [annualIncome, setAnnualIncome] = useState(Annual_income_status.NotSelected);
  const [tradingExperience, setTradingExperience] = useState(TradingExperience.NotSelected);
  const [professions, setProfessions] = useState<APIData.ProfessionDropDown[]>([]);
  const [genderDropDown, setGenderDropdown] = useState<APIData.Gender[]>([]);
  const [maritalStatusDropDown, setMaritalStatusDropdown] = useState<APIData.MaritialStatus[]>([]);
  const [annualIncomeDropDown, setAnnualIncomeDropdown] = useState<APIData.AnnualIncome[]>([]);
  const [tradingDropDown, setTradingDropdown] = useState<APIData.TradingExperience[]>([]);
  const [KycExit, setKycExit] = useState(false);
  const { setShowPreloader } = useAppStateAPI();
  const [apologyPopup, setApologyPopup] = useState(false);

  useEffect(() => {
    setShowPreloader(true);
    ServerAPI.userDetailData()
      .then((res: APIData.userDetail) => {
        if (res.gender_id != null) {
          setGender(res.gender_id);
        }
        if (res.marital_status_id != null) {
          setMaritalStatus(res.marital_status_id);
        }
        if (res.annual_income_id != null) {
          setAnnualIncome(res.annual_income_id);
        }
        if (res.trading_experience_id != null) {
          setTradingExperience(res.trading_experience_id);
        }
        setUserDetails(res);
      })
      .finally(() => {
        setShowPreloader(false);
      });

    setShowPreloader(true);
    ServerAPI.Gender()
      .then((res: any) => {
        setGenderDropdown(res["data"]["message"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });
    setShowPreloader(true);
    ServerAPI.ProfessionDropDown()
      .then((res: any) => {
        setProfessions(res["data"]["message"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });

    setShowPreloader(true);
    ServerAPI.MaritalStatus()
      .then((res: any) => {
        setMaritalStatusDropdown(res["data"]["message"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });

    setShowPreloader(true);
    ServerAPI.AnnualIncome()
      .then((res: any) => {
        setAnnualIncomeDropdown(res["data"]["message"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });

    setShowPreloader(true);
    ServerAPI.TradingExperience()
      .then((res: any) => {
        setTradingDropdown(res["data"]["message"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  }, []);

  return (
    <div className="main-index-wrapper">
      <main>
        <section className="subscribe-letter-area pt-20 pb-20">
          <div className="line-shape d-none d-lg-inline-block">
            <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
          </div>

          <div className="container custom-container-subs">
            <div className="sub-bg">
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="subscribe-wrapper">
                    <div className="section-title section-title-3 text-center">
                      <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                    </div>
                  </div>
                  <StepsComponent step={1} />
                  <div className="step_box_content">
                    <div className="sub-bg">
                      <div className="row justify-content-center">
                        <div className="col-xl-12">
                          <div className="subscribe-wrapper">
                            <div className="section-title section-title-3">
                              <h6 className="wow page-main-heading" data-wow-delay="0.1s">
                                Personal Details <span className="page-main-heading-steps">STEPS - 1 of 2</span>
                              </h6>

                              <hr></hr>
                              <Formik
                                initialValues={userDetail}
                                enableReinitialize
                                onSubmit={(values, { setSubmitting }) => {
                                  if (values.occupation_id == null) {
                                    toast.error("Please Select Your Occupation Type Before Proceeding");
                                  } else {
                                    setShowPreloader(true);
                                    ServerAPI.add_profile_details(values)
                                      .then((res) => {
                                        if (res != undefined) {
                                          toast.success(res["data"]["message"]);
                                          let ipv = localStorage.getItem("verification");
                                          if (ipv != null && ipv != undefined) {
                                            if (ipv == "false") {
                                              navigate(PageLinks.VERIFY_YOUR_IDENTITY);
                                            } else if (ipv == "true") {
                                              navigate(PageLinks.BANK_DETAILS);
                                            } else {
                                              navigate(PageLinks.VERIFY_YOUR_IDENTITY);
                                            }
                                          } else {
                                            navigate(PageLinks.VERIFY_YOUR_IDENTITY);
                                          }
                                        }
                                      })
                                      .finally(() => {
                                        setShowPreloader(false);
                                      });
                                  }
                                  ServerAPI.userConcent(values)
                                    .then((res: any) => {})
                                    .finally(() => {
                                      setShowPreloader(false);
                                    });
                                }}
                              >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                  <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                    <div className="col-xl-12 p-0 float-left">
                                      <div className="choice-list mb-3">
                                        <span className="input-title">What is your gender?</span>
                                        <span className="input-title-filled gender_col" role={"group"}>
                                          {genderDropDown != undefined &&
                                            genderDropDown.length > 0 &&
                                            genderDropDown.map((dropdown: APIData.Gender) => {
                                              return (
                                                <div className={gender == dropdown.gender_id ? "gender_btn selected" : "gender_btn"}>
                                                  <input
                                                    onChange={(e: any) => {
                                                      if (e.target.checked) {
                                                        setGender(dropdown.gender_id);
                                                      }
                                                      handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    type="radio"
                                                    value={dropdown.gender_id}
                                                    checked={gender == dropdown.gender_id ? true : false}
                                                    className="d-none"
                                                    name="gender_id"
                                                    id={"gender" + dropdown.gender_id + ""}
                                                  />
                                                  <label htmlFor={"gender" + dropdown.gender_id + ""} className={gender == dropdown.gender_id ? "gender-btn-label-selected" : "gender_btn-label"}>
                                                    {dropdown.gender}
                                                  </label>
                                                </div>
                                              );
                                            })}
                                        </span>
                                      </div>
                                      <div className="choice-list list1 mb-2">
                                        <span className="input-title">What is your marital status?</span>
                                        <span className="input-title-filled gender_col" role={"group"}>
                                          {maritalStatusDropDown != undefined &&
                                            maritalStatusDropDown.length > 0 &&
                                            maritalStatusDropDown.map((status: APIData.MaritialStatus) => {
                                              return (
                                                <div className={maritalStatus == status.marital_status_id ? "gender_btn selected" : "gender_btn"}>
                                                  <input
                                                    onChange={(e: any) => {
                                                      if (e.target.checked) {
                                                        setMaritalStatus(status.marital_status_id);
                                                      }
                                                      handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    type="radio"
                                                    value={status.marital_status_id}
                                                    checked={maritalStatus == status.marital_status_id ? true : false}
                                                    className="d-none"
                                                    name="marital_status_id"
                                                    id={"marital" + status.marital_status_id + ""}
                                                  />
                                                  <label htmlFor={"marital" + status.marital_status_id + ""} className={status.marital_status_id ? "gender-btn-label-selected" : "gender_btn-label"}>
                                                    {status.marital_status}
                                                  </label>
                                                </div>
                                              );
                                            })}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-xl-12 p-0 float-left list1">
                                      <div className="col-xl-4 pl-0 float-left mt-1">
                                        <div className="email-input input_field pl-0">
                                          <label className="input-title">What is your Occupation *</label>
                                          <Field as="select" className="" name="occupation_id" onChange={handleChange} onBlur={handleBlur}>
                                            <option>select</option>
                                            {professions != undefined &&
                                              professions.length > 0 &&
                                              professions.map((profession: APIData.ProfessionDropDown, index) => {
                                                return (
                                                  <option key={index} value={profession.occupation_id}>
                                                    {profession.occupation}
                                                  </option>
                                                );
                                              })}
                                          </Field>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-12 p-0 float-left">
                                      <div className="choice-list mb-124">
                                        <span className="input-title">What is your annual income?</span>
                                        <span className="input-title-filled gender_col" role={"group"}>
                                          {annualIncomeDropDown != undefined &&
                                            annualIncomeDropDown.length > 0 &&
                                            annualIncomeDropDown.map((annual: APIData.AnnualIncome) => {
                                              return (
                                                <div className={annualIncome == annual.annual_income_id ? "gender_btn selected" : "gender_btn"}>
                                                  <input
                                                    onChange={(e: any) => {
                                                      if (e.target.checked) {
                                                        setAnnualIncome(annual.annual_income_id);
                                                      }
                                                      handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    type="radio"
                                                    value={annual.annual_income_id}
                                                    checked={annualIncome == annual.annual_income_id ? true : false}
                                                    className="d-none"
                                                    name="annual_income_id"
                                                    id={"annual_income" + annual.annual_income_id + ""}
                                                  />
                                                  <label htmlFor={"annual_income" + annual.annual_income_id + ""} className={annualIncome == annual.annual_income_id ? "gender-btn-label-selected" : "gender_btn-label"}>
                                                    {annual.annual_income}
                                                  </label>
                                                </div>
                                              );
                                            })}
                                        </span>
                                      </div>
                                      <div className="choice-list">
                                        <span className="input-title">What is your trading experience?</span>
                                        <span className="input-title-filled gender_col" role={"group"}>
                                          {tradingDropDown != undefined &&
                                            tradingDropDown.length > 0 &&
                                            tradingDropDown.map((trading: APIData.TradingExperience) => {
                                              return (
                                                <div className={tradingExperience == trading.trading_experience_id ? "gender_btn selected" : "gender_btn"}>
                                                  <input
                                                    onChange={(e: any) => {
                                                      if (e.target.checked) {
                                                        setTradingExperience(trading.trading_experience_id);
                                                      }
                                                      handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    type="radio"
                                                    value={trading.trading_experience_id}
                                                    checked={tradingExperience == trading.trading_experience_id ? true : false}
                                                    className="d-none"
                                                    name="trading_experience_id"
                                                    id={"trading" + trading.trading_experience_id}
                                                  />
                                                  <label htmlFor={"trading" + trading.trading_experience_id} className={tradingExperience == trading.trading_experience_id ? "gender-btn-label-selected" : "gender_btn-label"}>
                                                    {trading.trading_experience}
                                                  </label>
                                                </div>
                                              );
                                            })}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-xl-12 p-0 float-left">
                                      <span className="input-title">Are You A Tax Resident Of Any Other Country Other Than India?</span>
                                      <div className="my-account-input-title-filled-switch">
                                        <div className="custom-switch custom-switch-label-yesno pl-0 my-account-profile-switch my-account-profile-switch-kra">
                                        <input
                                              className="custom-switch-input"
                                              id="is_politically_exposed"
                                              type="checkbox"
                                              checked={values.user_citizen_usa == 1 ? true : false}
                                              name="is_politically_exposed"
                                              onChange={(e: any) => {
                                                if (e.target.checked) {
                                                  values.user_itr_records = 1;
                                                  setApologyPopup(true);
                                                } else {
                                                  values.user_itr_records = 0;
                                                }                                               
                                              }}
                                            />
                                            <label className="custom-switch-btn" htmlFor="is_politically_exposed"></label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-12 p-0 float-left mt-3">
                                      <span className="input-title">Have You Filed ITR For Last 2 Years</span>
                                      <div className="my-account-input-title-filled-switch">
                                        <div className="custom-switch custom-switch-label-yesno pl-0 my-account-profile-switch my-account-profile-switch-kra">
                                          <input
                                            className="custom-switch-input"
                                            id="filled_itr_last_2years"
                                            type="checkbox"
                                            name="user_itr_records"
                                            onChange={(e: any) => {
                                              if (e.target.checked) {
                                                values.user_itr_records = 1;
                                              } else {
                                                values.user_itr_records = 0;
                                              }
                                            }}
                                          />
                                          <label className="custom-switch-btn" htmlFor="filled_itr_last_2years"></label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-12 p-0 float-left">
                                      <div className="col-xl-4 pl-0 float-left mt-2">
                                        <div className="email-input input_field pl-0">
                                          <label className="input-title">Mother’s Maiden Name</label>
                                          <Field as="input" type="text" name="personal_data_mothers_maiden_name" onChange={handleChange} onBlur={handleBlur} placeholder="Enter Mother's Maiden Name" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-12 continue_btn_whole mb-3">
                                      <button type="submit" className={gender != Gender.NotSelected && values.occupation_id != null && values.personal_data_mothers_maiden_name != "" && annualIncome != Annual_income_status.NotSelected && tradingExperience != TradingExperience.NotSelected ? "theme_btn primary_btn_one sub-btn wow w-auto" : "theme_btn primary_btn sub-btn wow"} data-wow-delay="0.5s">
                                        Continue
                                      </button>
                                    </div>
                                    <div
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        setKycExit(true);
                                      }}
                                      className="save_continue_btn text-center cursor"
                                    >
                                      <u>Save & Complete Later</u>
                                    </div>
                                  </form>
                                )}
                              </Formik>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmationKYCModel
            showModal={KycExit}
            closeModal={() => {
              setKycExit(false);
            }}
            confirmtype={"krarecords"}
          />
          <AppologiesPopup
            showModal={apologyPopup}
            closeModal={() => {
              setApologyPopup(false);
            }}
          />
        </section>
      </main>
    </div>
  );
};

export default AccountOpeningpennyDrop;
