import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { kycStatus, PageLinks } from "../common/Constants";
import { Field, Formik } from "formik";
import { APIData } from "../common/DataTypes";
import { InitialData } from "../components/Form/InitialData";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import StepsComponent from "../components/StepsComponent";
import { kycStatusUpdate } from "../common/Utilities";
import SignatureExample from "../components/Modals/SignatureExample";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";
import * as yup from "yup";
import moment from "moment";

const DematDetails: React.FC = () => {
  const [exisitingDematDetails, setExisitingDematDetails] = useState<APIData.DematAccount>(InitialData.DematAccount);
  const [depositories, setDepositories] = useState<APIData.Depositories[]>([]);
  const [segmentID, setSegmentID] = useState<APIData.VariousSegments[]>([]);
  const [depositoryName, setDepositoryName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [segmentsDropDown, setSegmentsDropDown] = useState<APIData.Segments[]>([]);
  const [brokerageDropDown, setBrokerageDropDown] = useState<APIData.BrokerageScheme[]>([]);
  const prevData: any = location.state;
  const [dematName, setDematName] = useState<string>("");
  const { setShowPreloader } = useAppStateAPI();
  let AccountRedirect = localStorage.getItem("demat");
  const [userDetail, setUserDetails] = useState<APIData.userDetail>();
  const [KycExit, setKycExit] = useState(false);

  useEffect(() => {
    setShowPreloader(true);
    ServerAPI.userDetailData()
      .then((res: any) => {
        setUserDetails(res["data"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  }, []);
  const validationSchema = yup.object({
    depository_id: yup.number().min(1).required("Please select Depository Name"),
    dp_id: yup.string().when("depository_id", {
      is: 1,
      then: (d) => d.max(8, "Must start with IN").min(8, "Must start with IN followed by 8 digits").matches(/^([IN])+/, ' Must start with IN').required(),
      otherwise: (d) => d.max(16).min(16).required(),
    }),
    client_id: yup.string().when("depository_id", {
      is: 1,
      then: (d) => d.max(8, "Must start with IN").min(8, "Must start with IN followed by 8 digits").required(),
      otherwise: (d) => d.notRequired(),
    }),
    brokerage_scheme_id: yup.number().min(1).required("Please select Brokerage Scheme"),
    reenter_client_id: yup
      .string().when("depository_id", {
        is: 1,
        then: (d) => d.oneOf([yup.ref("client_id")], "Client IDs don't match!")
        .required("Required")
      }),
    reenter_dp_id: yup
      .string()
      .oneOf([yup.ref("dp_id")], "Dp IDs don't match!")
      .required("Required"),
  });
  useEffect(() => {
    setShowPreloader(true);
    ServerAPI.Depository()
      .then((response: any) => {
        setDepositories(response["data"]["message"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });
    setShowPreloader(true);
    ServerAPI.Segments()
      .then((response: any) => {
        setSegmentsDropDown(response["data"]["message"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });
    setShowPreloader(true);
    ServerAPI.BrokerageScheme()
      .then((response: any) => {
        setBrokerageDropDown(response["data"]["message"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  }, []);

  const handleSegments = (id: number, status: number, checked: boolean) => {
    let newsegments = [...segmentID];
    if (checked == true) {
      newsegments.push({
        various_segment_id: id,
        various_segment_selected_status: status,
      });
      setSegmentID(newsegments);
    } else {
      let removedSegements = newsegments.filter((segment: APIData.VariousSegments) => segment.various_segment_id != id);
      setSegmentID(removedSegements);
    }
  };

  return (
    <div className="main-index-wrapper">
      <main>
        <section className="subscribe-letter-area pt-20 pb-20">
          <div className="line-shape d-none d-lg-inline-block">
            <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
          </div>

          <div className="container custom-container-subs">
            <div className="sub-bg">
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="subscribe-wrapper">
                    <div className={AccountRedirect != "true" ? "section-title section-title-3 text-center" : "section-title section-title-3 text-center mb-5"}>
                      <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                    </div>
                  </div>
                  {AccountRedirect != "true" && <StepsComponent step={3} />}
                  <div className="step_box_content">
                    <div className="sub-bg">
                      <div className="row justify-content-center">
                        <div className="col-xl-12">
                          <div className="subscribe-wrapper">
                            <div className="section-title section-title-3">
                              <h6 className="wow page-main-heading" data-wow-delay="0.1s">
                                Demat Details
                              </h6>
                              <hr></hr>
                              <h6 className="pt-20 mb-10 " data-wow-delay="0.1s">
                                Hey {userDetail?.personal_data_pan_fullname}, Enter Your Exisiting Demat Account Information
                              </h6>
                              <div className="col-xl-12 p-0">
                                <div className="choice-list">
                                  <h6 className="pt-20 mb-10 " data-wow-delay="0.1s">
                                    Segment Type
                                  </h6>
                                  <span className="input-title-filled gender_col">
                                    {segmentsDropDown != undefined &&
                                      segmentsDropDown.length > 0 &&
                                      segmentsDropDown.map((element: APIData.Segments) => {
                                        return (
                                          <div className={segmentID.filter((item: APIData.VariousSegments) => item.various_segment_id == element.various_segment_id).length > 0 ? "gender_btn selected" : "gender_btn"}>
                                            <input
                                              onClick={(e: any) => {
                                                e.preventDefault();
                                                handleSegments(element.various_segment_id, element.various_segment_status, e.target.checked);
                                              }}
                                              type="checkbox"
                                              checked={segmentID.filter((item: APIData.VariousSegments) => item.various_segment_id == element.various_segment_id).length > 0 ? true : false}
                                              value={element.various_segment_id}
                                              className="d-none"
                                              name={"segment" + element.various_segment_id}
                                              id={"segment" + element.various_segment_id}
                                            />
                                            <label htmlFor={"segment" + element.various_segment_id} className={segmentID.filter((item: APIData.VariousSegments) => item.various_segment_id == element.various_segment_id).length > 0 ? "gender-btn-label-selected" : "gender_btn-label"}>
                                              {element.various_segment}
                                            </label>
                                          </div>
                                        );
                                      })}
                                  </span>
                                </div>
                              </div>
                              <Formik
                                initialValues={exisitingDematDetails}
                                enableReinitialize
                                onSubmit={(values, { setSubmitting }) => {
                                  console.log("🚀 ~ file: DematDetails.tsx:171 ~ segmentsDropDown.map ~ exisitingDematDetails:", exisitingDematDetails)
                                  localStorage.setItem("animation", "exisitingdemat");
                                  let newValues = { ...values };
                                  console.log("🚀 ~ file: DematDetails.tsx:176 ~ segmentsDropDown.map ~ newValues:", newValues)
                                  newValues.various_segment = segmentID;
                                  if (values.depository_id == 1) {
                                    newValues.dp_id = newValues.dp_id + newValues.client_id!;
                                  } else {
                                    newValues.dp_id = newValues.dp_id;
                                  }
                                  delete newValues.client_id;
                                  delete newValues.reenter_client_id;
                                  delete newValues.reenter_dp_id;
                                  console.log('🚀 ~ file: DematDetails.tsx:189 ~ segmentsDropDown.map ~ newValues:',newValues);
                                  setShowPreloader(true);
                                  ServerAPI.exisitingDematAccount(newValues)
                                    .then((res: any) => {
                                      if (res != undefined) {
                                        toast.success(res["data"]["message"]);
                                        navigate(PageLinks.VERIFY_DEMAT_DETAILS_SUCCESS); 
                                      }
                                    })
                                    .finally(() => {
                                      setShowPreloader(false);
                                      localStorage.setItem("animation", "default");
                                    });
                                }}
                                validationSchema={validationSchema}
                              >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                  <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                    <div className="col-xl-12 pl-0 float-left">
                                      <h6 className="mt-3 mb-0">Existing DP Details</h6>
                                      <div className="col-xl-4 pl-0 float-left mt-10">
                                        <div className="email-input input_field pl-0">
                                          <label className={errors.depository_id && touched.depository_id ? "error" : "input-title"}>Depository *</label>
                                          <select
                                            placeholder="Enter Depository"
                                            onChange={(e: any) => {
                                              handleChange(e);
                                              setDepositoryName(e.nativeEvent.target[e.target.selectedIndex].text);
                                            }}
                                            onBlur={handleBlur}
                                            className={errors.depository_id && touched.depository_id ? "input-error" : ""}
                                            name="depository_id"
                                            value={values.depository_id}
                                          >
                                            <option value={0}>Select</option>
                                            {depositories != undefined &&
                                              depositories.length > 0 &&
                                              depositories.map((depository: APIData.Depositories, index) => {
                                                return <option value={depository.depository_id}>{depository.depository}</option>;
                                              })}
                                          </select>
                                          {errors.depository_id && touched.depository_id && <span className="error">{errors.depository_id}</span>}
                                        </div>
                                      </div>
                                    </div>
                                    {depositoryName == "Central Depository Services (India) Limited - CDSL" ? (
                                      <Fragment>
                                        <div className="col-xl-12 pl-0 float-left">
                                          <div className="col-xl-4 pl-0 float-left mt-10">
                                            <div className="email-input input_field pl-0">
                                              <label className={errors.dp_id && touched.dp_id ? "error" : "input-title"}>DP & Client (16 Digit Client Account No.) - ID*</label>
                                              <input type="text" placeholder="Enter DP - ID" name="dp_id" value={values.dp_id} onChange={handleChange} onBlur={handleBlur} maxLength={16} className={errors.dp_id && touched.dp_id ? "input-error" : ""} />
                                              {errors.dp_id && touched.dp_id && <span className="error">{errors.dp_id}</span>}
                                            </div>
                                          </div>
                                          <div className="col-xl-1 pl-0 float-left demat-dash  text-center">-</div>
                                          <div className="col-xl-4 pl-0 float-left mt-10">
                                            <div className="email-input input_field pl-0">
                                              <label className={errors.reenter_dp_id && touched.reenter_dp_id ? "error" : "input-title"}>DP & Client (16 Digit Client Account No.) - ID*</label>
                                              <input type="text" placeholder="Enter DP - ID" name="reenter_dp_id" value={values.reenter_dp_id} onChange={handleChange} onBlur={handleBlur} maxLength={16} className={errors.reenter_dp_id && touched.reenter_dp_id ? "input-error" : ""} />
                                              {errors.reenter_dp_id && touched.reenter_dp_id && <span className="error">{errors.reenter_dp_id}</span>}
                                            </div>
                                          </div>
                                        </div>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        <div className="col-xl-12 pl-0 float-left">
                                          <div className="col-xl-4 pl-0 float-left mt-10">
                                            <div className="email-input input_field pl-0">
                                              <label className={errors.dp_id && touched.dp_id ? "error" : "input-title"}>DP - ID *</label>
                                              <input type="password" placeholder="Enter DP Name" name="dp_id" value={values.dp_id} onChange={handleChange} onBlur={handleBlur} maxLength={8} className={errors.dp_id && touched.dp_id ? "input-error" : ""} />
                                              {errors.dp_id && touched.dp_id && <span className="error">{errors.dp_id}</span>}
                                            </div>
                                          </div>
                                          <div className="col-xl-1 pl-0 float-left demat-dash  text-center">-</div>
                                          <div className="col-xl-4 pl-0 float-left mt-10">
                                            <div className="email-input input_field pl-0">
                                              <label className={errors.client_id && touched.client_id ? "error" : "input-title"}>Client - ID *</label>
                                              <input type="password" placeholder="Enter Client - ID" name="client_id" value={values.client_id} onChange={handleChange} onBlur={handleBlur} maxLength={8} className={errors.client_id && touched.client_id ? "input-error" : ""} />
                                              {errors.client_id && touched.client_id && <span className="error">{errors.client_id}</span>}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-xl-12 pl-0 float-left">
                                          <div className="col-xl-4 pl-0 float-left mt-10">
                                            <div className="email-input input_field pl-0">
                                              <label className={errors.reenter_dp_id && touched.reenter_dp_id ? "error" : "input-title"}>Re-enter DP - ID *</label>
                                              <input type="text" placeholder="Enter DP Name" name="reenter_dp_id" value={values.reenter_dp_id} onChange={handleChange} onBlur={handleBlur} maxLength={8} className={errors.reenter_dp_id && touched.reenter_dp_id ? "input-error" : ""} />
                                              {errors.reenter_dp_id && touched.reenter_dp_id && <span className="error">{errors.reenter_dp_id}</span>}
                                            </div>
                                          </div>
                                          <div className="col-xl-1 pl-0 float-left demat-dash  text-center">-</div>
                                          <div className="col-xl-4 pl-0 float-left mt-10">
                                            <div className="email-input input_field pl-0">
                                              <label className={errors.reenter_client_id && touched.reenter_client_id ? "error" : "input-title"}>Re-enter Client - ID *</label>
                                              <input type="text" placeholder="Enter DP - ID" name="reenter_client_id" value={values.reenter_client_id} onChange={handleChange} onBlur={handleBlur} maxLength={8} className={errors.reenter_client_id && touched.reenter_client_id ? "input-error" : ""} />
                                              {errors.reenter_client_id && touched.reenter_client_id && <span className="error">{errors.reenter_client_id}</span>}
                                            </div>
                                          </div>
                                        </div>
                                      </Fragment>
                                    )}
                                    <div className="col-xl-12 pl-0 float-left">
                                      <div className="col-xl-9 pl-0 float-left mt-10">
                                        <div className="email-input input_field pl-0">
                                          <label className={errors.brokerage_scheme_id && touched.brokerage_scheme_id ? "error" : "input-title"}>Brokerage Scheme *</label>
                                          <select placeholder="Enter Depository" onChange={handleChange} onBlur={handleBlur} className={errors.brokerage_scheme_id && touched.brokerage_scheme_id ? "input-error" : ""} name="brokerage_scheme_id" value={values.brokerage_scheme_id}>
                                            <option value={0}>Select</option>
                                            {brokerageDropDown != undefined &&
                                              brokerageDropDown.length > 0 &&
                                              brokerageDropDown.map((broker: APIData.BrokerageScheme, index) => {
                                                return <option value={broker.brokerage_scheme_id}>{broker.brokerage_scheme}</option>;
                                              })}
                                          </select>
                                          {errors.brokerage_scheme_id && touched.brokerage_scheme_id && <span className="error">{errors.brokerage_scheme_id}</span>}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-12 pl-0 float-left mt-4">
                                      <label htmlFor="terms" className="review-text mx-1 mb-0 p-0">
                                        <p className="review-text text-left mt-1">
                                          <input
                                            className="signup"
                                            type="checkbox"
                                            id="terms"
                                            name="auth_fund_securities"
                                            onChange={(e: any) => {
                                              if (e.target.checked == true) {
                                                values.auth_fund_securities = 1;
                                              } else {
                                                values.auth_fund_securities = 0;
                                              }
                                            }}
                                          />{" "}
                                          I hereby authorise for TFCSPL Periodic Settlement of Funds and Securities:
                                        </p>
                                      </label>
                                    </div>
                                    <div className="col-xl-12 pl-0 float-left mt-1">
                                      <label htmlFor="terms1" className="review-text mx-1 mb-0 p-0">
                                        <p className="review-text text-left mt-1">
                                          <input
                                            className="signup"
                                            type="checkbox"
                                            id="terms1"
                                            name="auth_receive_info_stock_broker"
                                            onChange={(e: any) => {
                                              if (e.target.checked == true) {
                                                values.auth_receive_info_stock_broker = 1;
                                              } else {
                                                values.auth_receive_info_stock_broker = 0;
                                              }
                                            }}
                                          />{" "}
                                          I hereby authorise TFCSPL for receiving any documents/communications in electronic form by E-mail from the Stock Broker
                                        </p>
                                      </label>
                                    </div>
                                    <div className="col-xl-12 pl-0 float-left mt-1">
                                      <label htmlFor="terms2" className="review-text mx-1 mb-0 p-0">
                                        <p className="review-text text-left mt-1">
                                          <input
                                            className="signup"
                                            type="checkbox"
                                            id="terms2"
                                            name="auth_transfer_fund_securities"
                                            onChange={(e: any) => {
                                              if (e.target.checked == true) {
                                                values.auth_transfer_fund_securities = 1;
                                              } else {
                                                values.auth_transfer_fund_securities = 0;
                                              }
                                            }}
                                          />{" "}
                                          I hereby authorise TFCSPL for the Transfer of Funds and/or Securities, Inter Segment and Inter Exchange
                                        </p>
                                      </label>
                                    </div>
                                    <div className="col-xl-12 pl-0 float-left mt-1">
                                      <label htmlFor="terms3" className="review-text mx-1 mb-0 p-0">
                                        <p className="review-text text-left mt-1">
                                          <input
                                            className="signup"
                                            type="checkbox"
                                            id="terms3"
                                            name="auth_accept_collateral_segments"
                                            onChange={(e: any) => {
                                              if (e.target.checked == true) {
                                                values.auth_accept_collateral_segments = 1;
                                              } else {
                                                values.auth_accept_collateral_segments = 0;
                                              }
                                            }}
                                          />{" "}
                                          I hereby authorise TFCSPL for Acceptance of securities as collateral for any or all segment(s)
                                        </p>
                                      </label>
                                    </div>
                                    <div className="col-xl-12 continue_btn_whole mb-3 mt-5">
                                      <button type="submit" className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s">
                                        Add Demat Account
                                      </button>
                                    </div>
                                    <div
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        setKycExit(true);
                                      }}
                                      className="save_continue_btn text-center cursor"
                                    >
                                      <u>Save & Complete Later</u>
                                    </div>
                                  </form>
                                )}
                              </Formik>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ConfirmationKYCModel
          showModal={KycExit}
          closeModal={() => {
            setKycExit(false);
          }}
          confirmtype={"demat"}
        />
        {/* Profile-details-edit-area end */}
      </main>
    </div>
  );
};

export default DematDetails;
