import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { kycStatus, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";


const EsignCancelPopup: React.FC = () => {
    const location = useLocation();
    const { setShowPreloader } = useAppStateAPI();
    const [esignClicked, setEsignClicked] = useState(false);
    var wind: any;
    const navigate = useNavigate();

    const redirect = () => {
        setShowPreloader(true);
        ServerAPI.eSign('WEB').then((response: any) => {
            if (response != undefined) {
                var left = (window.screen.width - 500) / 2;
                var top = (window.screen.height - 500) / 2;
                wind = window.open("", 'popup', 'resizable=yes, width=500,height=500 + , top=' + top + ', left=' + left);
                if (wind != undefined) {
                    wind.document.write(response['data']);
                }
            }
        }).catch((error: any) => {
            console.log("🚀 ~ file: BankDetails.tsx:163 ~ ServerAPI.RazorPaybankDetailData ~ err:", error);
        }).finally(() => {
            setEsignClicked(true);
            setShowPreloader(false);
        })
    }
    return (
        <section className="subscribe-letter-area pt-200 pb-200">
            <div className="line-shape d-none d-lg-inline-block">
                <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
            </div>
            <div className="container custom-container-subs">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6  text-left" data-wow-delay="0.3s">
                        <div className="do-box do-box-pan-status mb-30 email_verify_bg text-center">

                            <img src="assets/img/icon/warning.png" className="user_icon w-40 pb-0" />
                            <p className="mb-10  identity_verify_success1" data-wow-delay="0.1s">E-Sign Process Failed.</p>
                            <p className="mb-10  confirmation-model-sub-head1 " data-wow-delay="0.1s">Oops! Something Went Wrong. Please try again later.</p>
                            <div className="mt-3 col-md-12 m-auto col-xl-10 row col-12">
                                <div className="col-md-6 col-xl-6 col-12">
                                    <button onClick={(e: any) => {
                                        e.preventDefault();
                                        navigate(PageLinks.INSTRUCTION_SCREEN);
                                    }} type="submit" className=" email_verify_btn1 " data-wow-delay="0.5s">Go to home</button>
                                </div>
                                <div className="col-md-6 col-xl-6 col-12">
                                    <button onClick={() => {
                                        redirect();
                                    }} type="submit" className="email_verify_btn2" data-wow-delay="0.5s">Try again</button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </section>

    );
};

export default EsignCancelPopup;
