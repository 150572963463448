import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { kycStatus, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";

const PanVerification: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userDetail, setUserDetails] = useState<APIData.userDetail>();
  const prevData: any = location.state;
  const { setShowPreloader } = useAppStateAPI();
  const PageNavigation = () => {
    console.log(localStorage.getItem("aadhar"));
    if (localStorage.getItem("aadhar") != undefined) {
      if (localStorage.getItem("aadhar") == "true") {
        navigate(PageLinks.AUTHENTICATE_AADHAAR);
      } else if (localStorage.getItem("aadhar") != "true") {
        navigate(PageLinks.ACCOUNT_OPENING_PENNY_DROP);
      } else {
        navigate(PageLinks.AUTHENTICATE_AADHAAR);
      }
    }
  };

  useEffect(() => {
    setShowPreloader(true);
    ServerAPI.userDetailData()
      .then((res) => {
        setUserDetails(res["data"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  }, []);
  return (
    <section className="subscribe-letter-area pt-200 pb-200">
      <div className="line-shape d-none d-lg-inline-block">
        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
      </div>
      <div className="container custom-container-subs">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6  text-left" data-wow-delay="0.3s">
            <div className="do-box do-box-pan-status mb-30 email_verify_bg text-center">
              <img src="assets/animations/icon_animations/user.gif" className="user_icon" />
              <h4 className="mb--10  pan_verify_head" data-wow-delay="0.1s">
                Hi, <span style={{ textTransform: "capitalize" }}>{(userDetail?.personal_data_pan_fullname != undefined ? userDetail?.personal_data_pan_fullname : "").toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase())}</span>
              </h4>
              <form className="email_verify_form quote-form mb-20" action="#">
                <span
                  onClick={(e: any) => {
                    e.preventDefault();
                    PageNavigation();
                  }}
                  className="theme_btn email_verify_btn sub-btn"
                >
                  I confirm to open the account in the name (as per PAN) of <br /> <p>[{(userDetail?.personal_data_pan_fullname != undefined ? userDetail?.personal_data_pan_fullname : "").toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase())}]</p>
                </span>

                <Link to={PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VALIDATE_PAN} className="theme_btn pan-wrong sub-btn w-70 mt-1" data-wow-delay="0.5s">
                  Wrong name? - Re-enter PAN number
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PanVerification;
