import { APIData } from "../../common/DataTypes";
import moment from "moment";

export namespace InitialData {
  export const BankDetails: APIData.bankDetails = {
    number_of_account: "",
    ifsc_code: "",
    account_number: "",
    account_type_1: 0,
    account_type_2: 0,
    is_primary: 0,
    cancel_cheque_image: "",
    dividend_bank_account_type: "",
    dividend_bank_account_code: "",
    dividend_bank_account_number: "",
    dividend_bank_CCY: 0,
    dividend_bank_IFSC_code: "",
    dividend_currency: 0,
    RBI_reference_no: "",
    RBI_approval_date: "",
    account_number_confirm: "",
  };

  export const verify_otp: APIData.otp_verify = {
    otp: "",
  };
  export const verify_email: APIData.email_Verify = {
    email_id: "",
  };

  export const signUp: APIData.signup = {
    agreed: false,
    firstName: "",
    lastName: "",
    mobile_number: "",
    terms: false,
    partnerterms: false,
  };

  export const UserDetails: APIData.userDetail = {
    address_proof_id: 0,
    address_proof_verified_remarks_id: 0,
    annual_income_id: 0,
    cvlkra_id: 0,
    Digilocker_aadhaar_id: 0,
    gender_id: 0,
    marital_status_id: 0,
    occupation_id: 0,
    personal_data_aadhar_fullname: "",
    personal_data_aadhar_number: 0,
    personal_data_aadhar_verified: 0,
    personal_data_address_as_aadhar: 0,
    personal_data_address_city: "",
    personal_data_address_line_1: "",
    personal_data_address_line_2: "",
    personal_data_address_pincode: "",
    personal_data_address_proof_back_image: "",
    personal_data_address_proof_front_image: "",
    personal_data_address_proof_verified: 0,
    personal_data_address_state: "",
    personal_data_created_at: "",
    personal_data_cvlkra_status: 0,
    personal_data_dob: "",
    personal_data_father_or_husband_name: "",
    personal_data_firstname: "",
    personal_data_lastname: "",
    personal_data_mothers_maiden_name: "",
    personal_data_nominee_added: 0,
    personal_data_pan_fullname: "",
    personal_data_pan_number: "",
    personal_data_pan_status_code: "",
    personal_data_pan_verified: 0,
    personal_data_updated_at: 0,
    trading_experience_id: 0,
    personal_data_email_id: "",
    personal_data_mobile: "",
    user_auth_interfychange: 0,
    user_e_confirmation: 0,
    user_auth_fund_securities: 0,
    user_citizen_usa: 0,
    user_auth_fetch_kyc_details: 0,
    user_auth_use_aadhar: 0,
    user_tax_resident: 0,
    user_itr_records: 0,
  };

  export const DematAccount: APIData.DematAccount = {
    auth_fund_securities: 0,
    auth_receive_info_stock_broker: 0,
    auth_transfer_fund_securities: 0,
    auth_accept_collateral_segments: 0,
    dp_id: "",
    client_id: "",
    depository_id: 0,
    brokerage_scheme_id: 0,
    reenter_dp_id: "",
    reenter_client_id: "",
    various_segment: [],
  };

  export const CancelledCheque: any = {
    cancelled_cheque_image: "",
  };

  export const ProofImage: any = {
    proof_image: "",
  };

  export const updateAadharDetails: any = {
    addressProofType: "",
    proof_back_image: "",
    proof_front_image: "",
    Address_line_1: "",
    Address_line_2: "",
    pincode: "",
    state: "",
    city: "",
    personal_data_address_proof_number: "",
  };
  export const newDematAccount: APIData.NewDematAccount = {
    dp_resident_india: 0,
    dp_resident_usa: 0,
    check_box_terms_selected: 0,
    check_box_account_statement_electronic: 0,
    demat_signature_image: "",
    born_place: "",
    primary_source: "",
  };

  export const nomineeDetail: APIData.NomineeDetails = {
    allocation: 0,
    title: "",
    fname: "",
    mname: "",
    lname: "",
    relationship: "",
    identification: 0,
    mobile_number: "",
    dob: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    address_zip: "",
    address_state_code: "",
    address_state: "",
    address_city: "",
    city_sequence_no: "",
    current_address_line_1: "",
    current_address_line_2: "",
    current_address_line_3: "",
    current_address_zip: "",
    current_address_state_codecurrent: "",
    current_address_state: "",
    current_address_city: "",
    current_city_sequence_no: "",
    identification_number: "",
  };

  export const NomineeGuardian = {
    nominee_name_title_id: 0,
    nominee_guardian_fullname: "",
    nominee_relationship_with_applicant_id: 0,
    nominee_identification_id: 0,
    nominee_guardian_identification_number: "",
    nominee_guardian_mobile_number: "",
  };

  export const NomineeData: APIData.NomineeData = {
    nominee_percentage: "",
    nominee_fullname: "",
    name_title_id: 0,
    relationship_with_applicant_id: 0,
    nominee_identification_id: 0,
    nominee_identification_number: "",
    nominee_mobile_number: "",
    nominee_dob: "",
    nominee_address_line_1: "",
    nominee_address_line_2: "",
    nominee_address_pincode: "",
    nominee_address_state: "",
    nominee_address_city: "",
    nominee_address_as_user: 1,
    nominee_is_minor: 0,
    nominee_guardian_records: NomineeGuardian,
    nominee_guardian_identification_number: "",
  };

  export const FilterData: APIData.Filters = {
    brand_tag_id: [],
    bond_rating_list_id: [],
    payment_frequency: [],
    tenure_from: 0,
    tenure_to: 0,
    yield_from: 0,
    yield_to: 0,
  };

  export const OrderFilterData: APIData.OrderFilters = {
    bond_type: 0,
    order_from_date: "",
    order_to_date: "",
  };

  export const AaadharDetails: APIData.AadharDetails = {
    digilocker_aadhaar_care_of: "",
    digilocker_aadhaar_api_type: "",
    digilocker_aadhaar_country: "",
    digilocker_aadhaar_created_at: "",
    digilocker_aadhaar_district: "",
    digilocker_aadhaar_dob: "",
    digilocker_aadhaar_fullname: "",
    digilocker_aadhaar_house_no: "",
    digilocker_aadhaar_id: 0,
    digilocker_aadhaar_image: "",
    digilocker_aadhaar_landmark: "",
    digilocker_aadhaar_location: "",
    digilocker_aadhaar_number: "",
    digilocker_aadhaar_pincode: "",
    digilocker_aadhaar_state: "",
    digilocker_aadhaar_street: "",
    digilocker_aadhaar_updated_at: "",
    digilocker_aadhaar_village_town_city: "",
    gender_id: 0,
  };
}
