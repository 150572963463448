import { Field, Formik, FormikProps } from "formik";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { kycStatus, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";
import PoliticallyExposed from "../components/Modals/PoliticallyExposed";
import StepsComponent from "../components/StepsComponent";
import { useAppStateAPI } from "../common/AppStateAPI";
import HeaderLogoContent from "../components/HeaderLogoContent";
import { kycStatusUpdate } from "../common/Utilities";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";
import MobileOTPVerify from "../components/Modals/MobileOTPVerify";
import EmailOTPVerify from "../components/Modals/EmailOTPVerify";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppologiesPopup from "../components/Modals/AppologiesPopup";

const maxDate = moment(new Date()).format("YYYY-MM-DD");
const PersonalDetailsEdit: React.FC = () => {
  const navigate = useNavigate();
  const [politicallyExposed, setPoliticallyExposed] = useState(false);
  const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [KycExit, setKycExit] = useState(false);
  const [apologyPopup, setApologyPopup] = useState(false);
  const [emailID, setEmailID] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileVerifyModal, setMobileVerifyModal] = useState(false);
  const [emailVerifyModal, setEmailVerifyModal] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [mobileVerified, setMobileVerified] = useState(false);
  const [ageValidationFailed, setValidationFailed] = useState(false);
  const { setShowPreloader } = useAppStateAPI();
  const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [startDate, setStartDate] = useState<Date>();
  const formikRef = useRef<FormikProps<APIData.userDetail>>(null);

  // useEffect(() => {
  //     setShowPreloader(true);
  //     ServerAPI.userDetailData().then((res: APIData.userDetail) => {
  //         setUserDetails(res);
  //     }).finally(() => {
  //         setShowPreloader(false);
  //     })
  // }, []);

  const validationSchema = yup.object({
    personal_data_firstname: yup.string().required("First Name is Required"),
    personal_data_lastname: yup.string().required("Last Name is Required"),
    personal_data_email_id: yup.string().email("Please Enter a Valid Email").required("Please Enter Your Email"),
    personal_data_mobile: yup.string().required("Please Enter Your Mobile Number").min(10).max(10),
    user_auth_interfychange: yup.boolean().required("user_auth_interfychange should be Accepted"),
  });

  useEffect(() => {
    if (moment().diff(moment(startDate), "years") < 18 && startDate) {
      setValidationFailed(true);
    } else {
      setValidationFailed(false);
    }
  }, [startDate]);

  return (
    <div className="main-index-wrapper">
      <main>
        {/* Profile-details-edit-area start */}
        <section className="subscribe-letter-area pt-20 pb-20">
          <div className="line-shape d-none d-lg-inline-block">
            <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
          </div>

          <div className="container custom-container-subs">
            <div className="sub-bg">
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <HeaderLogoContent />
                  <StepsComponent step={1} />
                  <div className="step_box_content">
                    <div className="sub-bg">
                      <div className="row justify-content-center">
                        <div className="col-xl-12">
                          <div className="subscribe-wrapper">
                            <div className="section-title section-title-3">
                              <h6 className="wow page-main-heading" data-wow-delay="0.1s">
                                Personal Details <span className="page-main-heading-steps">STEPS - 1 of 2</span>
                              </h6>

                              <hr></hr>
                              <Formik
                                initialValues={userDetail}
                                validationSchema={validationSchema}
                                enableReinitialize
                                onSubmit={(values, { setSubmitting }) => {
                                 
                                  if(mobileVerified  && emailVerified){
                                  console.log(values);
                                  setShowPreloader(true);
                                  ServerAPI.add_profile_details(values)
                                    .then((res: any) => {
                                      if (res != undefined) {
                                        toast.success(res["data"]["message"]);
                                        navigate(PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VALIDATE_PAN);
                                      }
                                    })
                                    .finally(() => {
                                      setShowPreloader(false);
                                    });
                                    ServerAPI.userConcent(values)
                                    .then((res: any) => {})
                                    .finally(() => {
                                      setShowPreloader(false);
                                    });
                                  }else{
                                    toast.error("please verify your mobile number and email address");
                                  }
                                 
                                }}
                              >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                  <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                    <div className="col-xl-8 pl-3 row">
                                      <div className="col-xl-6 pl-0">
                                        <div className="email-input input_field pl-0">
                                          <label className={errors.personal_data_firstname && touched.personal_data_firstname ? "error" : "input-title"}>First Name *</label>
                                          <Field as="input" placeholder="Enter First Name" onChange={handleChange} onBlur={handleBlur} name="personal_data_firstname" value={values.personal_data_firstname} className={errors.personal_data_firstname && touched.personal_data_firstname ? "input-error" : ""} />
                                          {errors.personal_data_firstname && touched.personal_data_firstname && <span className="error">{errors.personal_data_firstname}</span>}
                                        </div>
                                      </div>
                                      <div className="col-xl-6 pl-0 ">
                                        <div className="email-input input_field pl-0">
                                          <label className={errors.personal_data_lastname && touched.personal_data_lastname ? "error" : "input-title"}>
                                            Last Name <span className="error-area">* &nbsp;</span>
                                            {errors.personal_data_lastname && touched.personal_data_lastname && <span className="error">({errors.personal_data_lastname})</span>}
                                          </label>
                                          <input type="text" className={errors.personal_data_lastname && touched.personal_data_lastname ? "input-error" : ""} placeholder="Enter Last Name" name="personal_data_lastname" value={values.personal_data_lastname} onChange={handleChange} onBlur={handleBlur} />

                                          {errors.personal_data_lastname && touched.personal_data_lastname && <span className="error">{errors.personal_data_lastname}</span>}
                                        </div>
                                      </div>
                                      <div className="col-xl-6 pl-0">
                                        <div className="email-input mobile-input input_field pl-0">
                                          <label className={errors.personal_data_mobile && touched.personal_data_mobile ? "error" : "input-title"}>Mobile Number *</label>
                                          <input type="text" className="email_input mobile_number pe-4" readOnly={!mobileVerified ? false : true} placeholder="Enter Mobile Number" name="personal_data_mobile" value={values.personal_data_mobile}  maxLength={10} onChange={handleChange} onBlur={handleBlur} />
                                          {mobileVerified && values.personal_data_mobile.length == 10 ? (
                                            <span className="verified">
                                              <i className="far fa-check-circle"></i>
                                            </span>
                                          ) : (
                                            <button
                                              onClick={(e: any) => {
                                                e.preventDefault();
                                                if (values.personal_data_mobile != "") {
                                                  setShowPreloader(true);
                                                  ServerAPI.mobileVerify(values.personal_data_mobile)
                                                    .then((res: any) => {
                                                      setMobileNumber(values.personal_data_mobile);
                                                      if (res.status == 200) {
                                                        localStorage.setItem("mobile", values.personal_data_mobile);
                                                        localStorage.setItem("hashkey", res["data"].hash_key);
                                                        toast.success(res["data"]["message"]);
                                                        setMobileVerifyModal(true);
                                                      }
                                                    })
                                                    .finally(() => {
                                                      setShowPreloader(false);
                                                    });
                                                }
                                              }}
                                              className={values.personal_data_mobile.length != 10 ? "pan_verify_btn disabled_btn personal-details-mobile-number" : "pan_verify_btn personal-details-mobile-number"}
                                            >
                                              Verify
                                            </button>
                                          )}
                                          {errors.personal_data_mobile && touched.personal_data_mobile && <span className="error">{errors.personal_data_mobile}</span>}
                                          <div className={errors.personal_data_mobile && touched.personal_data_mobile ? "country_box_with_error" : "country_box"}>
                                            <img src="assets/img/icon/flag.png" />
                                            <span className="input-title-mobile"> +91</span>&nbsp;&nbsp;&nbsp;
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 pl-0">
                                        <div className="email-input input_field pl-0">
                                          <label className={errors.personal_data_email_id && touched.personal_data_email_id ? "error" : "input-title"}>Email ID *</label>
                                          <input type="text" placeholder="Enter Email ID" readOnly={!emailVerified ? false : true} onChange={handleChange} onBlur={handleBlur} name="personal_data_email_id" value={values.personal_data_email_id} className={errors.personal_data_email_id && touched.personal_data_email_id ? "input-error" : ""} />
                                          {errors.personal_data_email_id && touched.personal_data_email_id && <span className="error">{errors.personal_data_email_id}</span>}
                                          {emailVerified && emailPattern.test(values.personal_data_email_id) ? (
                                            <span className="verified">
                                              <i className="far fa-check-circle"></i>
                                            </span>
                                          ) : (
                                            <button
                                              onClick={(e: any) => {
                                                e.preventDefault();
                                                if (values.personal_data_email_id != "") {
                                                  setShowPreloader(true);
                                                  ServerAPI.EmailSendOtp(values.personal_data_email_id)
                                                    .then((res: any) => {
                                                      if (res.status == 200) {
                                                        toast.success(res["data"]["message"]);
                                                        setEmailVerifyModal(true);
                                                      }
                                                    })
                                                    .finally(() => {
                                                      setShowPreloader(false);
                                                    });
                                                }

                                                setEmailID(values.personal_data_email_id);
                                              }}
                                              className={!emailPattern.test(values.personal_data_email_id) ? "pan_verify_btn disabled_btn" : "pan_verify_btn"}
                                            >
                                              Verify
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-xl-6 pl-0 float-left">
                                        <div className=" input_field pl-0">
                                          <label className={ageValidationFailed ? "error" : "input-title"}>
                                            Date of Birth - Should be as per Aadhar <span className="error-area">*</span>
                                          </label>
                                          <DatePicker
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                            maxDate={new Date(maxDate)}
                                            minDate={new Date("1900-01-01")}
                                            className="date-picker-input profile-calendar mobile_number"
                                            name="personal_data_dob"
                                            selected={startDate}
                                            onChange={(date: any) => {
                                              setStartDate(date);
                                              values.personal_data_dob = moment(date).format("YYYY/MM/DD");
                                            }}
                                          />
                                          {ageValidationFailed && <span className="error">Please Enter a Valid Date of Birth</span>}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-xl-4 p-0"></div>
                                    <div className="col-xl-8 pl-3 row mb-5 political_switch-div mt-3">
                                      <div className="choice-list political_switch">
                                        <p className="input-title" style={{ textTransform: "capitalize" }}>
                                          Are You A U.S Citizen?
                                        </p>
                                        <div className="input-title-filled">
                                          <div className="custom-switch custom-switch-label-yesno pl-0">
                                            <input
                                              className="custom-switch-input"
                                              id="is_politically_exposed"
                                              type="checkbox"
                                              checked={values.user_citizen_usa == 1 ? true : false}
                                              name="is_politically_exposed"
                                              onChange={(e: any) => {
                                                if (e.target.checked) {
                                                  setApologyPopup(true);
                                                }
                                              }}
                                            />
                                            <label className="custom-switch-btn" htmlFor="is_politically_exposed"></label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-4 choice-list text-start">
                                        <label htmlFor="terms" className="review-text mx-1 mb-0 p-0 d-inline-flex">
                                          <p className="review-text text-left mt-1">
                                            <input
                                              className="signup"
                                              type="checkbox"
                                              id="terms"
                                              name="user_auth_interfychange"
                                              onChange={(e: any) => {
                                                if (e.target.checked == true) {
                                                  setAgree1(true);
                                                  values.user_auth_interfychange = 1;
                                                } else {
                                                  setAgree1(false);
                                                  values.user_auth_interfychange = 0;
                                                }
                                              }}
                                            />{" "}
                                          </p>
                                          <p className="m-1">Authentication for InterFychange </p>
                                        </label>
                                        {errors.user_auth_interfychange && touched.user_auth_interfychange && <p className="error_text">{errors.user_auth_interfychange}</p>}
                                      </div>
                                      <div className="col-xl-4 choice-list text-center">
                                        <label htmlFor="terms1" className="review-text mx-1 mb-0 p-0 d-inline-flex">
                                          <p className="review-text text-left mt-1">
                                            <input
                                              className="signup"
                                              type="checkbox"
                                              id="terms1"
                                              name="user_e_confirmation"
                                              onChange={(e: any) => {
                                                if (e.target.checked == true) {
                                                  setAgree1(true);
                                                  values.user_e_confirmation = 1;
                                                } else {
                                                  setAgree1(false);
                                                  values.user_e_confirmation = 0;
                                                }
                                              }}
                                            />{" "}
                                          </p>
                                          <p className="m-1">E-Confirmation</p>
                                        </label>
                                        {errors.user_e_confirmation && touched.user_e_confirmation && <p className="error_text">{errors.user_e_confirmation}</p>}
                                      </div>
                                      <div className="col-xl-4 choice-list text-center">
                                        <label htmlFor="terms2" className="review-text mx-1 mb-0 p-0 d-inline-flex">
                                          <p className="review-text text-left mt-1">
                                            <input
                                              className="signup"
                                              type="checkbox"
                                              id="terms2"
                                              name="user_auth_fund_securities"
                                              onChange={(e: any) => {
                                                if (e.target.checked == true) {
                                                  setAgree1(true);
                                                  values.user_auth_fund_securities = 1;
                                                } else {
                                                  setAgree1(false);
                                                  values.user_auth_fund_securities = 0;
                                                }
                                              }}
                                            />
                                          </p>
                                          <p className="m-1">Authentication for Fund Securities</p>
                                        </label>
                                        {errors.user_auth_fund_securities && touched.user_auth_fund_securities && <p className="error_text">{errors.user_auth_fund_securities}</p>}
                                      </div>
                                      <label htmlFor="terms3" className="review-text mx-1 mb-0 p-0 d-inline-flex">
                                        <p className="review-text text-left mt-1">
                                          <input
                                            className="signup"
                                            type="checkbox"
                                            id="terms3"
                                            name="user_auth_fetch_kyc_details"
                                            onChange={(e: any) => {
                                              if (e.target.checked == true) {
                                                setAgree1(true);
                                                values.user_auth_fetch_kyc_details = 1;
                                              } else {
                                                setAgree1(false);
                                                values.user_auth_fetch_kyc_details = 0;
                                              }
                                            }}
                                          />{" "}
                                        </p>
                                        <p className="m-1 mx-2 text-left"> I/We authorise TFCS to fetch my/our KYC details available with KRA. I/We also request you to please consider the details fetched for account opening purposes.</p>
                                      </label>
                                      {errors.user_auth_fetch_kyc_details && touched.user_auth_fetch_kyc_details && <p className="error_text">{errors.user_auth_fetch_kyc_details}</p>}
                                      <label htmlFor="terms4" className="review-text mx-1 mb-0 p-0 d-inline-flex">
                                        <p className="review-text text-left pb-0">
                                          <input
                                            type="checkbox"
                                            id="terms4"
                                            className="signup"
                                            name="user_e_confirmation"
                                            onChange={(e: any) => {
                                              if (e.target.checked == true) {
                                                setAgree2(true);
                                                values.user_e_confirmation = 1;
                                              } else {
                                                setAgree2(false);
                                                values.user_e_confirmation = 0;
                                              }
                                            }}
                                          />
                                        </p>
                                        <p className="mx-2 text-left"> I hereby give my consent tо use my Aadhar / Virtual ID details (as applicable) for the purpose of e- Signing my account opening form.</p>
                                      </label>
                                      {errors.user_e_confirmation && touched.user_e_confirmation && <p className="error_text">{errors.user_e_confirmation}</p>}
                                      <div className="col-xl-12 mt-2">
                                        <ul className="ul-style">
                                          <li className="mt-2">
                                            <p className="sourceSansPro-regular text-light-grey">By submitting my PAN, I authorise TRUST GROUP to fetch my data from KYC Registration Agency by submitting my PAN.</p>
                                          </li>
                                          <li className="mt-2">
                                            <p className="sourceSansPro-regular text-light-grey">Currently, this process is applicable only for opening an individual trading account and single-holder Demat accounts for resident individuals soon we shall allow for NRIs and multiple holders in Demat account.</p>
                                          </li>

                                          <li className="mt-2">
                                            <p className="sourceSansPro-regular text-light-grey">Please note that submitting the above-mentioned details allows us to Call/SMS you even though you may be registered under DNC. We shall Call/SMS you for a period of 12 months.</p>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-xl-4 p-0 float-left"></div>
                                    <div className="col-xl-12 continue_btn_whole mb-3">
                                      <button className={values.personal_data_firstname != "" && values.personal_data_lastname != "" && values.personal_data_dob != "" && agree1 == true && agree2 == true ? "theme_btn btn-change primary_btn_1 sub-btn wow" : "theme_btn primary_btn sub-btn wow"} data-wow-delay="0.5s" type="submit">
                                        Continue
                                      </button>
                                    </div>
                                    <div
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        setKycExit(true);
                                      }}
                                      className="save_continue_btn text-center cursor"
                                    >
                                      <u>Save & Complete Later</u>
                                    </div>
                                  </form>
                                )}
                              </Formik>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ConfirmationKYCModel
          showModal={KycExit}
          closeModal={() => {
            setKycExit(false);
          }}
          confirmtype={"personaldetails"}
        />
        <PoliticallyExposed
          showModal={politicallyExposed}
          closeModal={() => {
            setPoliticallyExposed(false);
          }}
        />
        <MobileOTPVerify
          showModal={mobileVerifyModal}
          closeModal={() => {
            setMobileVerifyModal(false);
          }}
          setMobileVerified={() => {
            setMobileVerified(true);
          }}
          mobileNumber={mobileNumber}
        />
        <EmailOTPVerify
          showModal={emailVerifyModal}
          closeModal={() => {
            setEmailVerifyModal(false);
          }}
          emailID={emailID}
          setEmailVerified={() => {
            setEmailVerified(true);
          }}
        />
        <AppologiesPopup
          showModal={apologyPopup}
          closeModal={() => {
            setApologyPopup(false);
          }}
        />
      </main>
    </div>
  );
};

export default PersonalDetailsEdit;
