import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";

const CancelledChequePreview: React.FC = () => {

    return (
        <div className="main-index-wrapper">
            <main>
            <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                           <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                        </div>
                                    </div>
                                    <div className="progress-indicator-element">
                                        <ul className="progress-indicator"> 
                                            <li className="completed">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 01</span> <br></br><p> Personal Details</p></span>
                                                </span>
                                                {/* <span className="bubble"></span> */}
                                            </li>
                                            <li className="completed msg_contain">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 02</span> <br></br><p> Bank Details</p></span>
                                                </span>
                                                {/* <span className="bubble"></span> */}
                                                <div className="bse-order-no">
                                                </div>
                                                <div className="bse-order-msg msg-success">
                                                </div>
                                            </li>
                                            <li className="completed">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 03</span> <br></br> <p> Demat Details</p></span>
                                                </span>
                                                {/* <span className="bubble"></span> */}
                                            </li>
                                        </ul>
                                    <div className='line-container'>
                                        <div className='progress-line'>
                                            <div className='progress '></div>
                                            <div className='status'>
                                                <div className='dot completed'></div>
                                            </div>
                                            <div className='status'>
                                                <div className='dot current'></div>
                                            </div>
                                            <div className='status'>
                                                <div className='dot'></div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="blur_back_instruct"></div>
                <section className="otp_popup instruct">
                    <div className="container custom-container-subs">
                        <div className="sub-bg pt-150 pb-100 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center step_box_content_shadow">
                                <div className="step_box_content">
                                    <div className="sub-bg">
                                        <div className="">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="mb--10 wow " data-wow-delay="0.1s">Preview</h6>
                                                            <Link to={PageLinks.UPLOAD_CANCELLED_CHEQUE} className="theme_btn profile_close_btn sub-btn wow " data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</Link>
                                                            <hr></hr>
                                                            <div className="identity_photo_whole">
                                                                <div className="passport_photo_single">
                                                                    <img src="assets/img/icon/canceled_cheque.png" />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-12 continue_btn_whole">
                                                                <Link to={"#"} className="theme_btn primary_btn_one sub-btn wow  mr-10" data-wow-delay="0.5s">Delete</Link>
                                                                <Link to={PageLinks.AUTHENTICATE_AADHAAR_DETAIL} className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s">Reupload</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        )
}

export default CancelledChequePreview;
